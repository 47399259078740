/*------------------------------------*\
    Page Template: Download
    download.php
    .page-template-download
\*------------------------------------*/

.page-template-download {
    #main {
        overflow-x: hidden;
        overflow-y: clip;
    }

    .box--intro {
        margin: 0 auto;
        max-width: $w-media-uw;
        padding-bottom: $gutter / 2;
    }

    .box--section-1 {
        display: flex;
        flex-wrap: wrap;

        @media #{$media-u} {
            max-width: $w-media-uw;
        }

        .col-1 {
            width: 100%;
            order: -1;
            position: relative;

            &::after {
                width: 30%;
                height: 184px;
                position: absolute;
                right: 0;
                top: - $gutter * 4;
                content: '';
                background: url('../images/griglia_contatti.svg') no-repeat center center / contain;

                @media #{$media-sm} {
                    display: none;
                }
            }

            .text {
                width: 50%;
                padding: 0 $gutter * 2 0 $gutter * 4;

                @media #{$media-bx} {
                    padding-left: $gutter;
                }

                @media #{$media-b} {
                    padding-left: 0;
                    width: 70%;
                }

                @media #{$media-sm} {
                    width: 100%;
                    padding-right: 0;
                }

                @media #{$media-sx} {
                    font-size: 1.4rem;
                }
            }
        }
    }

    // .box--section-1

    .box--section-2 {
        display: flex;
        flex-wrap: wrap;
        margin-top: $gutter;
        padding-bottom: $gutter * 4;

        @media #{$media-u} {
            max-width: $w-media-uw;
        }

        @media #{$media-sx} {
            margin-top: $gutter / 3;
            padding-bottom: $gutter * 2;
        }

        .col-1 {
            width: 100%;

            .table_download {
                padding: 0 $gutter * 4;

                @media #{$media-b} {
                    padding: 0;
                }

                .title {
                    background-color: var(--c-corporate-1);
                    color: var(--c-white);
                    font-size: 3rem;
                    font-weight: 700;
                    padding: $gutter / 3 $gutter * 1.5;

                    @media #{$media-s} {
                        font-size: 1.8rem;
                        padding: $gutter / 5 $gutter / 2;
                    }
                }

                .download_file {
                    display: flex;
                    align-items: center;
                    position: relative;
                    border-left: 1px solid var(--c-grey-dark);
                    border-right: 1px solid var(--c-grey-dark);
                    border-bottom: 1px solid var(--c-grey-dark);
                    padding: $gutter / 2 $gutter * 1.5;

                    @media #{$media-sx} {
                        flex-direction: column;
                        align-items: flex-start;
                        justify-content: center;
                        padding: $gutter / 5 $gutter;
                        font-size: 1.6rem;
                    }

                    &::before {
                        content: '';
                        width: 30px;
                        height: 20px;
                        position: absolute;
                        left: $gutter / 3;
                        background: url('../images/simbolo_logo.svg') no-repeat center center / contain;

                        @media #{$media-sx} {
                            width: 28px;
                            height: 18px;
                            left: $gutter / 4;
                        }
                    }

                    &.public::after {
                        content: '';
                        width: 35px;
                        height: 25px;
                        position: absolute;
                        right: $gutter / 3;
                        background: url('../images/icon-download.svg') no-repeat center center / contain;

                        @media #{$media-sx} {
                            width: 28px;
                            height: 18px;
                            right: $gutter / 4;
                        }
                    }

                    &.private::after {
                        content: '';
                        width: 35px;
                        height: 25px;
                        position: absolute;
                        right: $gutter / 3;
                        background: url('../images/icon-lock.svg') no-repeat center center / contain;

                        @media #{$media-sx} {
                            width: 28px;
                            height: 18px;
                            right: $gutter / 4;
                        }
                    }

                    .extension {
                        font-size: 1.3rem;
                        margin-left: $gutter / 2;

                        @media #{$media-sx} {
                            margin-left: 0;
                            font-size: 1.2rem;
                        }
                    }

                    a {
                        opacity: 0;
                        position: absolute;
                        width: $gutter * 2;
                        height: 100%;
                        z-index: 2;
                        right: 0;
                    }
                }
            }
        }
    }

    // .box--section-2
}

// .page-template-download