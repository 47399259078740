/*------------------------------------*\
    Footer
   #footer.footer
\*------------------------------------*/

.footer {

    margin-top: $gutter*4;
    font-family: $f-family-title;

    position: relative;
    z-index: 1;

    @media #{$media-s} { margin-top: $gutter*2; }

    @media #{$media-sx} { margin-top: $gutter; }

    .footer__getintouch {

        padding: $gutter*.5 $gutter;

        @media #{$media-uw} {
            width: $w-media-uw;
            margin: auto;
        }

        display: flex;
        flex-wrap: wrap;
        align-items: stretch;

        @media #{$media-sm} { flex-direction: column; }

        @media #{$media-sx} {
            padding: $gutter*.5 $gutter / 2;
        }

        .cta,
        .social {

            width: 33.33%;
            padding: $gutter*2 $gutter;

            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            @media #{$media-m} { padding: $gutter; }
            @media #{$media-m} { padding: $gutter / 1.5 $gutter / 2; }

        }
        .cta {
            @media #{$media-m} { width: 50%; }
            @media #{$media-sm} { width: 100%; }
        }
        .social {
            @media #{$media-m} { width: 100%; }
        }

        .cta-title {

            background-color: var(--c-corporate-1);

            .title {

                margin-right: auto;
                color: var(--c-white);
                font-size: 1.8rem;
                font-weight: $f-weight-500;

                @media #{$media-sm} {
                    text-align: center;
                    margin-right: inherit;
                }

                @media #{$media-sm} {
                    font-size: 1.6rem;
                }

            }// .title

            .title2 {

                margin-right: auto;
                color: var(--c-accent);
                font-size: 3.4rem;
                font-weight: $f-weight-600;
                line-height: 1.1;
                text-transform: uppercase;

                @media #{$media-sm} {
                    font-size: 2.4rem;
                    margin-right: inherit;
                    text-align: center;
                }

                @media #{$media-sx} {
                    font-size: 2.2rem;
                }

            }// .title2

        }// .cta-title

        .cta-content {

            background-color: var(--c-corporate-2);

            .title3 {

                color: var(--c-accent);
                font-size: 1.8rem;
                font-weight: $f-weight-500;

            }// .title3

            .link {

                margin-top: 1rem;
                display: inline-flex;
                padding: $gutter*.25 $gutter;

                color: var(--c-white);
                background-color: var(--c-corporate-1);

                font-size: 1.8rem;
                line-height: 1.1;
                font-weight: $f-weight-500;
                text-transform: uppercase;

                &:hover,
                &:focus-within {

                    color: var(--c-corporate-1);
                    background-color: var(--c-accent);

                }

                @media #{$media-sm} {
                    font-size: 1.6rem;
                    text-align: center;
                }

            }// .link

        }// .cta-content




        .social {

            background-color: var(--c-accent);

            .title {

                color: var(--c-corporate-1);
                font-size: 1.8rem;
                font-weight: $f-weight-600;

            }// .title

            .items {

                margin-top: 1rem;
                display: flex;
                flex-wrap: wrap;
                gap: 2rem 1rem;

                @media #{$media-sm} {
                    justify-content: center;
                }

            }// .items

            .link {

                color: var(--c-white);
                display: block;
                width: 5rem;
                height: 5rem;

                svg {

                    display: block;
                    width: 100%;
                    height: 100%;
                    fill: currentColor;

                }// svg

                &:hover,
                &:focus-within {
                    color: var(--c-corporate-1);
                }

            }// .link

        }// .social


    }// .footer__getintouch



    .footer__navigation {

        padding: $gutter;

        display: flex;
        gap: $gutter;

        @media #{$media-uw} {
            width: $w-media-uw;
            margin: auto;
        }
        @media #{$media-m} { flex-direction: column; }
        @media #{$media-sx} { padding: $gutter / 2; }

        .brand {

            flex-shrink: 0;
            width: 20rem;
            height: 7rem;
            color: var(--c-corporate-1);
            fill: currentColor;

            .link,
            svg {
                display: block;
                width: 100%;
                height: 100%;
                fill: currentColor;
            }

        }// .brand

        .footer_menu {

            margin-left: auto;
            margin-right: auto;

            @media #{$media-m} {
                margin-left: initial;
                margin-right: initial;
            }

            .menu {

                display: flex;
                gap: $gutter*2;

                @media #{$media-sm} { flex-wrap: wrap; }

                @media #{$media-sx} {
                    gap: $gutter;
                }

                li {
                    @media #{$media-m} {
                        flex: 1;
                        font-size: 1.4rem;
                    }
                }

                & > .menu-item {

                    // display: flex;
                    // flex-direction: column;

                    & > a,
                    & > span {
                        display: block;
                        font-size: 1.6rem;
                        font-weight: $f-weight-600;
                        text-transform: uppercase;
                        pointer-events: none;
                        user-select: none;
                    }

                }// & > .menu-item

                .sub-menu {

                    margin-top: 2rem;
                    margin-left: 2rem;

                    display: flex;
                    flex-direction: column;
                    gap: $gutter*.25;

                    a {

                        &:hover {
                            background-color: var(--c-accent);
                        }

                    }

                }// .sub-menu

            }// .menu

        }// .footer_menu

    }// .footer__navigation



    .footer__location {

        padding: $gutter*.5 $gutter;

        color: var(--c-corporate-1);
        background-color: var(--c-corporate-5);

        @media #{$media-sx} { padding: $gutter / 2 $gutter / 2; }

        .items {

            display: flex;
            justify-content: space-evenly;
            gap: $gutter*2;

            max-width: 160rem;
            margin: auto;

            @media #{$media-sm} { flex-direction: column; }

            @media #{$media-sx} {
                gap: $gutter;
            }

            .item {

                max-width: 70rem;

                .name {

                    width: 100%;
                    font-size: 2.4rem;
                    font-weight: $f-weight-500;
                    line-height: 1.15;
                    text-transform: uppercase;

                    @media #{$media-sx} {
                        font-size: 2rem;
                    }

                    // @media #{$media-b} { text-align: center; }

                }// .name

                .inner {

                    margin-top: 4rem;
                    display: flex;
                    gap: 4rem;

                    @media #{$media-b} { flex-direction: column; }

                    @media #{$media-sx} {
                        margin-top: 2rem;
                    }

                }// .inner

                .icon {

                    flex-shrink: 0;
                    height: 12rem;

                    &.icon--italy { width: 10rem; }
                    &.icon--usa { width: 20rem; }

                    svg {
                        display: block;
                        width: 100%;
                        height: 100%;
                    }

                }// .icon

                .content {

                    font-size: 1.6rem;
                    line-height: 1.6;

                    @media #{$media-sx} {
                        font-size: 1.4rem !important;
                    }

                    h6 {

                        &:not(:first-of-type) { margin-top: 2rem; }

                        .value {

                            display: flex;
                            align-items: center;

                            &::before {
                                flex-shrink: 0;
                                content: '';
                                display: block;
                                width: 3rem;
                                height: 3rem;
                                margin-right: 2rem;
                                background-position: center;
                                background-size: contain;
                                background-repeat: no-repeat;
                            }

                        }// .value

                        .map {

                            margin-top: 1rem;
                            display: flex;
                            align-items: center;
                            margin-left: 5rem;

                            &::before {
                                content: '';
                                display: block;
                                width: 2rem;
                                height: 2rem;
                                margin-right: 1rem;
                                background-position: center;
                                background-size: contain;
                                background-repeat: no-repeat;
                            }

                        }// .map

                    }
                    .phone .value::before { background-image: url(../images/icon-phone.svg); }
                    .email .value::before { background-image: url(../images/icon-mail.svg); }
                    .address .value::before { background-image: url(../images/icon-address.svg); }
                    .map::before { background-image: url(../images/icon-external.svg); }

                }// .content

            }// .item

        }// .items

    }// .footer__location



    .footer__copy {

        padding: $gutter*.5 $gutter;

        color: var(--c-white);
        background-color: var(--c-theme-2);

        font-size: 1.4rem;
        font-weight: $f-weight-500;

    }// .footer__copy



    .footer__policy {

        padding: $gutter*.5 $gutter;

        color: var(--c-corporate-5);
        background-color: var(--c-corporate-1);

        .items {

            display: flex;
            justify-content: center;
            gap: $gutter*.5;

            @media #{$media-sm} { flex-direction: column; }

            .item {

                font-size: 1.4rem;
                font-weight: $f-weight-500;
                text-transform: uppercase;
                display: flex;
                align-items: center;

                &::before {
                    content: '';
                    display: block;
                    width: .1rem;
                    height: 1.8rem;
                    background-color: var(--c-grey-light);
                    margin-right: $gutter*.5;
                }

                &:first-of-type::before {

                    content: none;
                    @media #{$media-sm} { content: ''; }

                }


                .link {

                    &:hover,
                    &:focus-within {

                        color: var(--c-accent);

                    }

                }// .link

            }// .item

        }// .items

    }// .footer__policy



}// .footer
