/*------------------------------------*\
    Page Template: Area Riservata
    area-riservata.php
    .page-template-area-riservata
\*------------------------------------*/

.page-template-area-riservata {
    #main {
        overflow-x: hidden;
        overflow-y: clip;
    }

    .box--intro {
        margin: 0 auto;
        max-width: $w-media-uw;
        padding-bottom: $gutter / 2;
    }

    .box--section-1 {
        display: flex;
        flex-wrap: wrap;

        @media #{$media-u} {
            max-width: $w-media-uw;
        }

        .col-1 {
            width: 100%;
            position: relative;

            &::after {
                width: 30%;
                height: 184px;
                position: absolute;
                right: 0;
                top: - $gutter * 4;
                content: '';
                background: url('../images/griglia_contatti.svg') no-repeat center center / contain;

                @media #{$media-sm} {
                    display: none;
                }
            }

            .text {
                max-width: $w-media-b;
                padding: 0 $gutter * 2 0 $gutter * 4;

                @media #{$media-bx} {
                    padding-left: $gutter;
                }

                @media #{$media-b} {
                    padding-left: 0;
                    width: 70%;
                }

                @media #{$media-sm} {
                    width: 100%;
                    padding-right: 0;
                }

                @media #{$media-sx} {
                    font-size: 1.4rem;
                }
            }
        }

        .col-2 {
            width: 100%;

            .dashboard--boxes {
                display: flex;
                flex-wrap: wrap;
                max-width: $w-media-u;
                padding: 0 0 0 $gutter * 4;
                margin-top: $gutter;

                @media #{$media-bx} {
                    padding-left: 0;
                }

                .single-box {
                    display: flex;
                    width: $gutter * 6;
                    height: $gutter * 6;
                    background-color: var(--c-corporate-5);
                    border: 1px solid var(--c-corporate-3);
                    text-align: center;
                    margin: 0 $gutter / 2 $gutter 0;
                    transition: 0.15s all ease;

                    @media #{$media-sx} {
                        width: $gutter * 4;
                        height: $gutter * 4;
                        margin: 0 $gutter / 4 $gutter / 2 0;
                    }

                    &:hover:not(.disabled) {
                        background-color: var(--c-corporate-3);
                    }

                    &.disabled {
                        opacity: 0.3;
                    }

                    &.carrello {
                        svg {
                            max-height: 100px;
                        }
                    }

                    svg {
                        path {
                            fill: var(--c-corporate-1);
                        }
                    }

                    a {
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                        align-items: center;
                        width: 100%;
                        height: 100%;
                        padding: $gutter / 2 $gutter / 2 $gutter $gutter / 2;

                        @media #{$media-sx} {
                            padding: $gutter / 2;
                        }

                        span {
                            color: var(--c-theme-4);
                            font-weight: 500;
                            line-height: 1.2;
                            margin-bottom: $gutter / 2;
                        }

                        svg {
                            max-width: 90%;

                            g {
                                fill: var(--c-corporate-1)
                            }
                        }
                    }
                }

                .dashboard--msg {
                    background-color: var(--c-theme-2);
                    color: var(--c-white);

                    width: 100%;
                    margin-bottom: $gutter;
                    padding: $gutter / 3 $gutter / 2;
                    display: flex;
                    align-items: center;

                    @media #{$media-sx} {
                        font-size: 1.4rem;
                    }

                    a {
                        display: flex;
                        align-items: center;
                    }

                    .link {
                        margin-left: $gutter / 2;
                        display: flex;
                        align-items: center;

                        &::before {
                            content: '';
                            display: block;
                            width: 2rem;
                            height: 2rem;
                            background-position: center;
                            background-size: contain;
                            background-repeat: no-repeat;
                            background-image: url(../images/icon-external.svg);
                            filter: brightness(100);
                        }

                    }

                    // .link
                }

            }


            .logout {
                display: flex;
                justify-content: center;

                a {
                    display: flex;
                    justify-content: center;
                    position: relative;
                    width: fit-content;
                    border: 1px solid var(--c-corporate-1);
                    font-size: 1.4rem;
                    padding: $gutter / 1.5 $gutter 0 $gutter;
                    transition: 0.15s all ease;

                    &::before {
                        width: 15px;
                        height: 19px;
                        position: absolute;
                        top: 5px;
                        content: '';
                        background: url('../images/simbolo_logo.svg') no-repeat center center / contain;
                    }

                    &:hover {
                        background-color: var(--c-grey-light);
                    }
                }
            }
        }
    }

    // .box--section-1
}

// .page-template-area-riservata