/* Magnific Popup CSS */
.mfp-bg {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1042;
    overflow: hidden;
    position: fixed;
    background: var(--c-bg);
    opacity: 1;
}

.mfp-wrap {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1043;
    position: fixed;
    outline: none !important;
    -webkit-backface-visibility: hidden;
}

.mfp-container {
    text-align: center;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    padding: 0 8px;
    box-sizing: border-box;
}

.mfp-container:before {
    content: '';
    display: inline-block;
    height: 100%;
    vertical-align: middle;
}

.mfp-align-top .mfp-container:before {
    display: none;
}

.mfp-content {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    margin: 0 auto;
    text-align: left;
    z-index: 1045;
}

.mfp-inline-holder .mfp-content,
.mfp-ajax-holder .mfp-content {
    width: 100%;
    cursor: auto;
}

.mfp-ajax-cur {
    cursor: progress;
}

button.mfp-close {
    cursor: pointer;
    cursor: -webkit-zoom-out;
    cursor: -moz-zoom-out;
    cursor: zoom-out;
}

.mfp-zoom {
    cursor: pointer;
    cursor: -webkit-zoom-in;
    cursor: -moz-zoom-in;
    cursor: zoom-in;
}

.mfp-auto-cursor .mfp-content {
    cursor: auto;
}

.mfp-close,
.mfp-arrow,
.mfp-preloader,
.mfp-counter {
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
}

.mfp-loading.mfp-figure {
    display: none;
}

.mfp-hide {
    display: none !important;
}

.mfp-preloader {
    color: #CCC;
    position: absolute;
    top: 50%;
    width: auto;
    text-align: center;
    margin-top: -0.8em;
    left: 8px;
    right: 8px;
    z-index: 1044;
}

.mfp-preloader a {
    color: #CCC;
}

.mfp-preloader a:hover {
    color: #FFF;
}

.mfp-s-ready .mfp-preloader {
    display: none;
}

.mfp-s-error .mfp-content {
    display: none;
}

button.mfp-close {
    overflow: visible;
    cursor: pointer;
    background: transparent;
    border: 0;
    -webkit-appearance: none;
    display: block;
    outline: none;
    padding: 0;
    z-index: 1046;
    box-shadow: none;
    touch-action: manipulation;
}

button::-moz-focus-inner {
    padding: 0;
    border: 0;
}

.mfp-close {
    width: 44px;
    height: 44px;
    line-height: 44px;
    position: fixed;
    text-decoration: none;
    text-align: center;
    opacity: 1;
    padding: 0 0 18px 10px;
    font-style: normal;
    font-size: 28px;
    font-family: $f-family-title;
    top: 2rem;
    right: 2rem;
    color: transparent;

    &::before {
        content: 'X';
        position: absolute;
        color: var(--c-black);
        width: 50px;
        height: 50px;
        right: 0;
        top: 0;
        text-align: center;
        transform: scaleX(1.5);
    }
}

.mfp-close:hover,
.mfp-close:focus {
    opacity: 1;
}

.mfp-close:active {
    top: 1px;
}

.mfp-close-btn-in .mfp-close {
    color: #333;
}

.mfp-image-holder .mfp-close,
.mfp-iframe-holder .mfp-close {
    color: #FFF;
    right: 4rem;
    text-align: right;
    width: 100%;
}

.mfp-counter {
    position: absolute;
    top: 0;
    right: 0;
    color: var(--c-corporate-1);
    font-size: 12px;
    line-height: 18px;
    white-space: nowrap;
    display: none;
}

.mfp-arrow {
    position: absolute;
    bottom: - $gutter * 1.5;
    z-index: 2;
    color: transparent;
    border-radius: 20px;
    background-color: var(--c-corporate-5) !important;
    background-image: url('../images/icon_arrow.svg') !important;
    background-repeat: no-repeat !important;
    background-size: 50% !important;
    border: 0;
    outline: none;
    width: 51px;
    height: 30px;
    background-position: center !important;
    display: flex !important;
    justify-content: center;
    align-items: center;
    transition: $trans-default;
}

.mfp-arrow:active {
    margin-top: -54px;
}

.mfp-arrow:hover,
.mfp-arrow:focus {
    opacity: 1;
}

.mfp-arrow:before,
.mfp-arrow:after {
    display: none;
}

.mfp-arrow:after {
    border-top-width: 13px;
    border-bottom-width: 13px;
    top: 8px;
}

.mfp-arrow:before {
    border-top-width: 21px;
    border-bottom-width: 21px;
    opacity: 0.7;
}

.mfp-arrow-left {
    transform: rotate(180deg);
    left: $gutter;
}

.mfp-arrow-left:after {
    border-right: 17px solid #FFF;
    margin-left: 31px;
}

.mfp-arrow-left:before {
    margin-left: 25px;
    border-right: 27px solid #3F3F3F;
}

.mfp-arrow-right {
    right: $gutter;
}

.mfp-arrow-right:after {
    border-left: 17px solid #FFF;
    margin-left: 39px;
}

.mfp-arrow-right:before {
    border-left: 27px solid #3F3F3F;
}

.mfp-iframe-holder {
    padding-top: 40px;
    padding-bottom: 40px;
}

.mfp-iframe-holder .mfp-content {
    line-height: 0;
    width: 100%;
    max-width: 900px;
}

.mfp-iframe-holder .mfp-close {
    top: -40px;
}

.mfp-iframe-scaler {
    width: 100%;
    height: 0;
    overflow: hidden;
    padding-top: 56.25%;
}

.mfp-iframe-scaler iframe {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
    background: #000;
}

/* Main image in popup */
img.mfp-img {
    width: auto;
    max-width: 100%;
    height: auto;
    display: block;
    line-height: 0;
    box-sizing: border-box;
    padding: 40px 0 40px;
    margin: 0 auto;
}

/* The shadow behind the image */
.mfp-figure {
    line-height: 0;
}

.mfp-figure:after {
    content: '';
    position: absolute;
    left: 0;
    top: 40px;
    bottom: 40px;
    display: block;
    right: 0;
    width: auto;
    height: auto;
    z-index: -1;
    box-shadow: none;
    background: #444;
}

.mfp-figure small {
    color: #BDBDBD;
    display: block;
    font-size: 12px;
    line-height: 14px;
}

.mfp-figure figure {
    margin: 0;
}

.mfp-bottom-bar {
    margin-top: -36px;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    cursor: auto;
}

.mfp-title {
    text-align: center;
    line-height: 18px;
    color: var(--c-corporate-1);
    word-wrap: break-word;
    padding: $gutter / 2 0;
}

.mfp-image-holder .mfp-content {
    max-width: 100%;
}

.mfp-gallery .mfp-image-holder .mfp-figure {
    cursor: pointer;
}

@media screen and (max-width: 800px) and (orientation: landscape),
screen and (max-height: 300px) {

    /**
         * Remove all paddings around the image on small screen
         */
    .mfp-img-mobile .mfp-image-holder {
        padding-left: 0;
        padding-right: 0;
    }

    .mfp-img-mobile img.mfp-img {
        padding: 0;
    }

    .mfp-img-mobile .mfp-figure:after {
        top: 0;
        bottom: 0;
    }

    .mfp-img-mobile .mfp-figure small {
        display: inline;
        margin-left: 5px;
    }

    .mfp-img-mobile .mfp-bottom-bar {
        background: rgba(0, 0, 0, 0.6);
        bottom: 0;
        margin: 0;
        top: auto;
        padding: 3px 5px;
        position: fixed;
        box-sizing: border-box;
    }

    .mfp-img-mobile .mfp-bottom-bar:empty {
        padding: 0;
    }

    .mfp-img-mobile .mfp-counter {
        right: 5px;
        top: 3px;
    }

    .mfp-img-mobile .mfp-close {
        position: fixed;
        top: 2rem;
        right: 2rem;
        color: transparent;

        &::before {
            content: 'X';
            position: absolute;
            color: var(--c-black);
            width: 50px;
            height: 50px;
            right: 0;
            top: 0;
        }
    }
}

@media all and (max-width: 900px) {
    .mfp-arrow {
        -webkit-transform: scale(0.75);
        transform: scale(0.75);
    }

    .mfp-arrow-left {
        -webkit-transform-origin: 0;
        transform-origin: 0;
    }

    .mfp-arrow-right {
        -webkit-transform-origin: 100%;
        transform-origin: 100%;
    }

    .mfp-container {
        padding-left: 6px;
        padding-right: 6px;
    }
}


.popupGenerico {
    background-color: rgba(35, 59, 107, 0.70);

    .mfp-content {
        max-width: 630px;

        @media #{$media-s} {
            width: 90%;
        }
    }

    .mfp-close {
        display: none;
    }

    .white-popup {
        border: 5px solid var(--c-accent);
        background-color: var(--c-white);
        position: relative;
        padding: $gutter / 3 $gutter / 2;

        .closePopup {
            position: absolute;
            top: -3rem;
            right: -3rem;
            border-radius: 100%;
            background-color: var(--c-red);
            border: 2px solid var(--c-white);
            color: var(--c-white);
            font-size: 2rem;
            width: 40px;
            height: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-weight: bold;
            cursor: pointer;
        }
    }
}
// .popupGenerico


#addToCartPopup, #addToWishlistPopup {
    .content {
        .preloader {
            display: flex;
            justify-content: center;
        }

        .top {
            display: flex;
            align-items: center;

            #ecommerceProductName, #ecommerceProductNameWishlist {
                font-family: $f-family-title;
                font-size: 6rem;
                font-weight: $f-weight-700;
                color: var(--c-accent);
                line-height: 1;

                @media #{$media-sx} {
                    font-size: 3rem;
                }
            }

            #ecommerceProductMaterial {
                margin-left: $gutter;

                .value {
                    display: flex;
                    align-items: center;
                    gap: 1rem;
                    text-transform: capitalize;
                    font-size: 1.8rem;

                    &::before {
                        display: inline-flex;
                        justify-content: center;
                        align-items: center;
                        border-radius: 50%;
                        color: var(--c-white);
                        width: 2.4rem;
                        height: 2.4rem;
                        font-size: 1.5rem;
                        font-weight: $f-weight-700;
                    }
                }

                &.carbon-steel {
                    .value {
                        &::before {
                            content: 'CS';
                            background-color: var(--c-carbon);
                        }
                    }
                }

                &.stainless-steel {
                    .value {
                        &::before {
                            content: 'SS';
                            background-color: var(--c-stainless);
                        }
                    }
                }
            }

        }

        // .top

        .table_content {
            &.hidden {
                display: none;
            }

            .table {
                display: flex;
                flex-direction: column;
                margin-top: $gutter / 2;
                gap: 1rem;
                font-family: $f-family-title;

                .row {
                    display: flex;
                    align-items: center;
                    gap: 2rem;

                    @media #{$media-s} {
                        gap: 0;
                    }

                    &.rigaPrezzo {
                        /* display: none; */
                    }

                    .left {
                        font-size: 2.2rem;
                        font-weight: $f-weight-500;
                        color: var(--c-theme-4);
                        flex: 1;

                        @media #{$media-s} {
                           font-size: 1.6rem;
                           flex: 1.5;
                        }
                    }

                    .right {
                        flex: 2;
                        display: flex;
                        align-items: center;
                        gap: 5px;

                        @media #{$media-sx} {
                            font-size: 1.5rem;
                         }

                        &.modelName {
                            font-weight: $f-weight-700;
                        }

                        &.modelMaterial {
                            text-transform: capitalize;
                        }

                        &.modelQnt {
                            input {
                                width: 50px;
                                text-align: center;
                                padding: 0.6rem 0rem;
                                pointer-events: none;
                            }

                            button {
                                color: var(--c-white);
                                background-color: var(--c-corporate-1);
                                border: 0;
                                width: 30px;
                                height: 32px;
                                font-size: 2rem;
                            }
                        }
                    }
                }
            }

            // .table

            .bottom {
                display: flex;
                justify-content: center;
                margin: $gutter 0;

                button {
                    background-color: var(--c-electric);
                    color: var(--c-white);
                    border: 0;
                    font-size: 1.4rem;
                    padding: 1rem 1rem 1rem 4rem;
                    text-transform: uppercase;
                    position: relative;

                    &::before {
                        content: '';
                        position: absolute;
                        left: 1rem;
                        width: 22px;
                        height: 17px;
                        background: url('../images/carrello.svg') no-repeat center / contain;
                        filter: invert(1);
                        pointer-events: none;
                    }
                }
            }

            //.bottom
        }

        .preloader {
            &.hidden {
                display: none;
            }
        }

        // .preloader

        .resultMessage {
            display: flex;
            gap: 20px;
            justify-content: center;
            padding: 2rem 1rem;

            &.hidden {
                display: none;
            }

            span {
                padding: 0.5rem 1rem;
                color: var(--c-white);
                font-size: 1.7rem;

                &.hidden {
                    display: none;
                }

                &.error {
                    background-color: var(--c-error);
                }

                &.message {
                    background-color: var(--c-electric);
                }
            }

            a {
                padding: 0.5rem 1rem;
                background-color: var(--c-corporate-1);
                color: var(--c-white);
            }
        }

        //.resultMessage
    }
}

#addToWishlistPopup {
    .content {
        .table_content {
            .bottom {
                button {
                    &::before {
                        background: url('../images/icon-wishlist-add.svg') no-repeat center / contain;
                        filter: invert(1) brightness(2);
                    }
                }
            }
        }
    }
}