/*------------------------------------*\
    Page Template: Form
    form.php
    .page-template-form
\*------------------------------------*/

.page-template-form {
    #main {
        overflow-x: hidden;
        overflow-y: clip;
    }

    .box--intro {
        margin: 0 auto;
        max-width: $w-media-uw;
        padding-bottom: $gutter / 2;
    }
    
    .box--section-1 {
        display: flex;
        flex-wrap: wrap;

        @media #{$media-u} {
            max-width: $w-media-uw;
        }

        .col-1 {
            width: 100%;
            position: relative;

            &::after {
                width: 30%;
                height: 184px;
                position: absolute;
                right: 0;
                top: - $gutter * 4;
                content: '';
                background: url('../images/griglia_contatti.svg') no-repeat center center / contain;

                @media #{$media-sm} {
                    display: none;
                }
            }

            .text {
                width: 50%;
                padding: 0 $gutter * 2 0 $gutter * 4;

                @media #{$media-bx} {
                    padding-left: $gutter;
                }

                @media #{$media-b} {
                    padding-left: 0;
                    width: 70%;
                }

                @media #{$media-sm} {
                    width: 100%;
                    padding-right: 0;
                }

                @media #{$media-sx} {
                    font-size: 1.4rem;
                }
            }
        }
    }

    // .box--section-1

    .box--section-2 {
        display: flex;
        flex-wrap: wrap;
        margin-top: $gutter;
        padding-bottom: $gutter * 2;

        @media #{$media-u} {
            max-width: $w-media-uw;
        }

        @media #{$media-sx} {
            margin-top: $gutter / 3;
            padding-bottom: $gutter;
        }

        .col-1 {
            width: 100%;
            padding-left: $gutter * 2;

            @media #{$media-bx} {
                padding-left: $gutter;
            }

            @media #{$media-b} {
                padding-left: 0;
            }

            .text {
                font-size: 3rem;

                @media #{$media-s} {
                    font-size: 2.2rem;
                }
            }
        }

        .col-2 {
            width: 50%;
            padding-left: $gutter * 2;

            @media #{$media-bx} {
                padding-left: $gutter;
            }

            @media #{$media-b} {
                padding-left: 0;
                width: 60%;
            }

            @media #{$media-m} {
                width: 100%;
            }

            .gform_required_legend {
                display: none;
            }

            .ginput_counter {
                display: none;
            }

            input[type=radio] {

                flex-shrink: 0;
                width: 3rem !important;
                height: 3rem;
                margin-top: 0 !important;
                margin-right: 1rem;
                vertical-align: middle;
                accent-color: var(--c-accent);
    
            }

            .ginput_container_radio {
                .gfield_radio {
                    display: flex;
                    
                    .gchoice {
                        margin-right: $gutter;
                        label {
                            font-size: 1.8rem;
                            margin-left: $gutter / 4;
                        }
                    }
                }
            }

            .gform_footer {
                display: flex;
                justify-content: center;
                padding-bottom: $gutter !important;

                button {
                    margin-top: 0;
                    border: 0;
                    background-color: var(--c-corporate-1);
                    color: var(--c-white);
                    text-transform: uppercase;
                    padding: $gutter / 6 $gutter * 3 $gutter / 6 $gutter * 2;
                    position: relative;
                    transition: 0.15s all ease;

                    &::after {
                        position: absolute;
                        content: '';
                        background-color: var(--c-white);
                        clip-path: polygon(100% 100%, 0% 100%, 100% 0);
                        right: -1px;
                        bottom: -1px;
                        width: 35px;
                        height: 101%;
                        margin-top: 0;
                    }

                    &:hover {
                        background-color: var(--c-corporate-2);
                    }
                }
            }

            .contatti_ok {
                .image {
                    max-width: 100px;
                    margin: 0 auto;

                    img {
                        height: auto;
                    }
                }
            }
        }
    }

    // .box--section-2
}

// .page-template-form