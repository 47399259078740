/*------------------------------------*\
   Paginazione ( custom_pagination() --> extra  )
   .pagination
\*------------------------------------*/

.pagination {

	margin-top: 8rem;
	display: inline-flex;
	gap: 2rem;
	flex-wrap: wrap;
	justify-content: center;

	span,
    a {

		display: flex;
		justify-content: center;
		align-items: center;
		width: 5rem;
		height: 5rem;
		margin: 0;
		padding: 0;
		border: .1rem solid var(--c-grey-light);
		font-size: 1.8rem;

    }

	.current {

		color: var(--c-white);
		position: relative;
		font-weight: normal;

		&::before {
			content: '';
			width: 4rem;
			height: 4rem;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translateY(-50%) translateX(-50%);
			background-color: var(--c-corporate-2);
			z-index: -1;
		}

	}// .current

	a {

		&:hover:not(.current),
		&:focus-within:not(.current) { border-color: currentColor; }

	}

	.first { margin: 0; }

	.first,
	.prev,
	.next,
	.last {}

}// .pagination
