/*------------------------------------*\
    Page Template: Homepage
    homepage.php
    .page-template-homepage
\*------------------------------------*/

.page-template-homepage {


    .home--slider {

        height: calc(100vh - var(--h-header) + 12rem);
        min-height: 90rem;
        max-height: 96rem;
        position: relative;
        overflow: hidden;
        margin-top: - $gutter * 3;

        @media #{$media-sm} {
            height: auto;
            margin-top: 0;
            padding-bottom: 10rem;
            min-height: auto;
        }

        .items {

            height: 100%;
            position: relative;

            .slick-list,
            .slick-track,
            .slick-slide,
            .item {
                width: 100%;
                height: 100%;
            }

            .slick-dots {

                position: absolute;
                bottom: 3rem;
                right: 0;
                width: auto;
                margin: 0 $gutter $gutter*.5;

                display: flex;
                justify-content: center;
                align-items: baseline;
                gap: $gutter*.5;

                @media #{$media-sm} {
                    bottom: -6rem;
                    right: auto;
                    width: 100%;
                    margin: 0 auto;
                }

                &::before,
                &::after {
                    content: '';
                    width: 100%;
                    /* height: .1rem;
                    background-color: var(--c-grey-normal); */
                }

                li {

                    flex-shrink: 0;

                    button {

                        padding: 0;
                        font-family: $f-family-title;
                        border: none;
                        background-color: transparent;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        position: relative;

                        @media #{$media-sm} {
                            transform: scale(0.8);
                        }

                        .number {

                            font-size: 2.4rem;
                            font-weight: $f-weight-700;
                            position: relative;
                            border: 2px solid var(--c-corporate-1);
                            padding: 0 0.5rem;
                            width: 42px;
                            height: 35px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            margin-bottom: 0.5rem;
                            color: var(--c-corporate-1);

                        }

                        // .number

                        .label {

                            font-size: 1.3rem;
                            font-weight: $f-weight-500;
                            text-transform: uppercase;
                            color: var(--c-corporate-1);

                            @media #{$media-sm} {
                                display: none;
                            }

                        }

                        // .label

                        &:hover,
                        &:focus-within {
                            color: var(--c-corporate-1);
                        }

                    }

                    // button

                    .number_accent {
                        color: var(--c-accent);
                        font-size: 2.4rem;
                        font-weight: $f-weight-700;
                        position: relative;
                        padding: 0 0.5rem;
                        width: 42px;
                        height: 35px;
                        margin-bottom: 0.5rem;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        position: absolute;
                        z-index: 1;
                        background-color: var(--c-corporate-1);
                        clip-path: polygon(0 0, 0 0, 0 100%, 0% 100%);
                        transition: all 0.01s linear;
                    }

                    &.slick-active {
                        button {
                            .number_accent {
                                transition: all 5s linear;
                                clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
                            }
                        }
                    }

                }

                // li

            }

            // .slick-dots

            .item {


                &.color-- {
                    --c-family: var(--c-accent);
                }

                &.color--hydro {
                    --c-family: var(--c-hydro);
                }

                &.color--oilgas {
                    --c-family: var(--c-oil);
                }

                &.color--electric {
                    --c-family: var(--c-electric);
                }

                .box--top-middle {
                    z-index: 10000;
                    padding-top: 12rem;
                    max-width: none;
                    margin-left: auto;
                    text-align: center;
                    padding-bottom: 0;

                    @media #{$media-sm} {
                        text-align: left;
                        padding: $gutter / 2;
                    }

                    .title2 {
                        margin: $gutter / 2.5 0;
                        color: var(--c-corporate-1);
                        display: block;

                        @media #{$media-sm} {
                            margin: $gutter / 4 0;
                        }
                    }

                    // .title2
                }

                .slide--content {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    padding: $gutter;
                    height: 60rem;
    
                    position: relative;
    
                    @media #{$media-sm} {
                        flex-direction: column;
                        align-items: flex-start;
                        padding: 0 2rem;
                    }
    
                    .box--title {
    
                        flex-shrink: 0;
                        margin: inherit;
                        margin-top: -8rem;
                        padding: 0;
                        padding-left: 12rem;
                        width: 32vw;
                        max-width: 66rem;

                        @media #{$media-bx} {
                            padding-left: 0;
                        }

                        @media #{$media-b} {
                            width: 42vw;
                        }
    
                        @media #{$media-sm} {
                            width: 100%;
                            margin-top: 0;
                            padding-top: 0;
                        }
    
                        .title {}
    
                        // .title
    
                        .title3 {
    
                            font-family: $f-family-title;
                            // font-size: 3.2rem;
                            font-size: 2.8rem;
                            font-weight: $f-weight-400;
                            line-height: 1.2;
                            margin-bottom: $gutter / 2;
                            max-width: 80%;
                            display: block;
    
                            @media #{$media-sm} {
                                font-size: 2rem;
                                max-width: none;
                            }
    
                        }
    
                        // .title3
    
                        .text {
                            margin-top: $gutter;
                            margin-bottom: $gutter / 2;
                            max-width: 80%;
    
                            @media #{$media-sm} {
                                margin-top: $gutter / 2;
                                margin-bottom: 0;
                                max-width: none;
                            }
                        }
    
                        // .text
    
                        .link {
    
                            margin-top: $gutter;
                            display: inline-flex;
                            align-items: center;
                            position: relative;
                            height: 4.5rem;
                            padding-left: $gutter*.5;
                            padding-right: $gutter*2;
    
                            font-family: $f-family-title;
                            font-weight: $f-weight-600;
                            font-size: 1.8rem;
                            line-height: 1;
                            text-transform: uppercase;
                            color: var(--c-corporate-1);
                            background-color: var(--c-corporate-5);
    
                            &::after {
    
                                display: inline-flex;
                                content: '';
                                width: 0;
                                height: 0;
                                position: absolute;
                                top: 0;
                                right: 0;
                                border-left: 4.5rem solid transparent;
                                border-right: initial;
                                border-top: initial;
                                border-bottom: 4.5rem solid var(--c-white);
    
                            }
    
                            &:hover,
                            &:focus-within {
                                color: var(--c-white);
                                background-color: var(--c-corporate-1);
                            }

                            @media #{$media-bx} {
                                max-width: 28rem;
                            }

                            @media #{$media-sm} {
                                max-width: none;
                            }
    
                        }
    
                        // .link
    
                    }
    
                    // .box--title
    
                    .content--image {
    
                        display: flex;
                        gap: 1vw;
    
                        @media #{$media-bx} {
                            gap: 2vw;
                        }
    
                        @media #{$media-b} {
                            margin-top: -8rem;
                            gap: 8rem;
                        }
    
                        @media #{$media-sm} {
                            flex-direction: column;
                            position: relative;
                            left: 0;
                            right: auto;
                            margin-top: 10rem;
                            top: 0;
                            width: 100%;
                        }
    
                        .link {
    
                            display: block;
                            width: 100%;
                            height: 100%;
                            position: absolute;
                            top: 0;
                            left: 0;
                            z-index: 1;
    
                        }
    
                        // .link
    
                        .image {
    
                            position: relative;
    
                            .img {
    
                                position: absolute;
                                width: 60rem;
                                object-fit: cover;
                                top: 50%;
                                left: 25%;
                                transform: translateY(-50%) translateX(-22%);
                                z-index: 1;

                                @media #{$media-bx} {
                                    object-fit: contain;
                                }
    
                                @media #{$media-b} {
                                    height: 100%;
                                    top: 50%;
                                    left: 0;
                                    transform: translateY(-50%) translateX(-2.5%);
                                }
    
                                @media #{$media-sm} {
                                    transform: none;
                                    top: -9rem;
                                    width: 60rem;
                                    height: 29rem;
                                }
    
                            }
    
                            // .img
    
                            .bg {
    
                                max-width: 68rem;
                                width: 41vw;
                                height: 24rem;
                                background: rgb(15, 30, 56);
                                background: radial-gradient(circle, rgba(15, 30, 56, 1) 0%, rgba(29, 59, 111, 1) 50%, rgba(15, 30, 56, 1) 100%);
    
                                @media #{$media-bx} {
                                    max-width: 40rem;
                                }
    
                                @media #{$media-b} {
                                    max-width: 30rem;
                                    width: 20vw;
                                    height: 24rem;
                                }
    
                                @media #{$media-m} {}
    
                                @media #{$media-sm} {
                                    background-color: transparent;
                                    max-width: none;
                                    width: 100%;
                                }
    
                            }
    
                            // .bg
    
                        }
    
                        // .image
    
                        .image2 {
    
                            position: relative;
    
                            @media #{$media-sm} {
                                display: none;
                            }
    
                            // @media #{$media-b} { display: none; }
                            // @media #{$media-m} { display: block; }
    
                            .img {
    
                                position: absolute;
                                max-width: 36rem;
                                height: 36rem;
                                object-fit: cover;
                                top: 50%;
                                left: 0;
                                transform: translateY(-50%) translateX(-2.5%);
                                z-index: 1;
    
                                @media #{$media-b} {
                                    height: 100%;
                                    top: 50%;
                                    left: 0;
                                    transform: translateY(-50%) translateX(-2.5%);
                                }
    
                            }
    
                            // .img
    
                            .bg {
    
                                max-width: 32rem;
                                width: 20vw;
                                height: 24rem;
                                background-color: var(--c-family);
    
                                position: relative;
    
                                @media #{$media-bx} {
                                    max-width: 30rem;
                                }
    
                                @media #{$media-sm} {
                                    background-color: transparent;
                                }
    
                                &::after {
    
                                    display: inline-flex;
                                    content: '';
                                    width: 0;
                                    height: 0;
                                    position: absolute;
                                    bottom: 0;
                                    right: 0;
                                    border-left: 4.5rem solid transparent;
                                    border-right: initial;
                                    border-top: initial;
                                    border-bottom: 4.5rem solid var(--c-white);
    
                                }
    
                            }
    
                            // .bg
    
                        }
    
                        // .image2
    
                    }
    
                    // .content--image
    
                    .circle,
                    .circle2 {
    
                        display: block;
                        position: absolute;
                        z-index: -1;
                        left: 50%;
                        top: 50%;
                        transform: translateY(-50%) translateX(-50%);
    
                        border-radius: 50%;
                        border: .2rem dashed var(--c-grey-normal);
    
                        @media #{$media-b} {
                            display: none;
                        }
    
                    }
    
                    .circle {
    
                        width: 60rem;
                        height: 60rem;
    
                    }
    
                    // .circle
    
                    .circle2 {
    
                        width: 48rem;
                        height: 48rem;
    
                    }
    
                    // .circle2
    
                    .shadow {
    
                        display: block;
                        width: 80rem;
                        height: 80rem;
                        position: absolute;
                        z-index: -1;
                        left: 50%;
                        top: 50%;
                        transform: translateY(-50%) translateX(-50%);
    
                        background: radial-gradient(circle, rgba(204, 213, 230, 1) 0%, rgba(204, 213, 230, .45) 40%, transparent 60%, transparent 100%);
    
                        @media #{$media-b} {
                            display: none;
                        }
    
    
                    }
    
                    // .shadow
                }

            }

            // .item

        }

        // .items

        .line {

            display: block;
            position: absolute;
            bottom: 0;
            right: 0;
            z-index: -1;

            width: 20rem;
            height: 20rem;

        }

        // .line

    }

    // .home--slider

    .home--company,
    .home--sustainability {

        .content {

            .title {

                max-width: 80rem;
                // font-size: 6.3rem;
                font-size: 4.3rem;
                font-weight: $f-weight-500;
                line-height: 1.1;
                text-transform: uppercase;

                @media #{$media-b} {
                    font-size: 4rem;
                }

                @media #{$media-s} {
                    font-size: 3rem;
                }

                span {
                    color: var(--c-theme-5);
                }

            }

            // .title

            .text {

                max-width: 80rem;
                font-size: 1.8rem;
                line-height: 1.6;

                @media #{$media-sx} {
                    font-size: 1.6rem;
                }

            }

            // .text

            .link {

                margin-top: $gutter;
                display: inline-flex;
                align-items: center;
                position: relative;
                height: 4.5rem;
                padding-left: $gutter*.5;
                padding-right: $gutter*2;

                font-family: $f-family-title;
                font-weight: $f-weight-600;
                font-size: 1.8rem;
                line-height: 1;
                text-transform: uppercase;
                color: var(--c-white);
                background-color: var(--c-corporate-1);

                @media #{$media-sx} {
                    font-size: 1.5rem;
                }

                &::after {

                    display: inline-flex;
                    content: '';
                    width: 0;
                    height: 0;
                    position: absolute;
                    top: 0;
                    right: 0;
                    border-left: 4.5rem solid transparent;
                    border-right: initial;
                    border-top: initial;
                    border-bottom: 4.5rem solid var(--c-white);

                }

                &:hover,
                &:focus-within {
                    color: var(--c-corporate-1);
                    background-color: var(--c-corporate-5);
                }

            }

            // .link

        }

        // .content

    }

    .home--company {

        background-color: var(--c-corporate-5);
        display: flex;

        @media #{$media-b} {
            flex-direction: column;
            align-items: center;
        }

        .image {

            flex-shrink: 0;
            width: 45%;
            position: relative;
            overflow: hidden;

            @media #{$media-b} {
                width: 35%;
            }

            @media #{$media-m} {
                width: 100%;
                height: 40rem;
            }

            .img {
                display: block;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }

            // .img

            .bg {
                display: block;
                position: absolute;
                top: 0;
                right: -.1rem;
                width: auto;
                height: 100.1%;
                fill: var(--c-corporate-5);
            }

            // .bg

        }

        // .image

        .content {

            width: 30%;
            padding: $gutter * 1.5 $gutter / 2 $gutter * 1.5 $gutter;

            @media #{$media-b} {
                width: 100%;
                padding: $gutter $gutter;
            }

            @media #{$media-s} {
                padding: $gutter;
            }

            @media #{$media-sx} {
                padding: $gutter $gutter / 2;
            }

            .title {

                position: relative;
                padding-bottom: 3rem;
                margin-bottom: 3rem;

                @media #{$media-bx} {
                    padding-bottom: 1rem;
                    margin-bottom: 1rem;
                    font-size: 3rem;
                }

                @media #{$media-b} {
                    padding-bottom: 2rem;
                    margin-bottom: 2rem;
                    font-size: 3.6rem;
                }

                @media #{$media-sm} {
                    padding-bottom: 2rem;
                    margin-bottom: 2rem;
                    font-size: 2.6rem;
                }

            }

            // .title

            .text {
                @media #{$media-bx} {
                    font-size: 1.4rem;
                }

                @media #{$media-b} {
                    font-size: 1.6rem;
                }
            }

            // .text

            .link {

                &::after {
                    border-bottom-color: var(--c-corporate-5);
                }

                &:hover,
                &:focus-within {
                    background-color: var(--c-corporate-4);
                }

            }

            // .link

            .video_page {
                display: flex;
                position: relative;
                padding-right: $gutter * 1.5;
                margin-top: $gutter / 2;
                width: max-content;

                &::after {
                    content: '';
                    position: absolute;
                    right: 0;
                    width: 36px;
                    height: 25px;
                    background: url('../images/play_white.png') no-repeat center / contain;
                }
            }
            // .video_page

            .video {

                margin-top: $gutter;
                display: flex;
                align-items: center;
                gap: 2rem;

                .video-link {

                    flex-shrink: 0;
                    display: block;
                    width: 10rem;
                    height: 7rem;

                    .icon {
                        display: block;
                        width: 100%;
                        height: 100%;
                    }

                    // .icon

                }

                // .video-link

                .video-content {


                    .video-title {
                        font-size: 2rem;
                        font-weight: $f-weight-500;
                    }

                    // .video-title

                    .video-text {
                        font-size: 1.6rem;
                        line-height: 1.2;
                    }

                    // .video-text

                }

                // .video-content

            }

            // .video

        }

        // .content

        .video_home {
            width: 70%;
            position: relative;
            overflow: hidden;
            display: flex;
            justify-content: flex-end;
            pointer-events: none;

            @media #{$media-b} {
                width: 96%;
                pointer-events: all;
                margin-bottom: $gutter / 2;
                order: 2;
            }

            .video {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;

                iframe {
                    @media #{$media-b} {
                        width: 100%;
                        height: 100%;
                    }
                }
            }

            .forma_video_dx {
                position: relative;
                z-index: 1;
                height: 101%;
                margin: -1px -3px 0 0;
                align-self: center;

                @media #{$media-b} {
                    height: auto;
                    width: 20vw;
                    margin-left: -2px;
                }
            }
        }

        // .video_home

    }

    // .home--company



    .home--sustainability {

        padding: $gutter;
        max-width: 160rem;

        margin: $gutter*3 auto;

        display: flex;
        gap: $gutter*2;

        @media #{$media-m} {
            flex-direction: column;
            gap: $gutter;
        }

        @media #{$media-sx} {
            margin-bottom: 0;
            padding: $gutter $gutter / 2;
        }

        .image {

            position: relative;
            width: 50rem;

            @media #{$media-b} {
                width: 50rem;
            }

            @media #{$media-m} {
                width: 100%;
            }

            .img {
                display: block;
                width: 100%;
                height: 28rem;
                object-fit: cover;
                border-top-right-radius: 8rem;

                @media #{$media-b} {
                    height: 40rem;
                }
            }

            // .img

            .caption {

                display: flex;
                justify-content: center;
                gap: 4rem;
                padding: $gutter*.5;
                background-color: var(--c-grey-light);

                @media #{$media-s} {
                    flex-direction: column;
                    align-items: flex-start;
                    gap: 2rem;
                }

                .info {

                    display: flex;
                    align-items: center;
                    gap: 2rem;

                    @media #{$media-s} {
                        width: 100%;
                    }

                    .number {

                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        flex-shrink: 0;
                        border-radius: 50%;
                        width: 9rem;
                        height: 9rem;
                        background-color: var(--c-corporate-1);

                        font-family: $f-family-title;
                        color: var(--c-theme-5);

                        @media #{$media-s} {
                            width: 7rem;
                            height: 7rem;
                        }

                        .value {
                            font-size: 4rem;
                            line-height: 1;
                            font-weight: $f-weight-500;

                            @media #{$media-s} {
                                font-size: 3rem;
                            }
                        }

                        // .value

                        .label {
                            font-size: 1.8rem;
                            line-height: 1;
                            text-transform: uppercase;
                            font-weight: $f-weight-700;

                            @media #{$media-s} {
                                font-size: 1.6rem;
                            }
                        }

                        // .label

                    }

                    // .number

                    .text {

                        max-width: 11rem;
                        font-size: 1.8rem;
                        line-height: 1.2;

                        @media #{$media-s} {
                            max-width: none;
                        }

                    }

                    // .text

                }

                // .info

            }

            // .caption

            .leaf {

                position: absolute;
                top: -10rem;
                left: -6rem;
                width: 16rem;

            }

            // .leaf

        }

        // .image

        .content {

            max-width: 80rem;

            @media #{$media-b} {
                width: 50rem;
            }

            @media #{$media-m} {
                width: auto;
                max-width: 80rem;
            }

            .title {}

            // .title

            .title+.text {
                margin-top: $gutter;
            }

            .text {}

            // .text

            .link {}

            // .link

        }

        // .content

    }

    // .home--sustainability



    .home--news {

        padding: $gutter;

        @media #{$media-sx} {
            padding: $gutter / 2;
        }

        .content {

            .title {

                max-width: 80rem;
                // font-size: 6.3rem;
                font-size: 4.3rem;
                font-weight: $f-weight-500;
                line-height: 1.1;
                text-transform: uppercase;

                @media #{$media-b} {
                    font-size: 4rem;
                }

                @media #{$media-s} {
                    font-size: 3rem;
                }

                span {
                    color: var(--c-theme-5);
                }

            }

            // .title

            .text {

                margin-top: $gutter*.5;
                max-width: 80rem;
                font-size: 1.8rem;
                line-height: 1.6;

                @media #{$media-sx} {
                    font-size: 1.6rem;
                }

            }

            // .text

        }

        // .content

        .items {

            margin-top: $gutter;
            display: grid;
            grid-gap: $gutter*2;
            grid-template-columns: repeat(3, 1fr);

            @media #{$media-b} {
                grid-gap: $gutter*2;
            }

            @media #{$media-m} {
                grid-template-columns: repeat(2, 1fr);
            }

            @media #{$media-sm} {
                grid-gap: $gutter;
            }

            @media #{$media-sx} {
                grid-template-columns: repeat(1, 1fr);
            }

            .item {

                max-width: 60rem;
                position: relative;

                &:hover,
                &:focus-within {
                    outline: .1rem solid var(--c-accent);
                    outline-offset: 0;
                }

                .link {

                    outline: none;

                    &::before {
                        content: '';
                        display: block;
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        top: 0;
                        right: 0;
                        bottom: 0;
                        left: 0;
                        z-index: 5;
                    }

                }

                // .link

                .image {

                    //height: 35rem;
                    border: .1rem solid var(--c-grey-light);

                    .img {

                        display: block;
                        width: 100%;
                        height: 100%;
                        object-fit: cover;

                    }

                    // .img

                }

                // .image

                .content {

                    padding: 2rem;

                    @media #{$media-sx} {
                        padding: 2rem 0;
                    }

                }

                // .content

                .time {

                    font-family: $f-family-title;
                    font-weight: $f-weight-500;
                    font-size: 1.4rem;
                    text-transform: uppercase;

                }

                // .time

                .title {

                    margin-top: 1rem;

                    font-size: 2.4rem;
                    line-height: 1.1;
                    font-weight: $f-weight-600;

                    @media #{$media-sx} {
                        font-size: 2rem;
                    }

                }

                // .title

                .text {

                    margin-top: 1rem;
                    font-size: 1.4rem;
                    line-height: 1.2;

                }

                // .text

            }

            // .item

        }

        // .items

        .link-blog {

            margin-top: $gutter;
            text-align: center;

            @media #{$media-sx} {
                text-align: left;
            }

            .link {

                display: inline-flex;
                align-items: center;
                position: relative;
                height: 4.5rem;
                padding-left: $gutter*.5;
                padding-right: $gutter*2;

                font-family: $f-family-title;
                font-weight: $f-weight-600;
                font-size: 1.8rem;
                line-height: 1;
                text-transform: uppercase;
                color: var(--c-white);
                background-color: var(--c-corporate-1);

                &::after {

                    display: inline-flex;
                    content: '';
                    width: 0;
                    height: 0;
                    position: absolute;
                    top: 0;
                    right: 0;
                    border-left: 4.5rem solid transparent;
                    border-right: initial;
                    border-top: initial;
                    border-bottom: 4.5rem solid var(--c-white);

                }

                &:hover,
                &:focus-within {
                    color: var(--c-corporate-1);
                    background-color: var(--c-corporate-5);
                }

            }

            // .link

        }

        // .link-blog

    }

    // .home--news



}

// .page-template-homepage