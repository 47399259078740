/*------------------------------------*\
    Header
   #header.header
\*------------------------------------*/

.header {

    position: relative;
    z-index: 10;
    background-color: transparent;

    font-family: $f-family-title;

}// .header










/*------------------------------------*\
    Header content
   .header__content
\*------------------------------------*/

.header__content {

    position: relative;
    background-color: var(--c-white);

    display: flex;
    justify-content: space-between;
    align-items: center;

    padding: $gutter;
    max-width: 190rem;
    margin: 0 auto;

    @media #{$media-sx} { padding: $gutter*.5; }



    .brand {

        width: 20rem;
        height: 7rem;

        // @media #{$media-m} { width: 6.5rem; }

        .link,
        svg {
            display: block;
            width: 100%;
            height: 100%;
        }

    }// .brand



    .language__nav {

        margin-left: auto;
        margin-right: $gutter*.5;
        font-family: $f-family-title;


        select {

            cursor: pointer;
            font-size: 1.6rem;
            line-height: 1.1;
            font-weight: $f-weight-500;
            padding: 1rem 0 1rem 1rem;
            text-transform: uppercase;
            background-color: transparent;
            border: none;

        }

    }// .language__nav

    .icon__nav {

        // width: 30rem;
        height: 8rem;
        background-color: var(--c-corporate-2);

        display: flex;
        justify-content: flex-end;
        align-items: center;

        @media #{$media-m} {
            height: 4rem;
        }

        .item {

            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            width: 8rem;
            height: 8rem;
            position: relative;

            @media #{$media-m} {
                width: 4rem;
                height: 4rem;
            }

            &:not(:first-of-type) {
                &::before {
                    content: '';
                    display: block;
                    width: .2rem;
                    height: 5rem;
                    //background-color: var(--c-corporate-1);
                    position: absolute;
                    top: 50%;
                    left: 0;
                    transform: translateY(-50%);

                    @media #{$media-m} {
                        height: 2.5rem;
                    }
                }
            }

            &.hamburger {
                background-color: var(--c-oil);

                &:hover {
                    background-color: var(--c-accent); 

                    .icon {
                        color: var(--c-black);
                    }
                }

                .icon {
                    color: var(--c-corporate-1);
                }
            }

        }// .item

        .icon {

            color: var(--c-corporate-5);

            border: none;
            padding: 0;
            background-color: transparent;
            fill: currentColor;
            position: relative;

            width: 3rem;
            height: 3rem;

            @media #{$media-m} {
                width: 2rem;
                height: 2rem;
            }

            &:hover,
            &:focus-within {
                color: var(--c-accent);
            }

            svg {
                display: block;
                width: 100%;
                height: 100%;
                fill: currentColor;
            }

        }// .item

    }// .icon__nav

    .top_arrow {
        position: fixed;
        bottom: 2%;
        right: $gutter * 2;
        display: none;

        a {
            display: flex;
            width: 40px;
            height: 40px;
            transform: rotate(180deg);
            background: var(--c-corporate-1);
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            border: 1px solid var(--c-corporate-1);
            padding: 0 0.8rem;

            svg {
                margin-bottom: -3px;
                path {
                    fill: var(--c-white);
                }
            }
        }
    }// .top_arrow

}// .header__content










/*------------------------------------*\
    Header Menu -> header_menu
    Header Navigation
    nav.header_menu
\*------------------------------------*/

.header_menu {

    margin-left: $gutter;

    @media #{$media-sm} { display: none; }

    .menu {

        display: flex;
        position: relative;

        &::before {
            display: block;
            content: '';
            width: .1rem;
            height: 3rem;
            background-color: var(--c-corporate-2);

            position: absolute;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
        }

        @media #{$media-bx} {
            display: none;
        }

        & > .menu-item {

            margin-left: $gutter * .5;
            position: relative;

            & > a {

                font-size: 1.6rem;
                line-height: 1;
                padding: 2rem .5rem;
                display: inline-flex;
                align-items: center;
                text-transform: uppercase;
                outline-offset: 0;
            }

            &:hover,
            &:focus-within {
                // color: var(--c-corporate-1);
            }

            &.current-menu-item > a,
            &.current-menu-parent > a,
            &.current-menu-ancestor > a {
                color: var(--c-corporate-1);
            }

        }// .menu-item

        .sub-menu {

            display: none;
            position: absolute;
            top: 100%;
            left: 0;
            transition: opacity 0.5s ease;

            color: var(--c-white);
            background-color: var(--c-corporate-2);

            min-width: 28rem;
            padding: 2rem;

            .menu-item {

                padding: 0.1rem;

                a {

                    &:hover,
                    &:focus-within {
                        color: var(--c-accent);
                    }

                    &.current-menu-item > a,
                    &.current-menu-parent > a,
                    &.current-menu-ancestor > a {
                        color: var(--c-corporate-1);
                    }

                }

            }

        }// .sub-menu

        .open {
            z-index: 12;
        } 

        & > .menu-item-has-children > a {

            align-items: center;
            position: relative;

            &::after {

                display: inline-flex;
                margin-top: .3rem;
                margin-left: .2rem;
                content: '';
                width: 0;
                height: 0;

                border-left: .3rem solid transparent;
                border-right: .3rem solid transparent;
                border-top: .5rem solid currentColor;
                border-bottom: initial;

            }

        }

        .menu-item-has-children:hover > .sub-menu,
        // .menu-item-has-children:focus > .sub-menu,
        // .menu-item-has-children:focus-within > .sub-menu,
        .menu-item-has-children.open > .sub-menu {
            display: block;
            opacity: 1;
        }

        .menu-item-has-children:hover > a ::after,
        .menu-item-has-children.open > a::after {
            border-top: initial;
            border-bottom: .5rem solid currentColor;
        }

        .menu-item-has-children:hover > a ::before,
        .menu-item-has-children.open > a::before {

            display: block;
            content: '';
            width: .2rem;
            height: 1rem;
            background-color: var(--c-corporate-1);

            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);

        }

    }// .menu

}// .header_menu



/*------------------------------------*\
    Header breads
   .breads
\*------------------------------------*/
.header {
    .breads {
        position: relative;
        z-index: 11;
        padding: 0 $gutter;
        font-size: 1.3rem;
        display: flex;
        align-items: center;
        gap: $gutter / 4;

        @media #{$media-sx} {
            padding: 0 $gutter / 2;
        }

        @media #{$media-uw} {
            width: $w-media-uw;
            margin: auto;
        }
    }
}





/*------------------------------------*\
    Main Navigation
   nav.main__nav
\*------------------------------------*/

.main__nav {

    color: var(--c-white);
    background-color: var(--c-corporate-1);

    position: fixed;
    z-index: 99999;
    left: 0;
    top: 0;
    width: 100%;
    height: auto;
    overflow: auto;
    padding-bottom: $gutter * 3;

    will-change: transform, opacity, visibility;
    transform: translateX(-100%);
    opacity: 0;
    visibility: hidden;
    transition: transform 0.5s ease, opacity 0.5s ease, visibility 0.5s;

    :focus { outline: .1rem dashed var(--c-accent); outline-offset: .2rem; }

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image:
            url('../images/sagoma_logo_transparent_ok.svg'),
            url('../images/sagoma_logo_transparent_ok.svg');
        background-size: 55px 78px;
        background-position: 0 0, 28px 39px;
        opacity: 0.1;
    }

    .content {

        padding: $gutter;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .buttons_right {
            display: flex;
            align-items: center;

            .language__nav {

                margin-left: auto;
                margin-right: $gutter*.5;
                font-family: $f-family-title;
                z-index: 10;
        
                select {
        
                    cursor: pointer;
                    font-size: 1.6rem;
                    line-height: 1.1;
                    font-weight: $f-weight-500;
                    padding: 1rem 0 1rem 1rem;
                    text-transform: uppercase;
                    background-color: transparent;
                    border: none;
                    color: var(--c-white);

                    option {
                        color: var(--c-black);
                    }
        
                }
        
            }// .language__nav

            .item {
                width: 8rem;
                height: 8rem;
                background-color: var(--c-white);
                display: flex;
                position: relative;
                justify-content: center;
                align-items: center;

                @media #{$media-m} {
                    width: 4rem;
                    height: 4rem;
                }

                &:hover,
                &:focus-within {
                    a {
                        fill: var(--c-oil);
                    }
                }

                a {
                    width: 3rem;
                    height: 3rem;

                    @media #{$media-m} {
                        width: 2rem;
                        height: 2rem;
                    }
                }
            }
        }

    }// .content



    .brand {

        width: 20rem;
        height: 7rem;
        color: var(--c-white);
        fill: currentColor;

        .link,
        svg {
            display: block;
            width: 100%;
            height: 100%;
            fill: currentColor;
        }

    }// .brand



    .nav_close {

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 8rem;
        height: 8rem;
        position: relative;
        order: 1;
        flex-shrink: 0;
        margin-left: auto;
        z-index: 5;
        border: none;
        background-color: var(--c-oil);

        @media #{$media-m} {
            width: 4rem;
            height: 4rem;
        }
        &:hover,
        &:focus-within {
            background-color: var(--c-accent);
            color: var(--c-white);
        }

        .icon {

            display: block;
            width: 3rem;
            height: 3rem;

            @media #{$media-m} {
                width: 2rem;
                height: 2rem;
            }

            svg {
                display: block;
                width: 100%;
                height: 100%;
                fill: currentColor;
            }

        }// .icon

        .label {

            font-family: $f-family-text;
            margin-top: 1rem;
            display: block;
            font-size: 1.4rem;
            line-height: 1;
            text-shadow: .1rem .1rem .1rem rgba(0,0,0,.25);

            @media #{$media-m} { font-size: 1.6rem; }

        }// .label

    }// nav_close



    /*------------------------------------*\
        Main Menu -> main_menu
        Main Navigation
        nav.main_menu
    \*------------------------------------*/

    .main_menu {

        position: relative;
        z-index: 2;

        max-width: 186rem;
        padding: $gutter;
        margin: auto;

        @media #{$media-sm} { 
            height: calc(100vh - 150px);
            overflow-y: auto;
        }

        .menu {

            display: flex;
            flex-wrap: wrap;
            // justify-content: space-evenly;
            gap: $gutter $gutter*2;
            // flex-direction: column;

            @media #{$media-sm} { flex-direction: column; }

            & > .menu-item {

                & > a {

                    display: inline-flex;
                    align-items: center;

                    font-size: 2.4rem;
                    font-weight: 500;
                    line-height: 1;
                    text-transform: uppercase;
                    color: var(--c-accent);

                    @media #{$media-sm} {

                        pointer-events: initial;

                        &::after {

                            display: inline-flex;
                            margin-top: .2rem;
                            margin-left: 1rem;
                            content: '';
                            width: 0;
                            height: 0;

                            border-left: .5rem solid transparent;
                            border-right: .5rem solid transparent;
                            border-top: .5rem solid currentColor;
                            border-bottom: initial;

                        }

                    }

                }

            }// & > .menu-item

            // .menu-item {
            //
            //     & > a:hover,
            //     & > a:focus-within,
            //     &.current-menu-item > a,
            //     &.current-menu-parent > a,
            //     &.current-menu-ancestor > a {
            //         color: var(--c-accent);
            //     }
            //
            //     // @at-root body.single-brand #{&}.menu-item-object-brand > a { color: var(--c-accent); }
            //     // @at-root body.single-post #{&}.menu-item-type-post_type.current_page_parent > a { color: var(--c-accent); }
            //
            // }// .menu-item
            //
            // & > .menu-item.current-menu-item > a::before,
            // & > .menu-item.current-menu-parent > a::before,
            // & > .menu-item.current-menu-ancestor > a::before { filter: invert(77%) sepia(87%) saturate(916%) hue-rotate(355deg) brightness(95%) contrast(99%); }
            //
            // // @at-root body.single-brand #{&} > .menu-item-object-brand > a::before { filter: invert(0%); }
            // // @at-root body.single-post #{&} > .menu-item-type-post_type.current_page_parent > a::before { filter: invert(0%); }

            .sub-menu {

                margin-top: 2rem;

                @media #{$media-sm} { display: none; }

                .menu-item {

                    &:not(:first-of-type) { margin-top: 1rem; }

                    a {

                        font-size: 2.1rem;
                        line-height: 1;
                        color: var(--c-corporate-5);

                    }

                }

            }// .sub-menu


            // .menu-item-has-children:hover > .sub-menu,
            // .menu-item-has-children:focus > .sub-menu,
            // .menu-item-has-children:focus-within > .sub-menu,
            .menu-item-has-children.open > .sub-menu { display: block; }

            .menu-item-has-children.open > a::after {
                margin-top: .2rem;
                border-top: initial;
                border-bottom: .5rem solid currentColor;

            }

        }// .menu

    }// .main_menu

    &.open,
    &.toggled-on {

        transform: translateX(0%);
        opacity: 1;
        visibility: visible;

    }

}// .main__nav
