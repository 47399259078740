/*------------------------------------*\
    Post Type: Progetti ( project )
    General
\*------------------------------------*/










/*------------------------------------*\
    Post Type: Progetti ( project )
    archive-project.php
    .post-type-archive-project {}
\*------------------------------------*/

.post-type-archive-project {



    .project-archive {

        position: relative;

        .box--title {

            .text {

                background-color: var(--c-white);

            }// .text

            @media #{$media-bx} { max-width: 100rem; }
            @media #{$media-b} { max-width: 80rem; }

        }// .box--title

        .bg {

            position: absolute;
            z-index: -1;
            width: 25vw;
            height: 60rem;
            top: 0;
            right: 0;

            @media #{$media-m} { display: none; }

            .image {

                width: 100%;
                height: 100%;

                .img {

                    display: block;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;

                }// .img

            }// .image

        }// .bg

        .project-family {

            padding: $gutter;

        }// .project-family

        .items {

            max-width: 140rem;
            margin: auto;
            border-top: .1rem solid var(--c-grey-normal);
            border-right: .1rem dashed var(--c-grey-normal);
            background-color: var(--c-white);

            display: grid;
            grid-template-columns: repeat(3, 1fr);

            @media #{$media-m} { grid-template-columns: repeat(2, 1fr); }
            @media #{$media-sm} { grid-template-columns: repeat(1, 1fr); }

            .item {

                padding: $gutter;
                border-left: .1rem dashed var(--c-grey-normal);
                position: relative;

                .image {

                    position: relative;
                    display: flex;

                    svg {

                        display: inline-block;

                    }// svg

                    .family-icon {

                        height: 3.6rem;
                        margin-top: 1rem;
                        margin-left: 1rem;

                    }// .family-icon

                    .family-draw {

                        order: -1;
                        width: 8.8rem;
                        height: 8.8rem;

                        fill: var(--c-grey-dark);

                    }// .family-draw

                }// .image

                .title {

                    margin-top: 2rem;
                    font-size: 2.4rem;
                    line-height: 1.1;
                    font-weight: $f-weight-600;
                    text-transform: uppercase;

                }// .title

                .projects {

                    margin-top: $gutter*.5;

                    .project {

                        .name {}// .name

                        .location {
                            text-transform: uppercase;
                        }// .location

                    }// .project

                }// .projects

            }// .item

        }// .items

        .project--map {

            padding: $gutter;

            .image {

                max-width: 120rem;
                margin: auto;

                .img {

                    display: block;
                    width: 100%;
                    height: auto;

                }// .img

            }// .image

        }// .project--map

    }// .project-archive



}// .post-type-archive-project {}
