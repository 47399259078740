/*------------------------------------*\
   Text
\*------------------------------------*/

.text {

    a {
        color: currentColor;
    }

    ul,
    ol {
        margin: 2rem;
    }

    ol {
        list-style-type: decimal;
    }

    li {
        list-style-type: disc;
    }

    p+p {
        margin-top: $gutter * .5;
    }

    strong {
        font-weight: $f-weight-700;
    }

    em {
        font-style: italic;
    }

}

// .text





/*------------------------------------*\
    Box
    .box
\*------------------------------------*/

.box {}// .box









/*------------------------------------*\
    Title ( ex-box--intro )
    .box.box--title
\*------------------------------------*/

.box--title {

    margin: auto;
    margin-left: 0;
    padding: $gutter;

    max-width: 120rem;

    @media #{$media-sx} {
        padding: $gutter / 2;
    }

    .title {

        font-size: 1.8rem;
        font-weight: $f-weight-400;

        @media #{$media-s} { font-size: 1.6rem; }

    }// .title

    .title2 {

        color: var(--c-accent);
        font-size: 5rem;
        line-height: 1.2;
        font-weight: $f-weight-700;
        text-transform: uppercase;

        @media #{$media-s} { font-size: 4rem; }
        @media #{$media-sx} {
            font-size: 3rem;
            line-height: 1.1;
        }

    }// .title2

    .title3 {

        font-family: $f-family-text;
        font-size: 1.6rem;
        line-height: 1.6;

    }// .title3

    .text {

        margin-top: $gutter*.5;
        font-size: 1.6rem;
        line-height: 1.625;

    }// .text

}// .box--title





/*------------------------------------*\
    Intro
    .box.box--intro
\*------------------------------------*/

.box--intro {

    margin: auto;
    margin-left: 0;
    padding: $gutter;

    @media #{$media-sx} {
        padding: $gutter / 2 $gutter;
    }

    .title {

        font-size: 1.8rem;
        font-weight: $f-weight-400;

        @media #{$media-s} { font-size: 1.6rem; }

    }

    // .title

    .title2 {

        color: var(--c-accent);
        font-size: 5rem;
        line-height: 1.2;
        font-weight: $f-weight-700;
        text-transform: uppercase;

        @media #{$media-s} { font-size: 4rem; }
        @media #{$media-sx} { font-size: 3rem; }

    }

    // .title2

	.title3 {}// .title3

    .text {

        font-size: 1.6rem;

    }

    // .text

}

// .box--intro


/*------------------------------------*\
    Section 1
    .box.box--section-1
\*------------------------------------*/

.box--section-1 {
    @extend .box--intro;

    padding: 0 $gutter;

}

// .box--section-1

/*------------------------------------*\
    Section 2
    .box.box--section-2
\*------------------------------------*/

.box--section-2 {
    @extend .box--intro;

    padding: 0 $gutter;

}

// .box--section-2

/*------------------------------------*\
    Section 3
    .box.box--section-3
\*------------------------------------*/

.box--section-3 {
    @extend .box--intro;

    padding: 0 $gutter;

}

// .box--section-3

/*------------------------------------*\
    Section 4
    .box.box--section-4
\*------------------------------------*/

.box--section-4 {
    @extend .box--intro;

    padding: 0 $gutter;

}

// .box--section-4

/*------------------------------------*\
    Section 5
    .box.box--section-5
\*------------------------------------*/

.box--section-5 {
    @extend .box--intro;

    padding: 0 $gutter;

}

// .box--section-5


/*------------------------------------*\
    Link richiedi informazioni
    .box.box--richiedi-info
\*------------------------------------*/

.box--richiedi-info {
    @extend .box--intro;

    display: flex;
    justify-content: center;
    margin-top: $gutter * 5;
    padding: $gutter * 2 $gutter;

    @media #{$media-u} {
        max-width: $w-media-uw;
    }

    @media #{$media-b} {
        margin-top: $gutter * 2;
    }

    @media #{$media-sx} {
        margin-top: $gutter;
    }

    a {
        background-color: var(--c-corporate-1);
        color: var(--c-white);
        text-transform: uppercase;
        padding: $gutter / 6 $gutter * 1.5 $gutter / 6 $gutter / 2;
        position: relative;
        transition: 0.15s all ease;

        &::after {
            position: absolute;
            content: '';
            background-color: var(--c-white);
            clip-path: polygon(100% 100%, 0% 100%, 100% 0);
            right: 0;
            width: 35px;
            height: 101%;
            margin-top: - $gutter / 6;
        }

        &:hover {
            background-color: var(--c-corporate-2);
        }
    }

}

// .box--richiedi-info


/*------------------------------------*\
    Box newsletter e social
    .box.box--newsletter-social
\*------------------------------------*/

.box--newsletter-social {
    @extend .box--intro;

    padding: 0 $gutter;
    margin: $gutter * 3 0;
    display: flex;

    @media #{$media-sm} {
        flex-direction: column;
        padding: 0;
        margin: $gutter * 2 0 $gutter 0;
    }

    .col-1,
    .col-2,
    .col-3 {
        width: 33.3333333%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: $gutter * 1.5 $gutter;

        @media #{$media-sm} {
            width: 100%;
            padding: $gutter;
        }
    }

    .col-1 {
        background-color: var(--c-corporate-1);

        @media #{$media-sm} {
            align-items: center;
        }

        .sopratitolo {
            color: var(--c-white);
            font-size: 2.4rem;

            @media #{$media-b} {
                font-size: 2.2rem;
            }
        }

        .titolo {
            color: var(--c-accent);
            font-size: 4.3rem;
            font-weight: 500;
            text-transform: uppercase;

            @media #{$media-bx} {
                font-size: 3.2rem;
            }

            @media #{$media-b} {
                font-size: 2.8rem;
            }
        }
    }

    .col-2 {
        background-color: var(--c-corporate-2);
        align-items: center;

        .sopratitolo {
            color: var(--c-accent);
            font-size: 2rem;
            font-weight: 500;
        }

        a {
            color: var(--c-white);
            font-size: 2rem;
            font-weight: 500;
            text-transform: uppercase;
            text-align: center;
            padding: $gutter / 6 $gutter;
            margin-top: $gutter / 4;
            background-color: var(--c-corporate-1);
            transition: 0.15s all ease;

            @media #{$media-b} {
                padding: $gutter / 6 $gutter / 2;
            }

            &:hover {
                background-color: var(--c-accent);
                color: var(--c-corporate-1);
            }
        }
    }

    .col-3 {
        background-color: var(--c-accent);
        align-items: center;

        .titolo {
            font-size: 2rem;
            font-weight: 500;
        }
    }

}

// .box--newsletter-social
