/*------------------------------------*\
    Page Template: Lavora con noi
    lavora-con-noi.php
    .page-template-lavora-con-noi
\*------------------------------------*/

.page-template-lavora-con-noi {
    #main {
        overflow-x: hidden;
        overflow-y: clip;
    }

    .box--intro {
        margin: 0 auto;
        max-width: $w-media-uw;
        padding-bottom: $gutter / 2;
    }
    
    .box--section-1 {
        display: flex;
        flex-wrap: wrap;

        @media #{$media-u} {
            max-width: $w-media-uw;
        }

        .col-1 {
            width: 100%;
            position: relative;

            &::after {
                width: 30%;
                height: 184px;
                position: absolute;
                right: 0;
                top: - $gutter * 4;
                content: '';
                background: url('../images/griglia_contatti.svg') no-repeat center center / contain;

                @media #{$media-sm} {
                    display: none;
                }
            }

            .text {
                width: 50%;
                padding: 0 $gutter * 2 0 $gutter * 4;

                @media #{$media-bx} {
                    padding-left: $gutter;
                }

                @media #{$media-b} {
                    padding-left: 0;
                    width: 70%;
                }

                @media #{$media-sm} {
                    width: 100%;
                    padding-right: 0;
                }

                @media #{$media-sx} {
                    font-size: 1.4rem;
                }
            }
        }
    }

    // .box--section-1

    .box--section-2 {
        display: flex;
        flex-wrap: wrap;
        margin-top: $gutter;
        padding-bottom: $gutter * 2;

        @media #{$media-u} {
            max-width: $w-media-uw;
        }

        @media #{$media-sx} {
            margin-top: $gutter / 3;
            padding-bottom: $gutter;
        }

        .col-1 {
            width: 50%;
            padding-left: $gutter * 2;

            @media #{$media-bx} {
                padding-left: $gutter;
            }

            @media #{$media-b} {
                padding-left: 0;
                width: 60%;
            }

            @media #{$media-m} {
                width: 100%;
            }
        }

        .col-2 {
            width: 50%;
            padding-left: $gutter * 2;

            @media #{$media-bx} {
                padding-left: $gutter;
            }

            @media #{$media-b} {
                padding-left: 0;
                width: 60%;
            }

            @media #{$media-m} {
                padding-left: 0;
                width: 100%;
                order: -1;
            }

            .elenco--posizioni {
                display: flex;
                flex-direction: column;
                margin-top: 0;

                @media #{$media-b} {
                    margin-top: 0;
                    margin-bottom: $gutter * 1.5;
                }

                .box {
                    display: flex;
                    position: relative;
                    padding: $gutter / 1.5 $gutter / 2 $gutter / 1.5 $gutter * 1.5;
                    border-bottom: 1px dotted var(--c-corporate-1);

                    @media #{$media-m} {
                        flex-direction: column;
                    }

                    @media #{$media-s} {
                        padding: $gutter / 2 0 $gutter / 2 $gutter * 1.5;
                    }

                    @media #{$media-sx} {
                        padding-left: $gutter / 1.5;
                    }

                    &::before {
                        width: 22px;
                        height: 32px;
                        position: absolute;
                        left: $gutter / 4;
                        content: '';
                        background: url('../images/simbolo_logo.svg') no-repeat center center / contain;

                        @media #{$media-sx} {
                            height: 22px;
                            left: 0;
                            top: $gutter / 1.8;
                        }
                    }

                    .content {
                        display: flex;
                        align-items: center;
                        cursor: pointer;
                        position: relative;
                        flex: 1;

                        @media #{$media-sm} {
                            flex-direction: column;
                            justify-content: center;
                            align-items: flex-start;
                        }

                        &::after {
                            width: 17px;
                            height: 14px;
                            position: absolute;
                            right: $gutter / 4;
                            content: '';
                            background: url('../images/icon-dropdown.svg') no-repeat center center / contain;
                            transition: 0.15s all ease;
                        }

                        .text {
                            font-size: 1.8rem;
                            text-transform: uppercase;
                            margin-right: $gutter / 2;
                            width: 35%;

                            @media #{$media-sm} {
                                font-size: 1.6rem;
                                width: max-content;
                            }
                        }

                        .title {
                            font-size: 1.8rem;
                            font-weight: 600;
                            color: var(--c-theme-5);
                            width: 55%;

                            @media #{$media-sm} {
                                font-size: 1.6rem;
                                width: 100%;
                            }
                        }
                    }

                    .desc {
                        margin-top: $gutter / 2;

                        a {
                            display: block;
                            text-decoration: underline;
                            margin-top: $gutter / 4;
                            font-size: 1.6rem;
                        }


                        @media #{$media-sm} {
                            font-size: 1.4rem;
                        }
                    } 

                    &[open] {
                        flex-direction: column;

                        .content {
                            &::after {
                                transform: rotate(180deg);
                            }
                        }
                    }
                }
            }
        }

        .text {
            font-size: 3rem;

            @media #{$media-s} {
                font-size: 2.2rem;
            }
        }

        .gform_required_legend {
            display: none;
        }

        .gform_footer {
            display: flex;
            justify-content: center;
            padding-bottom: $gutter !important;

            button {
                margin-top: 0;
                border: 0;
                background-color: var(--c-corporate-1);
                color: var(--c-white);
                text-transform: uppercase;
                padding: $gutter / 6 $gutter * 3 $gutter / 6 $gutter * 2;
                position: relative;
                transition: 0.15s all ease;

                &::after {
                    position: absolute;
                    content: '';
                    background-color: var(--c-white);
                    clip-path: polygon(100% 100%, 0% 100%, 100% 0);
                    right: -1px;
                    bottom: -1px;
                    width: 35px;
                    height: 101%;
                    margin-top: 0;
                }

                &:hover {
                    background-color: var(--c-corporate-2);
                }
            }
        }

        .contatti_ok {
            .image {
                display: flex;
                justify-content: center;
                margin-bottom: $gutter / 2;

                img {
                    width: $gutter * 2;
                    height: auto;
                }
            }
        }
    }

    // .box--section-2
}

// .page-template-lavora-con-noi