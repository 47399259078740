/*------------------------------------*\
    Page Template: Team
    team.php
    .page-template-team
\*------------------------------------*/

.page-template-team {

    .box--intro {
        margin: 0 auto;
        max-width: $w-media-uw;
        padding-bottom: $gutter / 2;
    }

    .box--section-1 {
        display: flex;
        flex-wrap: wrap;

        @media #{$media-u} {
            max-width: $w-media-uw;
        }

        .col-1 {
            width: 100%;
            padding: 0 0 $gutter $gutter * 3;
            display: flex;
            order: -1;

            @media #{$media-m} {
                width: 100%;
                padding: 0 0 $gutter 0;
            }

            @media #{$media-sx} {
                padding-bottom: $gutter / 2;
            }

            p {
                width: 50%;
                font-size: 1.8rem;

                @media #{$media-b} {
                    width: 100%;
                }

                @media #{$media-sx} {
                    font-size: 1.6rem;
                }
            }
        }

        //.col-1

        .col-2 {
            width: 25%;
            display: flex;
            align-items: flex-end;

            @media #{$media-bx} {
                width: 30%;
            }

            @media #{$media-sm} {
                width: 100%;
                order: 2;
            }

            .box_container {
                a {
                    display: flex;
                    align-items: center;

                    .title {
                        font-size: 4.3rem;
                        font-weight: 500;
                        color: var(--c-corporate-1);
                        text-transform: uppercase;
                        line-height: 1;
                        padding-left: $gutter / 3;
                        display: flex;
                        flex-direction: column;

                        @media #{$media-bx} {
                            font-size: 3.5rem;
                        }

                        @media #{$media-m} {
                            font-size: 2.6rem;
                        }

                        @media #{$media-sm} {
                            font-size: 3.2rem;
                        }

                        @media #{$media-s} {
                            font-size: 2.6rem;
                        }

                        span {
                            color: var(--c-theme-5);
                        }
                    }

                    .arrow_down {
                        width: 90px;
                        height: 90px;
                        border: 1px solid var(--c-corporate-3);
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        flex: none;
                        transition: 0.25s all ease;

                        @media #{$media-m} {
                            width: 70px;
                            height: 70px;
                        }

                        @media #{$media-sm} {
                            width: 80px;
                            height: 80px;
                        }

                        @media #{$media-s} {
                            width: 60px;
                            height: 60px;
                        }

                        img {
                            width: 100%;
                            height: 90%;
                            transition: 0.15s all ease-out;
                        }
                    }

                    &:hover {
                        .arrow_down {
                            background-color: var(--c-accent);
                            border: 1px solid var(--c-corporate-1);
                            
                            img {
                                transform: scale(0.6) translate(0 15px);
                            }
                        }
                    }
                }


            }
        }

        //.col-2

        .col-3 {
            width: 75%;

            @media #{$media-bx} {
                width: 70%;
            }

            @media #{$media-sm} {
                width: 100%;
                order: 1;
                margin-bottom: $gutter;
            }

            .image {
                width: fit-content;
                padding: 0 $gutter * 1.5;
                background: linear-gradient(to bottom,
                        transparent 0%,
                        transparent 15%,
                        var(--c-corporate-5) 15%,
                        var(--c-corporate-5) 85%,
                        transparent 85%,
                        transparent 100%);

                @media #{$media-s} {
                    padding: 0
                }
            }
        }

        //.col-3

    }

    // .box--section-1

    .box--team {
        display: flex;
        flex-wrap: wrap;
        margin-top: $gutter * 3;

        @media #{$media-u} {
            max-width: $w-media-uw;
        }

        @media #{$media-m} {
            margin-top: $gutter * 2;
        }

        @media #{$media-sm} {
            margin-top: 0;
        }

        .col-1 {
            width: 50%;
            padding: $gutter 0 $gutter $gutter;

            @media #{$media-m} {
                width: 60%;
            }

            @media #{$media-sm} {
                width: 100%;
                padding: $gutter;
            }

            .elenco_team {
                display: flex;
                flex-wrap: wrap;
                gap: 10px;

                @media #{$media-sm} {
                    display: none;
                }

                li {
                    font-size: 1.8rem;
                    width: 30%;
                    padding: $gutter / 4 0 $gutter / 4 $gutter * 1.5;
                    list-style-type: none;
                    transition: 0.15s all ease;
                    cursor: pointer;
                    position: relative;
                    display: flex;
                    align-items: center;

                    @media #{$media-bx} {
                        width: 32%;
                        padding: $gutter / 4 0 $gutter / 4 $gutter;
                    }

                    @media #{$media-b} {
                        width: 46%;
                        padding: $gutter / 4 0 $gutter / 4 $gutter * 1.5;
                    }

                    @media #{$media-m} {
                        font-size: 1.6rem;
                    }

                    &::before {
                        content: '';
                        position: absolute;
                        left: $gutter / 2;
                        width: 20px;
                        height: 100%;
                        background: url('../images/simbolo_logo_white.svg') no-repeat center center / contain;

                        @media #{$media-bx} {
                            left: $gutter / 4;
                        }

                        @media #{$media-b} {
                            left: $gutter / 2;
                        }
                    }

                    &:hover,
                    &.selected {
                        background-color: var(--c-corporate-1);
                        color: var(--c-accent);
                    }

                    &.selected {
                        cursor: default;
                    }
                }
            }

            .elenco_team_select {
                display: none;

                @media #{$media-sm} {
                    display: flex;
                }
            }
        }

        .col-2 {
            width: 50%;
            display: flex;

            @media #{$media-m} {
                width: 40%;
            }

            @media #{$media-m} {
                padding: 0 $gutter / 2;
            }

            @media #{$media-sm} {
                width: 100%;
                padding: 0 $gutter;
            }

            .images {
                display: flex;
                justify-content: center;
                position: relative;
                height: 695px;
                width: 100%;

                @media #{$media-s} {
                    flex-direction: column;
                    height: auto;
                }

                figure {
                    position: absolute;
                    opacity: 0;
                    transition: 0.15s all ease;

                    @media #{$media-s} {
                        position: relative;
                        display: none;
                    }

                    .smaller {
                        -webkit-clip-path: polygon(18% 0, 100% 0, 100% 85%, 80% 100%, 0 100%, 0 12%);
                        clip-path: polygon(18% 0, 100% 0, 100% 85%, 80% 100%, 0 100%, 0 12%);
                    }

                    .bigger_top_left {
                        position: absolute;
                        left: 0;
                        z-index: -1;
                        -webkit-clip-path: polygon(0 0, 0 12%, 18% 0);
                        clip-path: polygon(0 0, 0 12%, 18% 0);
                        transition: 0.40s all ease-out 0.25s;
                    }

                    .bigger_bottom_right {
                        position: absolute;
                        left: 0;
                        z-index: -1;
                        -webkit-clip-path: polygon(80% 100%, 100% 100%, 100% 85%);
                        clip-path: polygon(80% 100%, 100% 100%, 100% 85%);
                        transition: 0.40s all ease-out 0.25s;
                    }

                    &.visible {
                        opacity: 1;
                        display: flex;

                        .bigger_top_left {
                            transform: translate(-7px, -30px) rotate(-6deg);
                        }

                        .bigger_bottom_right {
                            transform: translate(12px, 30px) rotate(4deg);
                        }
                    }


                }
            }
        }
    }

    // .box--team
}

// .page-template-team