/*------------------------------------*\
    Page Template: Chisiamo
    chisiamo.php
    .page-template-chisiamo
\*------------------------------------*/

.page-template-chisiamo {

    .box--intro {
        margin: 0 auto;
        max-width: $w-media-uw;
        padding-bottom: $gutter / 2;
    }

    .box--section-1 {
        display: flex;
        flex-wrap: wrap;

        @media #{$media-u} {
            max-width: $w-media-uw;
        }

        .col-1 {
            width: 100%;
            padding: 0 0 $gutter $gutter * 3;

            @media #{$media-m} {
                width: 100%;
                padding: 0 0 $gutter 0;
            }

            @media #{$media-sx} {
                padding-bottom: $gutter / 2;
            }

            p {
                width: 50%;
                font-size: 1.8rem;

                @media #{$media-b} {
                    width: 100%;
                }

                @media #{$media-sx} {
                    font-size: 1.6rem;
                }
            }
        }

        .col-2 {
            width: 24%;
            padding: $gutter 0 0 $gutter;

            @media #{$media-m} {
                width: 100%;
                padding: 0 0 $gutter 0;
            }

            @media #{$media-sx} {
                padding-bottom: $gutter / 2;
            }

            p {
                font-size: 2.6rem;
                line-height: 1.4;

                @media #{$media-sx} {
                    font-size: 2rem;
                }
            }
        }

        .col-3 {
            width: 26%;
            display: flex;
            position: relative;
            overflow: hidden;

            @media #{$media-m} {
                width: 35%;
            }

            @media #{$media-sx} {
                width: 100%;
            }

            svg {
                position: absolute;
                left: -20rem;
            }
        }

        .col-4 {
            width: 50%;
            display: flex;
            position: relative;
            overflow: hidden;
            padding-left: 1rem;

            @media #{$media-m} {
                width: 65%;
            }

            @media #{$media-sx} {
                width: 100%;
                padding-left: 0;
                padding-top: 1rem;
            }

            svg {
                position: absolute;
                bottom: -2px;
                right: 3rem;

                @media #{$media-sx} {
                    display: none;
                }
            }
        }
    }

    // .box--section-1

    .box--section-2 {
        display: flex;
        flex-wrap: wrap;
        margin-top: $gutter * 1.5;

        @media #{$media-u} {
            max-width: $w-media-uw;
        }

        @media #{$media-m} {
            margin-top: $gutter;
        }

        @media #{$media-sx} {
            margin-top: $gutter / 2;
        }

        .col-1 {
            width: 50%;
            padding-right: $gutter * 4;
            padding-left: $gutter;

            @media #{$media-bx} {
                padding-right: $gutter;
            }

            @media #{$media-m} {
                width: 100%;
                padding: 0;
            }

            p { font-size: 1.6rem; }
        }

        .col-2 {
            width: 50%;
            position: relative;

            @media #{$media-m} {
                width: 100%;
                margin-top: $gutter;
            }

            a {
                display: flex;
                justify-content: center;
                align-items: center;

                svg {
                    position: absolute;

                    @media #{$media-sx} {
                        max-width: 20%;
                    }
                }
            }

        }
    }

    // .box--section-2

    .box--section-3 {
        display: flex;
        flex-wrap: wrap;
        margin-top: $gutter * 1.5;

        @media #{$media-u} {
            max-width: $w-media-uw;
        }

        @media #{$media-m} {
            margin-top: $gutter;
        }

        @media #{$media-sx} {
            margin-top: $gutter / 2;
        }

        .col-1 {
            width: 60%;

            @media #{$media-m} {
                width: 100%;
                order: 2;
            }
        }

        .col-2 {
            width: 40%;

            @media #{$media-m} {
                width: 100%;
                display: flex;

                @media #{$media-sm} {
                    flex-direction: column;
                }

                .content {
                    width: 50%;
                    padding-right: $gutter;

                    @media #{$media-sm} {
                        width: 100%;
                        padding-right: 0;
                    }
                }

            }

            h4 {
                font-size: 2.6rem;
                font-weight: 700;
                color: var(--c-theme-4);
                text-transform: uppercase;
                margin-bottom: $gutter / 2;

                @media #{$media-sx} {
                    font-size: 2.2rem;
                }
            }

            .text {
                margin-bottom: $gutter * 1.5;

                @media #{$media-sm} {
                    margin-bottom: $gutter;
                }

                @media #{$media-sx} {
                    font-size: 1.6rem;
                }
            }
        }
    }

    // .box--section-3

    .box--section-4 {
        display: flex;
        flex-wrap: wrap;
        margin-top: $gutter * 1.5;

        @media #{$media-u} {
            max-width: $w-media-uw;
        }

        @media #{$media-m} {
            margin-top: $gutter;
        }

        @media #{$media-sx} {
            margin-top: $gutter / 2;
        }

        .col-1 {
            width: 50%;
            padding-right: $gutter * 4;
            padding-left: $gutter;

            @media #{$media-bx} {
                padding-right: $gutter;
            }

            @media #{$media-m} {
                width: 100%;
                padding: 0;
            }

            h4 {
                font-size: 2.6rem;
                font-weight: 700;
                color: var(--c-theme-4);
                text-transform: uppercase;
                margin-bottom: $gutter / 2;

                @media #{$media-sx} {
                    font-size: 2.2rem;
                }
            }

            .text {
                margin-bottom: $gutter * 1.5;

                @media #{$media-m} {
                    margin-bottom: 0;
                }

                @media #{$media-sx} {
                    font-size: 1.6rem;
                }
            }
        }

        .col-2 {
            width: 50%;

            @media #{$media-m} {
                width: 100%;
                margin-top: $gutter;
            }
        }
    }

    // .box--section-4

    .box--section-5 {
        display: flex;
        flex-wrap: wrap;
        margin-top: $gutter * 1.5;
        margin-bottom: $gutter * 3;

        @media #{$media-u} {
            max-width: $w-media-uw;
        }

        @media #{$media-sx} {
            margin-top: $gutter / 2;
            margin-bottom: 0;
        }

        .col-1 {
            width: 30%;
            padding-left: $gutter;
            overflow: visible;
            position: relative;

            @media #{$media-b} {
                padding-left: 0;
            }

            @media #{$media-m} {
                width: 50%;
            }

            @media #{$media-sm} {
                width: 100%;
            }

            h4 {
                //font-size: 4.3rem;
                font-size: 3.6rem;
                font-weight: 700;
                color: var(--c-theme-4);
                text-transform: uppercase;
                margin-bottom: $gutter * 3;
                position: relative;
                z-index: 2;

                @media #{$media-m} {
                    margin-bottom: $gutter;
                }

                @media #{$media-sx} {
                    font-size: 3rem;
                }
            }

            .table-index {
                position: absolute;

                @media #{$media-m} {
                    position: relative;
                }

                .box-menu {
                    display: flex;
                    flex-wrap: wrap;
                    transition: 0.15s all ease;
                    cursor: pointer;

                    &:hover {
                        .text {
                            background-color: var(--c-corporate-2);
                        }
                    }

                    .title {
                        width: 10%;
                        font-size: 2.6rem;
                        background-color: var(--c-accent);
                        color: var(--c-white);
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }

                    .text {
                        width: 90%;
                        font-size: 1.4rem;
                        background-color: var(--c-corporate-1);
                        color: var(--c-white);
                        text-transform: uppercase;
                        display: flex;
                        align-items: center;
                        padding: $gutter / 3 $gutter / 2;

                        &.desc {
                            width: 100%;
                            max-height: 0;
                            overflow: hidden;
                            margin-bottom: 2px;
                            padding: 0;
                            text-transform: none;
                            transition: 0.15s all ease;

                            &.selected {
                                max-height: none;
                                padding: $gutter / 2 0;
                                background-color: var(--c-white);
                                color: var(--c-corporate-1);
                            }
                        }
                    }
                }
            }
        }

        .col-2 {
            width: 70%;
            position: relative;

            @media #{$media-m} {
                width: 100%;
                margin-top: $gutter;
            }

            @media #{$media-s} {
                margin-top: 0;
            }

            svg {
                position: absolute;
                right: 0;
                pointer-events: none;
                mix-blend-mode: darken;
                height: 130%;

                @media #{$media-m} {
                    height: 100%;
                }

                @media #{$media-sx} {
                    display: none;
                }
            }

            .table-pointers {
                width: 100%;
                height: 100%;
                position: absolute;
                z-index: 2;

                .single-pointer {
                    position: absolute;
                    display: flex;
                    justify-content: center;

                    .title {
                        background-color: var(--c-corporate-1);
                        color: var(--c-accent);
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 36px;
                        height: 36px;
                        border-radius: 50%;
                        font-size: 2.2rem;
                        transition: 0.15s all ease;

                        @media #{$media-s} {
                            width: 24px;
                            height: 25px;
                            font-size: 1.6rem;
                        }

                        &.selected {
                            background-color: var(--c-accent);
                            transform: scale(1.2);
                            color: var(--c-corporate-1);
                        }
                    }

                    p {
                        opacity: 0;
                        overflow: hidden;
                        padding: $gutter / 3 $gutter / 2;
                        background-color: var(--c-white);
                        border: 1px solid var(--c-corporate-1);
                        position: absolute;
                        bottom: 4rem;
                        width: 50vw;
                        max-width: $gutter * 9;
                        border-radius: 10px;
                        flex-direction: column;
                        transition: 0.15s all ease;
                        font-size: 1.2rem;
                        z-index: -1;

                        &.selected {
                            display: flex;
                            opacity: 1;
                            z-index: 3;
                        }

                        span {
                            text-transform: uppercase;
                            font-size: 1.3rem;
                            margin-bottom: $gutter / 4;
                        }
                    }
                }
            }
        }
    }

    // .box--section-5

}

// .page-template-chisiamo