/*------------------------------------*\
    Page Template: Processo
    processo.php
    .page-template-processo
\*------------------------------------*/

.page-template-processo {

    #main {
        overflow-x: hidden;
        overflow-y: clip;
    }

    .box--intro {
        max-width: $w-media-uw;
        margin: 0 auto;
    }

    .box--section-1 {
        display: flex;
        margin: 0 auto;
        padding-bottom: $gutter * 4;

        @media #{$media-u} {
            max-width: $w-media-uw;
        }

        @media #{$media-m} {
            flex-wrap: wrap;
            padding-bottom: 0;
        }

        .col-1 {
            width: auto;

            .icona {
                border: 1px solid var(--c-grey-light);
                padding: $gutter / 4;
            }

            @media #{$media-m} {
                width: 10%;
            }

            @media #{$media-s} {
                width: auto;
            }
        }

        .col-2 {
            width: 32%;
            padding: 0 $gutter 0 $gutter * 1.5;

            picture {
                top: $gutter;
                right: - $gutter * 3;
                z-index: 1;
                position: relative;

                @media #{$media-b} {
                    right: 0;
                }

                @media #{$media-m} {
                    top: 0;
                }
            }

            @media #{$media-b} {
                width: 42%;
            }

            @media #{$media-m} {
                width: 90%;
                padding: 0 0 0 $gutter / 2;
                display: flex;
                justify-content: space-between;

                .content {
                    width: 70%;
                    padding-right: $gutter / 2;
                    padding-bottom: $gutter;
                }
            }

            @media #{$media-s} {
                width: 100%;
                padding: 0;
                flex-direction: column;

                .content {
                    width: 100%;
                }

                picture {
                    margin-bottom: $gutter / 2;
                }
            }
        }

        .col-3 {
            width: 60%;

            .content {
                padding: 0 $gutter / 4 0 $gutter * 3;

                @media #{$media-m} {
                    margin-top: $gutter / 2;
                }

                h4 {
                    font-size: 4.3rem;
                    font-weight: 700;
                    color: var(--c-theme-4);

                    @media #{$media-s} {
                        font-size: 3rem;
                    }
                }

                .elenco--loghi {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    flex-wrap: wrap;
                    margin-top: $gutter;

                    img {
                        margin: 0 $gutter / 2 $gutter / 2 0;
                        max-width: 33%;

                        @media #{$media-s} {
                            max-width: 44%;
                        }
                    }
                }

                @media #{$media-b} {
                    padding-left: 0;
                }
            }

            @media #{$media-b} {
                width: 50%;
            }

            @media #{$media-m} {
                width: 100%;
            }
        }

    }

    // .box--section-1

}

// .page-template-processo