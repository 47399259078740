/*------------------------------------*\
    Page Template: Contatti
    contatti.php
    .page-template-contatti
\*------------------------------------*/

.page-template-contatti {

    #main {
        overflow-x: hidden;
        overflow-y: clip;
    }

    .box--intro {
        margin: 0 auto;
        max-width: $w-media-uw;
        padding-bottom: $gutter / 2;
    }

    .box--section-1 {
        display: flex;
        flex-wrap: wrap;

        @media #{$media-u} {
            max-width: $w-media-uw;
        }

        .col-1 {
            width: 100%;
            order: -1;
            position: relative;

            &::after {
                width: 30%;
                height: 184px;
                position: absolute;
                right: 0;
                top: - $gutter * 4;
                content: '';
                background: url('../images/griglia_contatti.svg') no-repeat center center / contain;

                @media #{$media-sm} {
                    display: none;
                }
            }

            .text {
                width: 50%;
                padding: 0 $gutter * 2 0 $gutter * 4;

                @media #{$media-bx} {
                    padding-left: $gutter;
                }

                @media #{$media-b} {
                    padding-left: 0;
                    width: 70%;
                }

                @media #{$media-sm} {
                    width: 100%;
                    padding-right: 0;
                }

                @media #{$media-sx} {
                    font-size: 1.4rem;
                }
            }
        }

        .col-2 {
            width: 40%;
            padding: $gutter $gutter * 2 0 $gutter * 4;

            @media #{$media-bx} {
                padding-left: $gutter;
            }

            @media #{$media-b} {
                width: 50%;
                padding-left: 0;
            }

            @media #{$media-m} {
                order: 2;
                width: 100%;
                padding-right: 0;
            }

            .title {
                font-size: 2.6rem;
                font-weight: 700;
                color: var(--c-theme-4);
                text-transform: uppercase;

                @media #{$media-bx} {
                    font-size: 2.4rem;
                }

                @media #{$media-sx} {
                    font-size: 1.8rem;
                }
            }

            .box_data {
                margin: $gutter / 3 0;
                display: flex;

                .image {
                    width: 10%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-right: $gutter / 3;

                    @media #{$media-sx} {
                        width: 15%;
                    }

                    .orari {
                        width: 30px;
                    }

                    .phone {
                        width: 20px;
                    }

                    .email {
                        width: 28px;
                        display: flex;
                    }

                    .indirizzo {
                        width: 16px;
                        display: flex;
                    }
                }

                &.orari {
                    margin: $gutter / 2 0 0 0;

                    @media #{$media-sx} {
                        margin-bottom: 0;
                    }

                    .image {
                        align-items: flex-start;
                    }
                }

                &.indirizzo {
                    .image {
                        align-items: flex-start;
                    }
                }

                .content {

                    @media #{$media-sx} {
                        width: 85%;
                    }

                    .text {
                        font-size: 1.6rem;
        
                        @media #{$media-sx} {
                            font-size: 1.4rem;
                        }

                        ul {
                            margin-top: 0;

                            @media #{$media-sx} {
                                margin-bottom: 0;
                            }
                        }
                    }

                    .titolo {
                        font-size: 2.2rem;
                        line-height: 1;
                        margin-bottom: $gutter / 4;
        
                        @media #{$media-sx} {
                            font-size: 1.8rem;
                        }

                        @media #{$media-sxx} {
                            font-size: 1.6rem;
                        }
                    }

                    .indirizzo {
                        a {
                            display: flex;
                            align-items: center;
                            text-transform: uppercase;
                            font-size: 1.6rem;
                            font-weight: 300;
                            margin-top: $gutter / 4;

                            .icon_external {
                                width: 14px;
                                display: flex;
                                margin-right: $gutter / 3;
                            }
                        }

                    }
                }
            }
        }

        .col-3 {
            width: 60%;
            padding-top: $gutter;

            @media #{$media-b} {
                width: 50%;
            }

            @media #{$media-m} {
                width: 100%;
                order: 0;
                margin-top: $gutter;
                padding-top: 0;
            }

            @media #{$media-sx} {
                margin-top: $gutter / 2;
            }
        }
    }

    // .box--section-1

    .box--section-2 {
        display: flex;
        flex-wrap: wrap;

        @media #{$media-u} {
            max-width: $w-media-uw;
        }

        .col-1 {
            width: 50%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            padding: 0 $gutter * 2 0 $gutter * 4;

            @media #{$media-bx} {
                padding-left: $gutter;
            }

            @media #{$media-b} {
                padding-left: 0;
                padding-top: $gutter;
            }

            @media #{$media-m} {
                width: 100%;
                padding-right: 0;
            }

            @media #{$media-sx} {
                padding-top: 0;
            }

            .title {
                font-size: 2.6rem;
                font-weight: 700;
                color: var(--c-theme-4);
                text-transform: uppercase;

                @media #{$media-bx} {
                    font-size: 2.4rem;
                }

                @media #{$media-sx} {
                    font-size: 1.8rem;
                }
            }

            .box_data {
                margin: $gutter / 3 0;
                display: flex;

                .image {
                    width: 10%;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    @media #{$media-sx} {
                        width: 15%;
                    }

                    .orari {
                        width: 30px;
                    }

                    .phone {
                        width: 20px;
                    }

                    .email {
                        width: 28px;
                        display: flex;
                    }

                    .indirizzo {
                        width: 16px;
                        display: flex;
                    }
                }

                &.orari {
                    margin: $gutter / 2 0 $gutter 0;

                    @media #{$media-sx} {
                        margin-bottom: 0;
                    }

                    .image {
                        align-items: flex-start;
                    }
                }

                &.indirizzo {
                    .image {
                        align-items: flex-start;
                    }
                }

                .content {

                    @media #{$media-sx} {
                        width: 85%;
                    }

                    .text {
                        font-size: 1.6rem;
        
                        @media #{$media-sx} {
                            font-size: 1.4rem;
                        }

                        ul {
                            margin-top: 0;

                            @media #{$media-sx} {
                                margin-bottom: 0;
                            }
                        }
                    }

                    .titolo {
                        font-size: 2.2rem;
                        line-height: 1;
                        margin-bottom: $gutter / 4;
        
                        @media #{$media-sx} {
                            font-size: 1.8rem;
                        }

                        @media #{$media-sxx} {
                            font-size: 1.6rem;
                        }
                    }

                    .indirizzo {
                        a {
                            display: flex;
                            align-items: center;
                            text-transform: uppercase;
                            font-size: 1.6rem;
                            font-weight: 300;
                            margin-top: $gutter / 4;

                            .icon_external {
                                width: 14px;
                                display: flex;
                                margin-right: $gutter / 3;
                            }
                        }

                    }
                }
            }

            .whistleblowing {
                .title {
                    display: block;
                    text-transform: none;
                    text-decoration: underline;
                    font-size: 2.4rem;
                    font-weight: 500;
                    color: var(--c-corporate-1);
                    margin-top: $gutter;

                    @media #{$media-sx} {
                        font-size: 2rem;
                    }
                }
            }

            .gallery_container {
                display: flex;
                flex-direction: column;
                max-width: 510px;
                align-self: flex-end;
                margin-top: $gutter;

                @media #{$media-b} {
                    width: 100%;
                    align-self: flex-start;
                }

                .text {
                    font-size: 3rem;

                    @media #{$media-sx} {
                        font-size: 2.4rem;
                        margin-bottom: $gutter / 6;
                    }
                }

                .gallery--single {
                    .slick-dots { 
                        position: absolute;
                        top: - $gutter;
                        right: 0;

                        @media #{$media-sx} {
                           button {
                                padding: 0 $gutter / 4;
                                margin: 0 $gutter / 6;
                           }
                        }
                    }
                }
            }
        }

        .col-2 {
            width: 50%;
            background-color: var(--c-grey-light);
            padding: $gutter / 2 $gutter * 3;

            @media #{$media-bx} {
                padding: $gutter / 2 $gutter * 2;
            }

            @media #{$media-b} {
                padding: $gutter / 2 $gutter;
            }

            @media #{$media-m} {
                width: 100%;
                padding: $gutter / 2 $gutter * 3;
                margin-top: $gutter;
            }

            @media #{$media-sm} {
                padding: $gutter / 2 $gutter;
            }

            @media #{$media-sx} {
                padding: $gutter / 2 $gutter / 2;
            }

            .title {
                display: flex;
                justify-content: center;
                color: var(--c-theme-4);
                font-size: 4.3rem;
                font-weight: 700;

                @media #{$media-bx} {
                    font-size: 3.6rem;
                }

                @media #{$media-sm} {
                    font-size: 3rem;
                }

                @media #{$media-sx} {
                    font-size: 2.2rem;
                }
            }

            textarea {
                max-height: $gutter * 4;
            }

            input, textarea, select {
                border: 1px solid var(--c-grey-dark);
            }

            .gform_required_legend {
                display: none;
            }

            .gform_wrapper .gfield {
                margin-top: $gutter / 4;
            }

            .ginput_counter {
                display: none;
            }

            .gfield_consent_label {
                font-size: 1.2rem;
            }

            .gform_footer {
                display: flex;
                justify-content: center;
                padding-bottom: $gutter !important;

                button {
                    margin-top: 0;
                    border: 0;
                    background-color: var(--c-corporate-1);
                    color: var(--c-white);
                    text-transform: uppercase;
                    padding: $gutter / 6 $gutter * 3 $gutter / 6 $gutter * 2;
                    position: relative;
                    transition: 0.15s all ease;

                    &::after {
                        position: absolute;
                        content: '';
                        background-color: var(--c-grey-light);
                        clip-path: polygon(100% 100%, 0% 100%, 100% 0);
                        right: -1px;
                        width: 35px;
                        height: 101%;
                        margin-top: 0;
                    }

                    &:hover {
                        background-color: var(--c-corporate-2);
                    }
                }
            }

            .contatti_ok {
                display: flex;
                flex-direction: column;
                margin-top: $gutter;

                .image {
                    display: flex;
                    justify-content: center;
                    margin-bottom: $gutter / 2;

                    img {
                        width: $gutter * 2;
                        height: auto;
                    }
                }

                p {
                    padding: 0 $gutter;

                    @media #{$media-m} {
                        padding: 0;
                        font-size: 1.6rem;
                    }

                    @media #{$media-sx} {
                        font-size: 1.4rem;
                    }
                }
            }
        }
    }

    // .box--section-2

    .box--section-3 {
        display: flex;
        flex-wrap: wrap;
        margin-top: $gutter * 2;

        @media #{$media-u} {
            max-width: $w-media-uw;
        }

        @media #{$media-m} {
            margin-top: $gutter;
        }

        @media #{$media-sx} {
            margin-top: $gutter / 2;
        }

        .col-1 {
            width: 100%;

            .title {
                color: var(--c-theme-4);
                font-size: 2.6rem;
                font-weight: 700;
                text-transform: uppercase;
                padding-left: $gutter * 4;
                margin-bottom: $gutter;

                @media #{$media-bx} {
                    padding-left: 0;
                }

                @media #{$media-sx} {
                    font-size: 2.2rem;
                }
            }

            .acf-map {
                height: 710px;

                @media #{$media-sm} {
                    height: 350px;
                }
            }
        }
    }

    // .box--section-3
}

// .page-template-contatti