/*------------------------------------*\
    Page Template: Download Prodotti
    download-products.php
    .page-template-download-products
\*------------------------------------*/

.page-template-download-products {

    .box--intro {
        margin: 0 auto;
        max-width: $w-media-uw;
        padding-bottom: $gutter / 2;
    }

    .box--section-1 {
        display: flex;
        flex-wrap: wrap;

        @media #{$media-u} {
            max-width: $w-media-uw;
        }

        .col-1 {
            width: 100%;
            padding: 0 0 $gutter $gutter * 3;
            display: flex;
            position: relative;

            @media #{$media-m} {
                width: 100%;
                padding: 0 0 $gutter 0;
            }

            @media #{$media-sx} {
                padding-bottom: $gutter / 2;
            }

            &::after {
                width: 30%;
                height: 184px;
                position: absolute;
                right: 0;
                top: - $gutter * 3;
                content: '';
                background: url('../images/griglia_contatti.svg') no-repeat center center / contain;

                @media #{$media-sm} {
                    display: none;
                }
            }

            p {
                width: 50%;
                padding: 0 $gutter * 4 0 0;

                @media #{$media-bx} {
                    padding-left: $gutter;
                }

                @media #{$media-b} {
                    padding-left: 0;
                    width: 70%;
                }

                @media #{$media-sm} {
                    width: 100%;
                    padding-right: 0;
                }

                @media #{$media-sx} {
                    font-size: 1.4rem;
                }
            }
        }

        //.col-1

    }

    // .box--section-1

    .box--section-3 {
        display: flex;
        flex-wrap: wrap;
        position: relative;
        margin-top: $gutter;

        .col-1 {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 100%;

            .downloadTable {
                width: 100%;
                font-family: $f-family-title;
                display: flex;
                flex-wrap: wrap;
                flex-direction: column;
                overflow-x: auto;

                .thead {
                    width: 100%;
                    display: flex;
                    flex-wrap: wrap;
                    gap: 50px;
                    margin-bottom: $gutter / 2;

                    @media #{$media-sm} {
                        display: none;
                    }

                    .thBlock {
                        background-color: var(--c-corporate-5);
                        display: flex;

                        &.last {
                            @media #{"(max-width: 1869px)"} {
                                display: none;
                            }
                        }

                        &.second {
                            @media #{"(max-width: 1270px)"} {
                                display: none;
                            }
                        }

                        div {
                            border-left: .1rem solid var(--c-grey-dark);
                            border-top: .1rem solid var(--c-grey-dark);
                            border-bottom: .1rem solid var(--c-grey-dark);
                            vertical-align: middle;
                            font-size: 1.6rem;
                            font-weight: 700;
                            line-height: 1;
                            color: var(--c-theme-4);
                            padding: $gutter / 4;
                            flex: 1;
                            display: flex;
                            align-items: center;
                            justify-content: center;

                            &.thFamiglia {
                                width: 170px;
                                flex: none;
                            }

                            &.thNFile {
                                width: 140px;
                                flex: none;
                            }

                            &.thAllegati {
                                width: 170px;
                                flex: none;
                            }

                            &.thScheda {
                                width: 90px;
                                flex: none;
                            }

                            &.lastTh {
                                border-right: .1rem solid var(--c-grey-dark);
                            }

                            &.emptyTh {
                                width: 50px;
                                flex: none;
                                background-color: var(--c-white);
                                border-top: .1rem solid var(--c-white);
                                border-bottom: .1rem solid var(--c-white);
                            }
                        }
                    }
                }

                .tbody {
                    display: flex;
                    flex-wrap: wrap;
                    width: 100%;
                    gap: 20px 50px;

                    .downloadElement {
                        display: flex;

                        @media #{$media-sm} {
                            flex-direction: column;
                            width: 100%;
                        }

                        &.gray1 {
                            background-color: #F7F8FA;
                        }

                        &.gray2 {
                            background-color: #DEDEDE;
                        }

                        div {
                            padding: $gutter / 4;
                            min-height: 55px;
                            display: flex;
                            align-items: center;
                            text-align: left;
                            font-size: 1.4rem;
                            flex: 1;

                            @media #{$media-sm} {
                                width: 100% !important;
                                justify-content: space-between !important;
                                position: relative;
                                text-align: right !important;

                                &::before {
                                    content: attr(title) ' ' attr(data-unit);
                                    font-size: 1.4rem;
                                }

                                &.hideMobile {
                                    display: none !important;
                                }
                            }

                            @media #{$media-sx} {
                                font-size: 1.4rem;
                            }


                            &.tdFamiglia {
                                width: 170px;
                                flex: none;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                text-align: center;
                                word-break: break-word;
                            }

                            &.tdNFile {
                                width: 140px;
                                flex: none;
                            }

                            &.tdAllegati {
                                width: 170px;
                                flex: none;

                                a {
                                    display: flex;
                                    align-items: center;
                                    gap: 5px;
                                }
                            }

                            &.tdScheda {
                                width: 90px;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                flex: none;

                                a {
                                    display: inherit;
                                }
                            }

                            &.emptyTd {
                                width: 50px;
                                flex: none;
                            }
                        }
                    }

                }
            }
        }
    }

    // .box--section-3 


}

// .page-template-download-products