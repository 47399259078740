/*------------------------------------*\
    Page Template: Legal
    legal.php
    .page-template-legal
\*------------------------------------*/

.page-template-legal {

    #main {
        overflow-x: hidden;
        overflow-y: clip;
    }

    .box--intro {
        margin: 0 auto;
        max-width: $w-media-uw;
        padding-bottom: $gutter / 2;
    }

    .box--section-1 {
        display: flex;
        flex-wrap: wrap;

        @media #{$media-u} {
            max-width: 120rem;
        }

        #iubenda_policy .iub_content {
            padding: 0;
            font-family: $f-family-text;
            color: var(--c-corporate-1);
        }

        #iubenda_policy h1, #iubenda_policy h2, #iubenda_policy h3, #iubenda_policy h4, #iubenda_policy h5, #iubenda_policy h6 {
            color: var(--c-corporate-1);
        }

        #iubenda_policy a {
            color: var(--c-corporate-1);
            text-decoration: underline;
        }

    }

    // .box--section-1

}

// .page-template-legal