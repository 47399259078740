/*------------------------------------*\
    Custom Taxonomy: Famiglia ( family )
    Post Type: Articoli ( post )
    taxonomy-family.php
    .archive.tax-family
\*------------------------------------*/

.archive.tax-family {


    .family {

        &.color-- { --c-family: var(--c-corporate-1); }
        &.color--hydro { --c-family: var(--c-hydro); }
        &.color--oilgas { --c-family: var(--c-oil); }
        &.color--electric { --c-family: var(--c-electric); }

        &.family--parent {

            position: relative;

            .bg {

                position: absolute;
                top: 0;
                right: 0;
                width: 30vw;
                max-width: 70rem;
                height: 50rem;
                z-index: -1;

                @media #{$media-bx} {
                    width: 20vw;
                    min-width: 30rem;
                }

                .family-icon {

                    display: block;
                    width: 6rem;
                    position: absolute;
                    top: 2rem;
                    left: 22rem;
                    z-index: 3;

                    @media #{$media-bx} {
                        left: inherit;
                        right: 10rem;
                    }
                    @media #{$media-b} {
                        right: 4rem;
                        top: 4rem;
                    }
                    @media #{$media-m} { width: 4rem; }
                    @media #{$media-sx} { display: none; }

                }// .family-icon

                .family-draw {

                    display: block;
                    width: 20rem;
                    position: absolute;
                    top: 4rem;
                    left: 0;
                    z-index: 2;
                    fill: var(--c-grey-normal);

                    @media #{$media-bx} {
                        fill: var(--c-grey-light);
                        left: inherit;
                        top: 10rem;
                        right: 10rem;
                    }
                    @media #{$media-b} { display: none; }

                }// .family-draw

                .family-dots {

                    display: block;
                    // width: 33.6rem;
                    height: 100%;
                    position: absolute;
                    top: 0;
                    right: 4rem;
                    z-index: -1;

                    .dots {

                        fill: var(--c-grey-light);
                        @media #{$media-bx} { display: none; }

                    }
                    .dots-color {

                        fill: var(--c-family);
                        @media #{$media-b} { display: none; }

                    }

                }// .family-dots

            }// .bg

            .items {

                padding: $gutter;

                display: flex;
                justify-content: center;
                gap: $gutter;
                flex-wrap: wrap;

                @media #{$media-b} { flex-wrap: wrap; }
                // @media #{$media-sm} { grid-template-columns: repeat(1, 1fr); }

                .item {

                    width: 36rem;
                    position: relative;

                    @media #{$media-b} { width: 30rem; }

                    &:hover,
                    &:focus-within {
                        outline: .1rem solid var(--c-accent);
                        outline-offset: 0;
                    }

                    .link {

                        outline: none;

                        &::before {
                            content: '';
                            display: block;
                            position: absolute;
                            width: 100%;
                            height: 100%;
                            top: 0;
                            right: 0;
                            bottom: 0;
                            left: 0;
                            z-index: 1;
                        }

                    }// .link

                    .image {

                        padding: $gutter / 2;
                        position: relative;

                        .img {
                            display: block;
                            width: 100%;
                            height: 30rem;
                            object-fit: contain;
                        }// .img

                        svg {
                            display: block;
                            width: 100%;
                            height: 100%;
                            position: absolute;
                            top: 0;
                            left: 0;
                            z-index: -1;
                            fill: var(--c-family)
                        }//

                    }// .image

                    .title {

                        // font-size: 3.6rem;
                        font-size: 2.6rem;
                        line-height: 1.1;
                        font-weight: $f-weight-500;
                        text-transform: uppercase;

                    }// .title

                }// .item

            }// .items

        }// .family--parent

        &.family--child {

            .bg {

                position: absolute;
                overflow: hidden;
                top: 0;
                right: 0;
                width: 40rem;
                height: 30rem;
                z-index: -1;

                .family-dots {

                    display: block;
                    width: 29rem;
                    height: auto;
                    position: absolute;
                    top: 0;
                    right: 4rem;
                    z-index: -1;

                    .dots {

                        fill: var(--c-grey-light);
                        @media #{$media-m} { display: none; }

                    }
                    .dots-color {

                        fill: var(--c-family);
                        @media #{$media-s} { display: none; }

                    }

                }// .family-dots

            }// .bg

            .back {

                padding: $gutter;

                @media #{$media-sx} {
                    padding: $gutter / 2;
                }


            }// .back

        }// .family--child

    }// .family



    .faceted-search {

        background-color: var(--c-white);
        padding: $gutter;

        display: grid;
        grid-gap: $gutter*.5;
        grid-template-columns: 1fr 3fr;

        @media #{$media-m} { grid-template-columns: repeat(1, 1fr); }

        @media #{$media-sx} {
           padding: $gutter $gutter / 2;
        }

        .facets {

            font-family: $f-family-title;

            .facetwp-type-autocomplete {

                margin-bottom: 1rem;
                width: 100%;
                height: 5rem;

                .facetwp-autocomplete {

                    width: calc(100% - 5rem);
                    height: 100%;
                    border: .1rem solid var(--c-corporate-1);
                    padding: 1rem 2rem;

                }// .facetwp-autocomplete

                .facetwp-autocomplete-update {

                    width: 5rem;
                    height: 100%;
                    color: var(--c-white);
                    background-color: var(--c-corporate-1);
                    border: none;

                }// .facetwp-autocomplete-update

            }// .facetwp-type-autocomplete

            .item + .item {

                margin-top: 1rem;

            }// .item

            .item {

                .facetwp-facet {

                    font-size: 1.6rem;
                    font-weight: $f-weight-600;

                    padding: 2rem;
                    margin-bottom: 0;// override facetwp /wp-content/plugins/facetwp/assets/css/front.css:1

                    a {
                        @media #{$media-sx} {
                            font-size: 1.4rem;
                        }
                    }

                }// .facetwp-facet

                .facetwp-counter { display: none; }

                .facetwp-checkbox {

                    background-color: var(--c-corporate-3);
                    background-image: none;// override facetwp /wp-content/plugins/facetwp/assets/css/front.css:44

                    display: flex;
                    align-items: center;

                    position: relative;
                    padding: 1rem;

                    .facetwp-display-value {
                        margin-left: 1.5rem;
                        line-height: 1.1;

                        @media #{$media-sx} {
                            font-size: 1.4rem;
                        }
                    }

                    &::before {
                        flex-shrink: 0;
                        content: '';
                        display: inline-flex;
                        width: 1.6rem;
                        height: 1.6rem;
                        background-color: var(--c-white);
                        border: .1rem solid var(--c-corporate-1);
                        // position: absolute;
                        // top: 50%;
                        // left: 1rem;
                        // transform: translateY(-50%);
                    }

                    &.checked {

                        &::before {
                            background-color: var(--c-corporate-5);
                            border: .1rem solid var(--c-corporate-1);
                            // box-shadow:inset 0px 0px 0px 10px var(--c-accent);
                        }

                        &::after {
                            content: '';
                            display: inline-flex;
                            width: 1.2rem;
                            height: 1.2rem;
                            background-color: var(--c-corporate-1);
                            position: absolute;
                            top: 50%;
                            left: 1.3rem;
                            transform: translateY(-50%);
                        }

                    }

                }// .facetwp-checkbox

            }// .item

            details {

                summary {

                    color: var(--c-corporate-1);
                    background-color: var(--c-corporate-5);

                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    padding: 2rem;
                    font-size: 1.8rem;
                    font-weight: $f-weight-600;
                    text-transform: uppercase;

                    @media #{$media-sx} {
                        padding: 1rem;
                        font-size: 1.6rem;
                    }

                    &::after {

                        display: inline-flex;
                        content: '';
                        width: 0;
                        height: 0;

                        border-left: .75rem solid transparent;
                        border-right: .75rem solid transparent;
                        border-top: 1.3rem solid currentColor;
                        border-bottom: initial;

                    }

                    &:hover { cursor: pointer; }

                }

                &[open] {

                    summary {

                        &::after {
                            border-top: initial;
                            border-bottom: 1.3rem solid currentColor;
                        }

                    }

                }

            }// details

        }// .facets

        .content {

            .facewp-top {

                font-family: $f-family-title;

                .facetwp-selections {

                    margin-bottom: 0;

                    ul {
                        display: flex;
                        flex-wrap: wrap;
                        gap: 2rem;
                    }

                    li {

                        border: .1rem solid var(--c-grey-light);
                        padding: 2rem;

                        @media #{$media-sx} {
                            font-size: 1.4rem;
                        }

                    }

                    .facetwp-selection-label {
                        text-transform: uppercase;
                        font-weight: $f-weight-700;
                        margin-right: .5rem;
                    }

                    .facetwp-selection-value {
                        color: var(--c-theme-5);
                    }

                }// .facetwp-selections

                .paging {

                    display: flex;
                    justify-content: space-between;
                    gap: $gutter;

                    border-bottom: .1rem solid var(--c-grey-light);
                    padding: 2rem;
                    margin-bottom: 2rem;

                    @media #{$media-sx} {
                       padding: 2rem 0;
                    }

                    .facetwp-facet-result_counts {

                        margin-bottom: 0;
                        font-weight: $f-weight-700;

                    }// .facetwp-facet-result_counts

                    .facetwp-facet-per_page {

                        margin-bottom: 0;

                        .facetwp-per-page-select {

                            background-color: transparent;
                            border: .1rem solid currentColor;
                            padding: 1rem;
                            font-weight: $f-weight-700;

                        }// .facetwp-per-page-select

                    }// .facetwp-facet-per_page

                }// .paging

            }// .facewp-top

            .facetwp-template {

                .items {

                    display: grid;
                    grid-gap: $gutter*.5;
                    grid-template-columns: 1fr 1fr 1fr;

                    @media #{$media-b} { grid-template-columns: repeat(2, 1fr); }
                    @media #{$media-sm} { grid-template-columns: repeat(1, 1fr); }

                }// .items

                .item {

                    position: relative;
                    border: .1rem solid var(--c-grey-light);
                    padding: 2rem;
                    min-height: 20rem;

                    display: flex;
                    flex-direction: column;

                    &:hover,
                    &:focus-within {
                        outline: .1rem solid var(--c-accent);
                        outline-offset: 0;
                    }

                    .link {

                        outline: none;

                        &::before {
                            content: '';
                            display: block;
                            position: absolute;
                            width: 100%;
                            height: 100%;
                            top: 0;
                            right: 0;
                            bottom: 0;
                            left: 0;
                            z-index: 1;
                        }

                    }// .link

                    .image {

                        margin: auto;
                        max-width: 25rem;
                        // padding: $gutter*2;
                        // padding: 20%;


                        .img {

                            display: block;
                            width: 100%;
                            height: auto;

                        }// .img

                    }// .image

                    .title {

                        font-size: 3rem;
                        text-align: center;

                    }// .title

                    .text {

                        margin-top: 2rem;
                        margin-bottom: 2rem;
                        font-family: $f-family-title;
                        // font-size: 1.8rem;
                        // line-height: 1.6;

                    }// .text

                    .materials {

                        margin-top: auto;
                        display: flex;
                        min-height: 5rem;

                        .material {

                            display: flex;
                            justify-content: center;
                            align-items: center;
                            color: var(--c-white);
                            width: 100%;
                            position: relative;

                            &::after {

                                position: absolute;
                                top: -1rem;
                                left: .75rem;
                                display: inline-flex;
                                justify-content: center;
                                align-items: center;
                                border-radius: 50%;
                                border: .1rem solid var(--c-white);
                                color: var(--c-white);
                                width: 2.4rem;
                                height: 2.4rem;
                                font-size: 1.2rem;
                                font-weight: normal;

                            }

                            &.stainless-steel { background-color: var(--c-stainless); }
                            &.carbon-steel { background-color: var(--c-carbon); }

                            &.carbon-steel::after {
                                content: 'CS';
                                background-color: var(--c-carbon);
                            }
                            &.stainless-steel::after {
                                content: 'SS';
                                background-color: var(--c-stainless);
                            }

                        }// .material

                    }// .materials

                }// .item

                .item--kit {

                    &:hover,
                    &:focus-within {
                        outline: none !important;
                    }

                    .kit {

                        margin-top: auto;
                        display: flex;
                        min-height: 5rem;

                        .file {

                            display: flex;
                            justify-content: center;
                            align-items: center;
                            color: var(--c-white);
                            width: 100%;
                            background-color: var(--c-corporate-2);

                            &:hover,
                            &:focus-within {
                                outline: .1rem solid var(--c-corporate-1);
                                outline-offset: 0;
                                color: var(--c-corporate-1);
                                background-color: var(--c-accent);
                            }

                            .type {

                                margin-left: .5rem;
                                margin-right: .5rem;
                                font-size: .8em;
                                text-transform: uppercase;

                            }// .tyle

                        }// .file

                    }// .kit

                }// .item--kit

            }// .facetwp-template

            .facewp-bottom {

                font-family: $f-family-title;

                .facetwp-facet-page_numbers {

                    margin-top: $gutter;
                    margin-bottom: 0;

                    .facetwp-pager {

                        display: flex;
                        flex-wrap: wrap;
                        gap: $gutter*.25;

                        .facetwp-page {

                            display: flex;
                    		justify-content: center;
                    		align-items: center;
                    		width: 5rem;
                    		height: 5rem;
                            margin: 0;
                            padding: 0;
                            border: .1rem solid var(--c-grey-light);
                            font-size: 1.8rem;

                            &.first {}
                            &.last {}
                            &.prev {}
                            &.next {}

                            &:hover:not(.active),
                            &:focus-within:not(.active) { border-color: currentColor; }

                            &.active {

                                color: var(--c-white);
                                position: relative;
                                font-weight: normal;
                                z-index: 1;

                                &::before {
                                    content: '';
                                    width: 4rem;
                                    height: 4rem;
                                    position: absolute;
                                    top: 50%;
                                    left: 50%;
                                    transform: translateY(-50%) translateX(-50%);
                                    background-color: var(--c-corporate-2);
                                    z-index: -1;
                                }

                            }// .active

                        }// .facetwp-page

                    }// .facetwp-pager

                }// .facetwp-facet-page_numbers

            }// .facewp-bottom

        }// .content

    }// .faceted-search



}// .tax-family
