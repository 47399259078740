/*------------------------------------*\
    Page Template: Storia
    storia.php
    .page-template-storia
\*------------------------------------*/

.page-template-storia {

    #main {
        overflow-x: hidden;
        overflow-y: clip;
    }

    .box--intro {
        margin: 0 auto;
        max-width: $w-media-uw;
        padding-bottom: $gutter / 2;
    }

    .box--section-1 {
        display: flex;
        flex-wrap: wrap;

        @media #{$media-u} {
            max-width: $w-media-uw;
        }

        .col-1 {
            width: 50%;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-end;
            padding-right: $gutter * 2;

            @media #{$media-m} {
                width: 100%;
                padding: 0 0 $gutter 0;
            }

            @media #{$media-sx} {
                padding-bottom: $gutter / 2;
            }

            .text {
                padding: 0 0 $gutter $gutter * 4;

                @media #{$media-b} {
                    padding: 0 0 $gutter 0;
                }

                @media #{$media-sx} {
                    font-size: 1.6rem;
                }
            }
        }

        .col-2 {
            width: 50%;
            position: relative;
            display: flex;
            justify-content: flex-end;
            align-items: flex-end;
            margin-top: - $gutter * 4;

            @media #{$media-m} {
                width: 65%;
                padding: 0;
                margin-top: - $gutter * 9;
            }

            @media #{$media-sx} {
                width: 100%;
                margin-top: 0;
            }

            svg {
                position: absolute;
                bottom: -1px;
                right: 0;

                @media #{$media-s} {
                    transform: scale(.5) translateY(50%) translateX(50%);
                }
            }
        }

    }

    // .box--section-1

    .box--section-2 {
        display: flex;
        flex-wrap: wrap;
        margin-top: $gutter * 2;
        margin-bottom: $gutter * 4;

        @media #{$media-u} {
            max-width: $w-media-uw;
        }

        @media #{$media-s} {
            margin-top: $gutter;
            margin-bottom: $gutter * 2;
        }

        .col-1 {
            width: 50%;

            @media #{$media-b} {
                width: 100%;
            }

            .content {
                display: flex;
                align-items: flex-end;

                .title {
                    color: var(--c-white);
                    background-color: var(--c-corporate-1);
                    font-weight: 700;
                    padding: $gutter / 2.5 $gutter;
                    margin-left: $gutter;

                    @media #{$media-sx} {
                        padding: $gutter / 2.5 $gutter / 2;
                    }
                }

                img {
                    @media #{$media-s} {
                        max-width: 25%;
                    }
                }
            }
        }

        .col-2 {
            width: 50%;

            @media #{$media-b} {
                width: 100%;
            }

            .timeline {
                position: relative;
                margin-left: - $gutter * 25;
                padding-top: $gutter * 4;

                @media #{$media-bx} {
                    margin-left: - $gutter * 15;
                }

                @media #{$media-b} {
                    padding-top: $gutter;
                    margin-left: 0;
                }

                &::after {
                    position: absolute;
                    top: $gutter * 4;
                    left: 50%;
                    content: '';
                    width: 1px;
                    height: 94%;
                    border: 1px solid var(--c-corporate-1);

                    @media #{$media-b} {
                        top: $gutter;
                        height: 100%;
                    }

                    @media #{$media-s} {
                        left: $gutter / 3;
                    }
                }

                .content {
                    padding: $gutter / 3 $gutter / 2;
                    background-color: var(--c-white);
                    border: 1px solid var(--c-corporate-1);
                    border-radius: 20px;
                    width: $gutter * 10;
                    position: relative;
                    margin-bottom: $gutter;

                    @media #{$media-m} {
                        width: $gutter * 7;
                    }

                    @media #{$media-sm} {
                        width: $gutter * 6;
                    }

                    @media #{$media-s} {
                        width: 100%;
                    }

                    &::after {
                        position: absolute;
                        content: '';
                        width: 0;
                        height: 0;
                    }

                    &::before {
                        position: absolute;
                        content: '';
                        width: 0;
                        height: 0;
                    }

                    span {
                        font-size: 1.6rem;
                    }

                    p {
                        font-size: 1.4rem;
                    }
                }

                .box-timeline-right,
                .box-timeline-left {
                    display: flex;
                    justify-content: center;
                    position: relative;

                    @media #{$media-s} {
                        justify-content: flex-start;
                        padding-left: $gutter * 1.5;
                    }

                    &::after {
                        position: absolute;
                        bottom: $gutter * 1.2;
                        content: url('../images/simbolo_logo.svg');

                        @media #{$media-s} {
                            left: .2rem;
                        }
                    }
                }

                .box-timeline-right {
                    .content {
                        margin-right: - $gutter * 13;

                        @media #{$media-m} {
                            margin-right: - $gutter * 10;
                        }

                        @media #{$media-sm} {
                            margin-right: - $gutter * 9;
                        }

                        @media #{$media-s} {
                            margin-right: 0;
                        }

                        &::after {
                            bottom: 20px;
                            left: -11px;
                            border-top: 12px solid transparent;
                            border-bottom: 12px solid transparent;
                            border-right: 12px solid var(--c-white);
                        }

                        &::before {
                            bottom: 19px;
                            left: -12px;
                            border-top: 13px solid transparent;
                            border-bottom: 13px solid transparent;
                            border-right: 13px solid var(--c-corporate-1);
                        }
                    }
                }

                .box-timeline-left {
                    .content {
                        margin-left: - $gutter * 13;

                        @media #{$media-m} {
                            margin-left: - $gutter * 10;
                        }

                        @media #{$media-sm} {
                            margin-left: - $gutter * 9;
                        }

                        @media #{$media-s} {
                            margin-left: 0;
                        }

                        &::after {
                            bottom: 20px;
                            right: -11px;
                            border-top: 12px solid transparent;
                            border-bottom: 12px solid transparent;
                            border-left: 12px solid var(--c-white);

                            @media #{$media-s} {
                                margin-left: 0;
                                bottom: 20px;
                                left: -11px;
                                right: 0;
                                border-top: 12px solid transparent;
                                border-bottom: 12px solid transparent;
                                border-right: 12px solid var(--c-white);
                                border-left: 0;
                            }
                        }

                        &::before {
                            bottom: 19px;
                            right: -13px;
                            border-top: 13px solid transparent;
                            border-bottom: 13px solid transparent;
                            border-left: 13px solid var(--c-corporate-1);

                            @media #{$media-s} {
                                bottom: 19px;
                                left: -12px;
                                right: 0;
                                border-top: 13px solid transparent;
                                border-bottom: 13px solid transparent;
                                border-right: 13px solid var(--c-corporate-1);
                                border-left: 0;
                            }
                        }
                    }
                }
            }


        }
    }

    // .box--section-2

}

// .page-template-storia