/*------------------------------------*\
    Page Template: Sostenibilità
    sostenibilita.php
    .page-template-sostenibilita
\*------------------------------------*/

.page-template-sostenibilita {

    #main {
        overflow-x: hidden;
        overflow-y: clip;
    }

    .box--intro {
        margin: 0 auto;
        max-width: $w-media-uw;
        padding-bottom: $gutter / 2;
    }

    .box--section-1 {
        display: flex;
        flex-wrap: wrap;

        @media #{$media-u} {
            max-width: $w-media-uw;
        }

        .col-1 {
            width: 40%;

            @media #{$media-sm} {
                width: 100%;
            }

            .title {
                font-size: 3.2rem;
                line-height: 1.4;
                font-weight: 500;
                padding: $gutter $gutter * 2 0 $gutter * 4;

                @media #{$media-bx} {
                    font-size: 2.6rem;
                    padding: $gutter $gutter 0 $gutter * 2;
                }

                @media #{$media-b} {
                    font-size: 2rem;
                    padding: $gutter $gutter 0 $gutter;
                }

                @media #{$media-sm} {
                    font-size: 2rem;
                    padding: 0 0 $gutter;
                }

                @media #{$media-sx} {
                    font-size: 1.8rem;
                    padding-bottom: $gutter / 2;
                }
            }
        }

        .col-2 {
            width: 60%;
            margin-top: - $gutter * 4;
            padding-left: $gutter * 1.5;

            @media #{$media-bx} {
                margin-top: 0;
            }

            @media #{$media-b} {
                padding-left: 0;
            }

            @media #{$media-sm} {
                width: 100%;
            }

            img {
                object-fit: cover;
                height: 60rem;
                width: 100%;

                @media #{$media-bx} {
                    height: 50rem;
                }

                @media #{$media-b} {
                    height: 40rem;
                }

                @media #{$media-sm} {
                    height: auto;
                }
            }
        }

        .col-3 {
            width: 60%;
            position: relative;
            margin-top: $gutter * 2;

            @media #{$media-bx} {
                width: 70%
            }

            @media #{$media-b} {
                width: 80%
            }

            @media #{$media-m} {
                width: 90%
            }

            @media #{$media-sm} {
                width: 100%;
                margin-top: $gutter;
            }

            .text {
                padding: 0 0 $gutter * 4 $gutter * 4;
                font-size: 1.6rem;

                @media #{$media-m} {
                    padding-left: $gutter * 2;
                }

                @media #{$media-sm} {
                    padding-left: 0;
                    padding-bottom: $gutter;
                }
            }

            img {
                position: absolute;
                top: -70%;
                left: -20%;
                z-index: -1;

                @media #{$media-bx} {
                    top: -50%;
                }

                @media #{$media-sm} {
                    top: -50%;
                    left: -90%;
                    max-width: none;
                }
            }
        }

    }

    // .box--section-1

    .box--section-2 {
        display: flex;
        flex-wrap: wrap;

        @media #{$media-u} {
            max-width: $w-media-uw;
        }

        .col-1 {
            width: 50%;
            display: flex;
            justify-content: flex-end;

            @media #{$media-bx} {
                width: 40%;
            }

            @media #{$media-b} {
                width: 30%;
            }

            @media #{$media-m} {
                width: 100%;
            }

            .content {
                width: 40%;

                @media #{$media-bx} {
                    width: 60%;
                }

                @media #{$media-b} {
                    width: 100%;
                }

                .title {
                    background-color: var(--c-accent);
                    padding: $gutter / 3 $gutter / 2;
                    font-size: 2.6rem;
                    font-weight: 700;

                    @media #{$media-b} {
                        font-size: 2.4rem;
                    }

                    @media #{$media-sx} {
                        font-size: 2rem;
                    }

                    p {
                        margin-bottom: $gutter / 2;
                    }
                }

                .text {
                    padding: $gutter / 3 $gutter / 2;
                    font-size: 1.6rem;

                    @media #{$media-sx} {
                        padding: $gutter / 3 0;
                    }
                }
            }
        }

        .col-2 {
            width: 50%;
            display: flex;
            flex-wrap: wrap;
            background-color: var(--c-corporate-5);
            padding: $gutter / 3 $gutter / 2;
            gap: 10px;

            @media #{$media-bx} {
                width: 60%;
            }

            @media #{$media-b} {
                width: 70%;
            }

            @media #{$media-m} {
                width: 100%;
            }

            .item {
                flex: 1;
                display: flex;
                flex-direction: column;
                position: relative;
                max-width: 50%;
                
                @media #{$media-sm} {
                    flex: auto;
                    max-width: none;
                }

                .link {

                    outline: none;

                    &::before {
                        content: '';
                        display: block;
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        top: 0;
                        right: 0;
                        bottom: 0;
                        left: 0;
                        z-index: 5;
                    }

                }

                // .link

                .image {

                    height: 30rem;

                    .img {

                        display: block;
                        width: 100%;
                        height: 100%;
                        object-fit: cover;

                    }

                    // .img

                }

                // .image

                .content {

                    padding: $gutter / 2 0;

                }

                // .content

                .time {

                    font-family: $f-family-title;
                    font-weight: $f-weight-500;
                    font-size: 1.4rem;
                    text-transform: uppercase;

                }

                // .time

                .title {

                    margin-top: 1rem;

                    font-size: 2rem;
                    line-height: 1.1;
                    font-weight: $f-weight-600;

                }

                // .title

                .text {

                    margin-top: 1rem;
                    font-size: 1.4rem;
                    line-height: 1.2;

                }

                // .text
            }
        }

    }

    // .box--section-2

    .box--section-3 {
        display: flex;
        flex-wrap: wrap;
        margin-top: $gutter * 5;

        @media #{$media-u} {
            max-width: $w-media-uw;
        }

        @media #{$media-m} {
            margin-top: $gutter;
        }

        .col-1 {
            width: 100%;
            position: relative;

            .content {
                width: 50%;
                position: absolute;
                background-color: var(--c-corporate-5);
                padding: $gutter / 2 $gutter / 1.5;
                margin-top: - $gutter * 1.5;

                @media #{$media-b} {
                    width: 60%;
                }

                @media #{$media-m} {
                    width: 100%;
                    position: relative;
                    margin-top: 0;
                }

                .title {
                    color: var(--c-theme-4);
                    font-size: 2.6rem;
                    font-weight: 700;
                    margin-bottom: $gutter / 2;

                    @media #{$media-b} {
                        font-size: 2.4rem;
                    }

                    @media #{$media-sx} {
                        font-size: 2rem;
                    }
                }

                .text {
                    font-size: 1.6rem;
                }
            }
        }
    }

    // .box--section-3

    .box--section-4 {
        display: flex;
        flex-wrap: wrap;
        margin-top: $gutter * 2;

        @media #{$media-u} {
            max-width: $w-media-uw;
        }

        @media #{$media-m} {
            margin-top: $gutter;
        }

        @media #{$media-s} {
            margin-top: $gutter / 2;
        }

        .col-1,
        .col-2 {
            width: 50%;

            @media #{$media-sm} {
                width: 100%;
            }

            .title {
                color: var(--c-theme-4);
                font-size: 2.6rem;
                font-weight: 700;
                margin-bottom: $gutter / 2;

                @media #{$media-b} {
                    font-size: 2.4rem;
                }

                @media #{$media-sx} {
                    font-size: 2rem;
                }
            }

            .text {
                @media #{$media-b} {
                    font-size: 1.6rem;
                }
            }

        }

        .col-1 {
            padding-right: $gutter;

            @media #{$media-sm} {
                padding-right: 0;
                margin-bottom: $gutter / 2;
            }
        }

        .col-2 {
            padding-left: $gutter;

            @media #{$media-sm} {
                padding-left: 0;
            }
        }

        .col-3 {
            width: 100%;

            @media #{$media-m} {
                display: flex;
                justify-content: center;

                img {
                    max-width: none;
                }
            }
        }
    }

    // .box--section-4

}

// .page-template-sostenibilita