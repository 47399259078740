/*------------------------------------*\
    Page Template: Wishlist
    wishlist.php
    .page-template-wishlist
\*------------------------------------*/

.page-template-wishlist {

    .box--intro {
        margin: 0 auto;
        max-width: $w-media-uw;
        padding-bottom: $gutter / 2;
    }

    .box--section-1 {
        display: flex;
        flex-wrap: wrap;
        position: relative;

        @media #{$media-u} {
            max-width: $w-media-uw;
        }

        &::after {
            width: 30%;
            height: 184px;
            position: absolute;
            right: 0;
            top: - $gutter * 4;
            content: '';
            background: url('../images/griglia_contatti.svg') no-repeat center center / contain;

            @media #{$media-sm} {
                display: none;
            }
        }

        .col-1 {
            width: 100%;
            margin: 0 auto;
            margin-top: $gutter;
            padding: 0 $gutter * 2;
            max-width: $w-media-u;
            display: flex;
            align-items: center;
            gap: 1px;

            @media #{$media-m} {
                padding: 0 0 $gutter 0;
            }

            @media #{$media-sx} {
                margin-top: 0;
                padding-bottom: 0;
                font-size: 1.4rem;
            }
        }
    }

    // .box--section-1

    .box--section-3 {
        display: flex;
        flex-wrap: wrap;
        position: relative;
        margin-top: $gutter;

        .col-1 {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 100%;

            .wishlistTable {
                width: 100%;
                font-family: $f-family-title;
                display: flex;
                flex-wrap: wrap;
                flex-direction: column;
                overflow-x: auto;

                .thead {
                    width: 100%;
                    display: flex;
                    flex-wrap: wrap;
                    gap: 50px;
                    margin-bottom: $gutter / 2;

                    @media #{$media-sm} {
                        display: none;
                    }

                    .thBlock {
                        background-color: var(--c-corporate-5);
                        display: flex;

                        &.last {
                            @media #{"(max-width: 1869px)"} {
                                display: none;
                            }
                        }

                        div {
                            border-left: .1rem solid var(--c-grey-dark);
                            border-top: .1rem solid var(--c-grey-dark);
                            border-bottom: .1rem solid var(--c-grey-dark);
                            vertical-align: middle;
                            font-size: 1.6rem;
                            font-weight: 700;
                            line-height: 1;
                            color: var(--c-theme-4);
                            padding: $gutter / 4;
                            flex: 1;
                            display: flex;
                            align-items: center;
                            justify-content: center;

                            &.thFamiglia {
                                width: 100px;
                                flex: none;
                            }

                            &.thName {
                                width: 200px;
                                flex: none;
                            }

                            &.thDesc {
                                width: 300px;
                                flex: none;
                            }

                            &.thCarrello,
                            &.thRimuovi,
                            &.thScheda {
                                width: 90px;
                                flex: none;
                            }

                            &.lastTh {
                                border-right: .1rem solid var(--c-grey-dark);
                            }

                            &.emptyTh {
                                width: 50px;
                                flex: none;
                                background-color: var(--c-white);
                                border-top: .1rem solid var(--c-white);
                                border-bottom: .1rem solid var(--c-white);
                            }
                        }
                    }
                }

                .tbody {
                    display: flex;
                    flex-wrap: wrap;
                    width: 100%;
                    gap: 20px 50px;

                    .wishlistElement {
                        display: flex;

                        @media #{$media-sm} {
                            flex-direction: column;
                            width: 100%;
                        }

                        &.gray1 {
                            background-color: #F7F8FA;
                        }

                        &.gray2 {
                            background-color: #DEDEDE;
                        }

                        div {
                            padding: $gutter / 4;
                            min-height: 55px;
                            display: flex;
                            align-items: center;
                            text-align: center;
                            font-size: 1.6rem;
                            flex: 1;

                            @media #{$media-sm} {
                                width: 100% !important;
                                justify-content: space-between !important;
                                position: relative;
                                text-align: right !important;

                                &::before {
                                    content: attr(title) ' ' attr(data-unit);
                                    font-size: 1.4rem;
                                }

                                &.hideMobile {
                                    display: none !important;
                                }
                            }

                            @media #{$media-sx} {
                                font-size: 1.4rem;
                            }

                            &.modelName,
                            &.modelDesc {
                                text-align: left;
                            }

                            &.tdFamiglia {
                                width: 100px;
                                flex: none;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                            }

                            &.tdName {
                                width: 200px;
                                flex: none;
                            }

                            &.tdDesc {
                                width: 300px;
                                flex: none;
                            }

                            &.tdCarrello,
                            &.tdRimuovi,
                            &.tdScheda {
                                width: 90px;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                flex: none;

                                a {
                                    display: inherit;
                                }
                            }

                            &.emptyTd {
                                width: 50px;
                                flex: none;
                            }
                        }
                    }

                    .divisore {
                        width: 100%;
                        height: $gutter / 2;

                        @media #{$media-sm} { 
                            display: none;
                        }
                    }
                }
            }
        }
    }

    // .box--section-3

    .box--section-5 {
        display: flex;
        position: relative;

        @media #{$media-u} {
            max-width: $w-media-uw;
        }

        .col-1 {
            width: 100%;
            margin: 0 auto;
            margin-top: $gutter;
            padding: 0 $gutter * 2;
            max-width: $w-media-u;
            display: flex;
            flex-direction: column;
            font-size: 2.4rem;

            a {
                text-decoration: underline;
            }
        }
    }

    // .box--section-5
}

// .page-template-wishlist