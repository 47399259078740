/*------------------------------------*\
    Page Template: Login Registrazione
    login-registrazione.php
    .page-template-login-registrazione
\*------------------------------------*/

.page-template-login-registrazione {
    #main {
        overflow-x: hidden;
        overflow-y: clip;
    }

    .box--intro {
        margin: 0 auto;
        max-width: $w-media-uw;
        padding-bottom: $gutter / 2;
    }

    .box--section-1 {
        display: flex;
        flex-wrap: wrap;

        @media #{$media-u} {
            max-width: $w-media-uw;
        }

        .col-1 {
            width: 100%;
            position: relative;

            &::after {
                width: 30%;
                height: 184px;
                position: absolute;
                right: 0;
                top: - $gutter * 4;
                content: '';
                background: url('../images/griglia_contatti.svg') no-repeat center center / contain;

                @media #{$media-sm} {
                    display: none;
                }
            }

            .text {
                max-width: $w-media-b;
                padding: 0 $gutter * 2 0 $gutter * 4;

                @media #{$media-bx} {
                    padding-left: $gutter;
                }

                @media #{$media-b} {
                    padding-left: 0;
                    width: 70%;
                }

                @media #{$media-sm} {
                    width: 100%;
                    padding-right: 0;
                }

                @media #{$media-sx} {
                    font-size: 1.4rem;
                }
            }
        }
    }

    // .box--section-1

    .box--section-2 {
        display: flex;
        flex-wrap: wrap;
        margin-top: $gutter;
        padding-bottom: $gutter * 2;

        @media #{$media-u} {
            max-width: $w-media-uw;
        }

        @media #{$media-sx} {
            margin-top: $gutter / 3;
            padding-bottom: $gutter;
        }

        .col-1 {
            width: 100%;
            padding-left: $gutter * 2;

            @media #{$media-bx} {
                padding-left: $gutter;
            }

            @media #{$media-b} {
                padding-left: 0;
            }

            .text {
                font-size: 3rem;

                @media #{$media-s} {
                    font-size: 2.2rem;
                }
            }
        }

        .col-2 {
            width: 50%;
            padding-left: $gutter * 2;

            @media #{$media-bx} {
                padding-left: $gutter;
            }

            @media #{$media-b} {
                padding-left: 0;
                width: 60%;
            }

            @media #{$media-m} {
                padding-left: 0;
                width: 100%;
            }

            p:not(.gform_description) {
                margin-top: $gutter;
                font-size: 1.8rem;
            }

            &.resetpassword {
                br {
                    display: none;
                }
            }
        }

        &.logout {
            a {
                text-decoration: underline;
            }
        }

        .register_privacy {
            color: var(--c-black);
            font-size: 1.4rem;
        }

        #gform_fields_login {
            display: flex;
            justify-content: space-between;
            gap: $gutter;

            .gfield {
                flex: 1;
            }

            .gfield--type-remember_me {
                display: none;
            }
        }

        .gform_required_legend {
            display: none;
        }

        .gform_footer {
            display: flex;
            justify-content: center;
            padding-bottom: $gutter !important;

            button {
                margin-top: 0;
                border: 0;
                background-color: var(--c-corporate-1);
                color: var(--c-white);
                text-transform: uppercase;
                padding: $gutter / 6 $gutter * 3 $gutter / 6 $gutter * 2;
                position: relative;
                transition: 0.15s all ease;

                &::after {
                    position: absolute;
                    content: '';
                    background-color: var(--c-white);
                    clip-path: polygon(100% 100%, 0% 100%, 100% 0);
                    right: -1px;
                    bottom: -1px;
                    width: 35px;
                    height: 101%;
                    margin-top: 0;
                }

                &:hover {
                    background-color: var(--c-corporate-2);
                }
            }
        }

        .gf_login_links {
            a {
                display: inline-block;
                text-decoration: underline;
                font-size: 1.8rem;
                margin-bottom: $gutter / 2;
            }
        }

        &.register {

            .col-1,
            .col-2 {
                width: 50%;
            }

            .col-1 {
                @media #{$media-bx} {
                    padding-left: $gutter;
                }

                @media #{$media-b} {
                    padding-left: 0;
                    width: 60%;
                }

                @media #{$media-m} {
                    width: 100%;
                }
            }

            .col-2 {
                @media #{$media-b} {
                    width: 100%;
                    order: -1;
                }
            }

            .text {
                font-size: 3rem;

                @media #{$media-s} {
                    font-size: 2.2rem;
                }
            }

            .register--info {
                display: flex;
                flex-direction: column;
                margin-top: $gutter;

                @media #{$media-b} {
                    margin-top: 0;
                    margin-bottom: $gutter * 1.5;
                }

                .box {
                    display: flex;
                    position: relative;
                    padding: $gutter / 1.5 $gutter / 2 $gutter / 1.5 $gutter * 1.5;
                    border-bottom: 1px dotted var(--c-corporate-1);

                    @media #{$media-m} {
                        flex-direction: column;
                    }

                    @media #{$media-s} {
                        padding: $gutter / 2 0 $gutter / 2 $gutter * 1.5;
                    }

                    &::before {
                        width: 22px;
                        height: 32px;
                        position: absolute;
                        left: $gutter / 4;
                        content: '';
                        background: url('../images/simbolo_logo.svg') no-repeat center center / contain;
                    }

                    .text {
                        font-size: 1.8rem;

                        @media #{$media-s} {
                            font-size: 1.6rem;
                        }

                        &.first {
                            min-width: $gutter * 5;
                            padding-right: $gutter / 2;
                        }
                    }
                }
            }
        }

        &.profile {
            .col-2 {
                width: 100%;
                display: flex;
                justify-content: center;

                @media #{$media-b} {
                    order: 2;
                }

                a {
                    display: flex;
                    justify-content: center;
                    position: relative;
                    border: 1px solid var(--c-corporate-1);
                    font-size: 1.4rem;
                    padding: $gutter / 1.5 $gutter 0 $gutter;
                    transition: 0.15s all ease;

                    &::before {
                        width: 15px;
                        height: 19px;
                        position: absolute;
                        top: 5px;
                        content: '';
                        background: url('../images/simbolo_logo.svg') no-repeat center center / contain;
                    }

                    &:hover {
                        background-color: var(--c-grey-light);
                    }
                }
            }
        }

        .contatti_ok {
            .image {
                display: flex;
                justify-content: center;
                margin-bottom: $gutter / 2;

                img {
                    width: $gutter * 2;
                    height: auto;
                }
            }
            br {
                display: block !important;
            }
        }
    }

    // .box--section-2
}

// .page-template-login-registrazione