/*------------------------------------*\
   Slick custom styles
\*------------------------------------*/

.gallery--single {
    .slick-dots {
        display: flex;
        justify-content: center;
        padding-top: $gutter / 5;

        button {
            background-color: var(--c-grey-normal);
            border: 0;
            outline: none;
            color: transparent;
            height: 8px;
            margin: 0 $gutter / 5;
            padding: 0 $gutter / 3;
            transition: 0.15s all ease;

            &:hover {
                background-color: var(--c-corporate-1);
            }
        }

        .slick-active {
            button {
                background-color: var(--c-corporate-1);
            }
        }
    }
}