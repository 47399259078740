/*------------------------------------*\
    Page
    page.php
    .page-template-default
\*------------------------------------*/

.page-template-default {

    .box--intro {
        max-width: $w-media-uw;
        margin: 0 auto;
    }

    .box--section-1 {
        max-width: $w-media-uw;
        margin: 0 auto;
        padding-bottom: $gutter * 2;
    }

}// .page-template-default
