/*------------------------------------*\
    Page Template: Video
    video.php
    .page-template-video
\*------------------------------------*/

.page-template-video {

    .box--intro {
        margin: 0 auto;
        max-width: $w-media-uw;
        padding-bottom: $gutter / 2;
    }

    .box--section-1 {
        display: flex;
        flex-wrap: wrap;

        @media #{$media-u} {
            max-width: $w-media-uw;
        }

        .col-1 {
            width: 100%;
            padding: 0 0 $gutter $gutter * 3;
            display: flex;
            position: relative;

            @media #{$media-m} {
                width: 100%;
                padding: 0 0 $gutter 0;
            }

            @media #{$media-sx} {
                padding-bottom: $gutter / 2;
            }

            &::after {
                width: 30%;
                height: 184px;
                position: absolute;
                right: 0;
                top: - $gutter * 3;
                content: '';
                background: url('../images/griglia_contatti.svg') no-repeat center center / contain;

                @media #{$media-sm} {
                    display: none;
                }
            }

            .text {
                width: 50%;
                padding: 0 $gutter * 4 0 0;

                @media #{$media-bx} {
                    padding-left: $gutter;
                }

                @media #{$media-b} {
                    padding-left: 0;
                    width: 70%;
                }

                @media #{$media-sm} {
                    width: 100%;
                    padding-right: 0;
                }

                @media #{$media-sx} {
                    font-size: 1.4rem;
                }
            }
        }

        //.col-1

    }

    // .box--section-1

    .box--videos {
        padding: 0 $gutter * 4;
        display: flex;
        flex-wrap: wrap;
        gap: 20px;

        @media #{$media-bx} {
            padding: 0 $gutter * 2;
        }

        @media #{$media-b} {
            padding: 0 $gutter;
        }

        @media #{$media-s} {
            padding: 0 $gutter / 2;
        }

        .box--video {
            width: 48%;
            display: flex;
            flex-wrap: wrap;
            height: 280px;
            overflow: hidden;
            position: relative;

            @media #{$media-m} {
                width: 70%;
            }

            @media #{$media-sm} {
                width: 80%;
            }

            @media #{$media-s} {
                width: 100%;
            }

            @media #{$media-sx} {
                height: 320px;
            }

            .col-1 {
                width: 60%;
                overflow: hidden;

                @media #{$media-sx} {
                    width: 100%;
                }

                &::before {
                    content: '';
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    z-index: 2;
                    pointer-events: none;
                    background: url('../images/youtube.svg') no-repeat 30% 50% / 64px;

                    @media #{$media-sx} {
                        background-position: 50% 28%;
                    }
                }

                img {
                    transition: all 0.15s ease-out;
                }

                &:hover {
                    img {
                        transform: scale(1.2);
                    }
                }
            }

            .col-2 {
                width: 40%;
                background-color: var(--c-corporate-1);
                color: var(--c-white);
                padding: $gutter / 2 $gutter / 2;

                @media #{$media-sx} {
                    width: 100%;
                    order: -1;
                }

                h5 {
                    font-size: 2.2rem;
                    line-height: 1.2;
                    margin-bottom: $gutter / 2;

                    @media #{$media-s} {
                        font-size: 1.8rem;
                    }
                }

                h6 {
                    @media #{$media-s} {
                        font-size: 1.4rem;
                    }
                }
            }
        }

    }

    // .box--videos
}

// .page-template-video