/*------------------------------------*\
    Page Template: Certificazioni
    certificazioni.php
    .page-template-certificazioni
\*------------------------------------*/

.page-template-certificazioni {
    #main {
        overflow-x: hidden;
        overflow-y: clip;
    }

    .box--intro {
        margin: 0 auto;
        max-width: $w-media-uw;
        padding-bottom: $gutter / 2;
    }

    .box--section-1 {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-end;
        margin: 0 auto;

        @media #{$media-u} {
            max-width: $w-media-uw;
        }

        .col-1 {
            width: 100%;
            position: relative;
            order: -1;

            .text {
                width: 70%;
                padding: 0 $gutter * 2 0 $gutter * 4;

                @media #{$media-bx} {
                    padding-left: $gutter;
                }

                @media #{$media-b} {
                    padding-left: 0;
                    width: 70%;
                }

                @media #{$media-m} {
                    width: 100%;
                    padding-right: 0;
                }

                @media #{$media-sx} {
                    font-size: 1.4rem;
                }
            }
        }

        .col-2 {
            width: 30%;
            display: flex;
            margin-top: $gutter * 2;
            padding: 0 0 0 $gutter * 2;
            position: relative;

            @media #{$media-b} {
                padding-left: 0;
            }

            @media #{$media-sm} {
                width: 100%;
            }

            @media #{$media-s} {
                margin-top: $gutter;
            }

            &::before {
                position: absolute;
                bottom: -4px;
                left: -38px;
                width: 100%;
                height: 616px;
                content: '';
                background: url('../images/pallini_bg_2.svg') no-repeat top left / contain;
                pointer-events: none;

                @media #{$media-bx} {
                    max-height: 110%;
                    left: 0;
                }

                @media #{$media-s} {
                    left: -38px;
                }
            }
        }

        .col-3 {
            width: 70%;
            position: relative;
            padding-left: $gutter / 4;

            @media #{$media-m} {
                margin-top: $gutter / 2;
            }

            @media #{$media-sm} {
                width: 100%;
                padding-left: 0;
                order: -1;
            }

            &::after {
                width: 358px;
                height: 273px;
                position: absolute;
                bottom: -4px;
                right: 10px;
                content: '';
                background: url('../images/forma_certificazioni.svg') no-repeat top left / contain;
                pointer-events: none;
            }

            .content {
                background-color: var(--c-grey-light);
                color: var(--c-theme-4);
                font-size: 2.6rem;
                font-weight: 500;
                padding: $gutter;

                @media #{$media-s} {
                    padding: $gutter / 2;
                }

                .certificazione {
                    display: flex;
                    align-items: center;
                    position: relative;
                    z-index: 2;

                    .image {
                        padding-right: $gutter / 2;

                        @media #{$media-s} {
                            width: 60%;
                        }
                    }

                    .title {
                        @media #{$media-s} {
                            font-size: 1.6rem;
                        }
                    }
                }
            }
        }
    }

    // .box--section-1

    .box--section-2 {
        display: flex;
        flex-wrap: wrap;

        @media #{$media-u} {
            max-width: $w-media-uw;
        }

        .col-1 {
            width: 100%;
            padding: $gutter $gutter * 2 0 $gutter * 2;

            @media #{$media-b} {
                padding-left: 0;
            }

            @media #{$media-sx} {
                padding: $gutter / 3 0 0 0;
            }

            h4 {
                font-size: 4.3rem;
                font-weight: 700;
                color: var(--c-theme-4);

                @media #{$media-bx} {
                    font-size: 3.6rem;
                }

                @media #{$media-sm} {
                    font-size: 3rem;
                }

                @media #{$media-sx} {
                    font-size: 2.2rem;
                }
            }
        }

        .col-2 {
            padding: $gutter $gutter * 2 0 $gutter * 2;

            @media #{$media-b} {
                padding: $gutter 0;
            }

            @media #{$media-sm} {
                padding: $gutter / 2 0;
            }

            .elenco_certificazioni {
                display: flex;
                flex-wrap: wrap;

                .box_certificazione {
                    width: 33.33%;
                    display: flex;
                    flex-direction: column;
                    padding-right: 8px;
                    padding-bottom: 8px;

                    @media #{$media-m} {
                        width: 50%;
                    }

                    @media #{$media-sm} {
                        width: 100%;
                    }

                    .content {
                        display: flex;
                        align-items: center;
                        height: 107px;
                        cursor: pointer;

                        @media #{$media-sm} {
                            height: 90px;
                        }

                        @media #{$media-sx} {
                            height: 70px;
                        }

                        .image {
                            width: 20%;
                            height: 100%;
                            border: 1px solid var(--c-corporate-1);
                            padding: $gutter / 3;
                            box-sizing: border-box;
                            display: flex;
                            flex: 1;
                            justify-content: center;
                            align-items: center;

                            @media #{$media-sx} {
                                padding: 0 $gutter / 6;
                            }

                            img {
                                max-height: 100%;
                            }
                        }

                        .title {
                            width: auto;
                            height: 100%;
                            display: flex;
                            flex: 3;
                            align-items: center;
                            padding-left: $gutter / 2;
                            box-sizing: border-box;
                            background-color: var(--c-corporate-1);
                            color: var(--c-white);
                            font-size: 2.6rem;
                            font-weight: 500;

                            @media #{$media-b} {
                                font-size: 2.2rem;
                            }

                            @media #{$media-sm} {
                                font-size: 1.7rem;
                            }

                            @media #{$media-sx} {
                                width: 65%;
                            }
                        }

                        .arrow {
                            width: 15%;
                            height: 100%;
                            background-color: var(--c-accent);
                            position: relative;
                            display: flex;
                            flex: 2;
                            justify-content: center;
                            align-items: center;
                            transition: 0.15s all ease;
                            box-sizing: border-box;
                            max-width: 100px;

                            &::after {
                                width: 25px;
                                height: 22px;
                                position: absolute;
                                content: '';
                                background: url('../images/icon-dropdown.svg') no-repeat center center / contain;
                                pointer-events: none;
                                transition: 0.15s all ease;

                                @media #{$media-sx} {
                                    width: 18px;
                                    height: 16px;
                                }
                            }
                        }
                    }

                    .text {
                        width: 100%;
                        background-color: var(--c-corporate-5);
                        position: relative;
                        padding: $gutter;
                        margin: $gutter / 4 0;
                        box-sizing: border-box;

                        @media #{$media-sm} {
                            padding: $gutter / 2;
                            font-size: 1.6rem;
                        }
                    }

                    &[open] {
                        .content {

                            .title {
                                width: -webkit-fill-available;
                                width: -moz-available;
                                width: stretch; // <-- suggerisco, da provare
                            }

                            .arrow {
                                background-color: var(--c-corporate-5);

                                &::after {
                                    transform: rotate(180deg);
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    // .box--section-2

    .box--section-3 {
        display: flex;
        flex-wrap: wrap;
        margin-top: $gutter * 4;
        padding-bottom: $gutter * 4;

        @media #{$media-u} {
            max-width: $w-media-uw;
        }

        @media #{$media-bx} {
            margin-top: $gutter * 2;
        }

        @media #{$media-m} {
            margin-top: $gutter;
            padding-bottom: $gutter * 2;
        }

        @media #{$media-sm} {
            margin-top: $gutter / 2;
        }

        .col-1 {
            width: 30%;

            @media #{$media-b} {
                width: 100%;
            }

            .content {
                background-color: var(--c-corporate-5);
                padding: $gutter / 2 $gutter / 1.5;

                .title {
                    font-size: 2.6rem;
                    font-weight: 700;
                    color: var(--c-theme-4);
                    margin-bottom: $gutter / 3;

                    @media #{$media-b} {
                        font-size: 2.4rem;
                    }

                    @media #{$media-sm} {
                        font-size: 2.2rem;
                    }
                }

                .text {
                    margin-bottom: $gutter;

                    @media #{$media-b} {
                        margin-bottom: $gutter / 2;
                        font-size: 1.6rem;
                    }
                }
            }
        }

        .col-2 {
            width: 70%;

            @media #{$media-b} {
                width: 100%;
            }
        }
    }

    // .box--section-3
}

// .page-template-certificazioni
