/*------------------------------------*\
    Page Template: Gallery
    gallery.php
    .page-template-gallery
\*------------------------------------*/

.page-template-gallery {

    .box--intro {
        margin: 0 auto;
        max-width: $w-media-uw;
        padding-bottom: $gutter / 2;
    }

    .box--section-1 {
        display: flex;
        flex-wrap: wrap;

        @media #{$media-u} {
            max-width: $w-media-uw;
        }

        .col-1 {
            width: 100%;
            padding: 0 0 $gutter $gutter * 3;
            display: flex;
            position: relative;

            @media #{$media-m} {
                width: 100%;
                padding: 0 0 $gutter 0;
            }

            @media #{$media-sx} {
                padding-bottom: $gutter / 2;
            }

            &::after {
                width: 30%;
                height: 184px;
                position: absolute;
                right: 0;
                top: - $gutter * 3;
                content: '';
                background: url('../images/griglia_contatti.svg') no-repeat center center / contain;

                @media #{$media-sm} {
                    display: none;
                }
            }

            .text {
                width: 50%;
                padding: 0 $gutter * 4 0 0;

                @media #{$media-bx} {
                    padding-left: $gutter;
                }

                @media #{$media-b} {
                    padding-left: 0;
                    width: 70%;
                }

                @media #{$media-sm} {
                    width: 100%;
                    padding-right: 0;
                }

                @media #{$media-sx} {
                    font-size: 1.4rem;
                }
            }
        }

        //.col-1

    }

    // .box--section-1

    .box--gallery {
        padding: 0 $gutter * 3;

        @media #{$media-m} {
            padding: 0;
        }

        .container {
            display: flex;
            flex-wrap: wrap;
            gap: 40px;
            width: 100%;

            @media #{$media-sm} {
                gap: 20px;
            }

            @media #{$media-sx} {
                margin-bottom: $gutter / 2;
            }

            .box--photo {
                position: relative;
                overflow: hidden;
                cursor: pointer;

                a {
                    width: 100%;
                    height: 100%;
                    display: block;
                }

                .image {
                    position: absolute;
                    pointer-events: none;
                    background-repeat: no-repeat;
                    background-size: cover;
                    transition: all 0.15s ease-out;
                    width: 100%;
                    height: 100%;

                    &::before {
                        content: '';
                        position: absolute;
                        top: 0;
                        width: 100%;
                        height: 100%;
                        pointer-events: none;
                        background-color: var(--c-corporate-1);
                        opacity: 0;
                        transition: all 0.15s ease-out;
                    }

                    &.allinea_Center {
                        background-position: center center
                    }

                    &.allinea_Left {
                        background-position: center left
                    }

                    &.allinea_Right {
                        background-position: center right
                    }

                    &.allinea_Top {
                        background-position: center top
                    }

                    &.allinea_Bottom {
                        background-position: center bottom
                    }
                }

                h4 {
                    position: absolute;
                    bottom: 0;
                    opacity: 0;
                    font-size: 2.6rem;
                    font-weight: 700;
                    margin: 1rem 2rem;
                    color: var(--c-white);

                    @media #{$media-m} {
                        font-size: 2.2rem;
                    }

                    @media #{$media-s} {
                        font-size: 1.8rem;
                    }
                }
            }

            .box--photo:hover {
                .image {
                    transform: scale(1.2);

                    &::before {
                        opacity: .6;
                    }
                }

                h4 {
                    opacity: 1;
                }
            }

            .size_1,
            .size_5 {
                width: 30%;
                height: 450px;
            }

            .size_2,
            .size_3,
            .size_4 {
                width: 30%;
                height: 300px;
            }

            .size_6 {
                width: 30%;
                height: 790px;
            }

            .size_7 {
                width: calc(60% + 40px);
                height: 300px;
            }

            .size_5,
            .size_6 {
                margin-top: -150px;
            }

            .size_7 {
                margin-top: -340px;
            }

            @media #{$media-b} {
                .size_1 {
                    width: 30%;
                    height: 300px;
                }

                .size_2,
                .size_3 {
                    width: 60%;
                    height: 300px;
                }

                .size_5,
                .size_7 {
                    width: 60%;
                }

                .size_5,
                .size_6 {
                    margin-top: 0;
                }
            }

            @media #{$media-sm} {
                .size_1,
                .size_4,
                .size_6 {
                    width: 35%;
                }

                .size_7 {
                    margin-top: -320px;
                }

                .size_6 {
                    height: 770px;
                }
            }

            @media #{$media-sx} {
                .size_1,
                .size_2,
                .size_3,
                .size_4,
                .size_5,
                .size_6,
                .size_7 {
                    width: 100%;
                    margin: 0;
                }

                .size_1,
                .size_5 {
                    height: 300px;
                }

                .size_6 {
                    height: 500px;
                }
            }
        }

    }

    // .box--gallery
}

// .page-template-gallery