/*------------------------------------*\
    Post Type: Prodotti ( product )
    General
\*------------------------------------*/










/*------------------------------------*\
    Custom Post Type: Prodotti ( product )
    single-product.php
\*------------------------------------*/

.single-product {



    .product--single {

        position: relative;

        &.color-- {
            --c-family: var(--c-corporate-1);
        }

        &.color--hydro {
            --c-family: var(--c-hydro);
        }

        &.color--oilgas {
            --c-family: var(--c-oil);
        }

        &.color--electric {
            --c-family: var(--c-electric);
        }

        .bg {

            position: absolute;
            overflow: hidden;
            top: 0;
            right: 0;
            width: 40rem;
            height: 18rem;
            z-index: -1;

            .family-dots {

                display: block;
                width: 29rem;
                height: auto;
                position: absolute;
                top: 0;
                right: 4rem;
                z-index: -1;

                .dots {

                    fill: var(--c-grey-light);

                    @media #{$media-m} {
                        display: none;
                    }

                }

                .dots-color {

                    fill: var(--c-family);

                    @media #{$media-s} {
                        display: none;
                    }

                }

            }

            // .family-dots

        }

        // .bg

        .addToCart, .addToWishlist {
            display: inline-block;
            width: max-content;
        }

    }

    // .product--single


    .mfp-img {
        min-height: 90vh;
    }

    .mfp-figure::after {
        background-color: white;
    }



    .gallery {

        .items {

            margin: auto;
            max-width: $w-media-u;

            display: flex;
            justify-content: space-evenly;
            flex-wrap: wrap;
            gap: 4rem;

            .slick-list,
            .slick-track,
            .slick-slide,
            .item {
                width: 100%;
                height: 100%;
            }

            .slick-dots {

                margin: auto;
                margin-top: 0;
                text-align: center;
                display: flex;
                justify-content: center;
                align-items: center;

                li {

                    position: relative;
                    display: inline-flex;
                    align-items: center;
                    width: 4rem;
                    height: 3rem;

                    cursor: pointer;
                    // background-color: var(--c-grey-normal);

                    &:not(:first-of-type) {
                        margin-left: 2rem;
                    }

                    // &.slick-active { background-color: var(--c-corporate-1); }

                    &::before {
                        content: '';
                        display: block;
                        width: 100%;
                        height: 1rem;
                        background-color: var(--c-grey-normal);
                    }

                    &.slick-active::before {
                        background-color: var(--c-corporate-1);
                    }

                }

                button {
                    display: none;
                }

            }

            // .slick-dots

            .item {

                //width: 25rem;

                .image {

                    display: block;
                    width: auto;
                    max-width: 120rem;
                    // max-height: 80rem;
                    // height: 60rem;
                    margin: auto;

                    @media #{$media-m} {
                        // height: 50rem;
                    }

                    @media #{$media-s} {
                        //height: auto;
                    }

                    .img {

                        display: block;
                        width: 100%;
                        height: 100%;
                        object-fit: contain;

                    }

                    // .img

                    .caption {

                        padding: .5rem 2rem;
                        text-align: center;
                        color: var(--c-white);
                        background-color: var(--c-corporate-1);

                    }

                    // .caption

                }

                // .image

            }

            // .item

        }

        // .items

    }

    // .gallery



    .gallery--image {

        .image {

            display: block;
            margin: auto;

            .img {

                display: block;
                width: 100%;
                height: auto;
                object-fit: contain;

            }

            // .img

            .caption {

                padding: .5rem 2rem;
                text-align: center;
                color: var(--c-white);
                background-color: var(--c-corporate-1);

            }

            // .caption

        }

        // .image

    }

    // .gallery--image



    .product--info {

        max-width: 190rem;
        padding: $gutter;
        position: relative;

        @media #{$media-sx} {
            padding: $gutter / 2;
        }

        .box--title {
            padding: 0;
        }


        .bg {

            position: absolute;
            top: 0;
            right: 0;
            width: 30vw;
            max-width: 70rem;
            height: 50rem;
            z-index: -1;

            @media #{$media-bx} {
                width: 20vw;
                min-width: 30rem;
            }

        }

        // .bg


        .product--image,
        .product--gallery {

            margin: auto;

        }

        .product--image .img,
        .product--gallery .items .image .img {
            width: auto;
            height: auto;
            max-width: 100%;
            margin: auto;
        }

        .product--image {

            text-align: center;

            .link {
                display: block;
                width: 100%;
                height: 100%;
            }

        }

        .content {

            margin-top: $gutter;

            // display: grid;
            // grid-gap: $gutter;
            // grid-template-columns: 1fr 2fr;
            //
            // @media #{$media-m} { grid-template-columns: repeat(1, 1fr); }

            display: flex;
            justify-content: space-evenly;
            align-items: center;
            gap: $gutter*2;

            @media #{$media-m} {
                flex-direction: column;
                align-items: flex-start;
            }

            .content--media {

                width: 50rem;

                @media #{$media-b} {
                    width: 40rem;
                }

                @media #{$media-s} {
                    width: 100%;
                    margin-left: 0;
                }

            }

            .content--text {

                &>.title3,
                &>.text {
                    max-width: 80rem;
                }

                &>.title3 {
                    font-size: 1.8rem;
                }

                .title3+.text {
                    margin-top: 2rem;
                }



                .techsheet {

                    margin-top: $gutter;
                    font-family: $f-family-title;
                    font-size: 1.6rem;

                    @media #{$media-s} {
                        font-size: 1.6rem;
                        margin-top: 0;
                    }

                    .title {

                        font-size: 2.6rem;
                        font-weight: $f-weight-500;
                        text-transform: uppercase;
                        color: var(--c-accent);

                        @media #{$media-s} {
                            font-size: 2.4rem;
                        }

                    }

                    // .title

                    .items {

                        margin-top: $gutter*.5;
                        display: grid;
                        grid-template-columns: 1fr 1fr;
                        grid-gap: $gutter;

                        @media #{$media-s} {
                            grid-template-columns: repeat(1, 1fr);
                        }

                        .item {}

                        // .item

                    }

                    // .items

                    .info {

                        padding-top: 1rem;
                        padding-bottom: 1rem;
                        border-bottom: .1rem solid var(--c-grey-light);

                        .label {
                            font-weight: $f-weight-700;
                        }

                        // .label

                        .value {}

                        // .value

                        &.material--carbon .value,
                        &.material--stainless .value {
                            display: flex;
                            align-items: center;
                            gap: 1rem;
                        }

                        &.material--carbon .value::after,
                        &.material--stainless .value::after {
                            display: inline-flex;
                            justify-content: center;
                            align-items: center;
                            border-radius: 50%;
                            color: var(--c-white);
                            width: 2.4rem;
                            height: 2.4rem;
                            font-size: 1.2rem;
                            font-weight: normal;
                        }

                        &.material--carbon .value::after {
                            content: 'CS';
                            background-color: var(--c-carbon);
                        }

                        &.material--stainless .value::after {
                            content: 'SS';
                            background-color: var(--c-stainless);
                        }

                    }

                    // .info

                }

                // .techsheet



                .characteristics {

                    margin-top: $gutter;

                    .title {

                        font-size: 2.6rem;
                        font-weight: $f-weight-500;
                        text-transform: uppercase;
                        color: var(--c-accent);

                    }

                    // .title

                    .text {

                        margin-top: $gutter*.5;
                        font-family: $f-family-title;
                        font-size: 1.6rem;

                    }

                    // .text

                }

                // .characteristics

                .content--links {
                    display: flex;
                    flex-wrap: wrap;
                    gap: $gutter;
                    align-items: center;

                    @media #{$media-sx} {
                        gap: $gutter / 4;
                    }
                }


                .link--kit {

                    margin-top: $gutter;
                    display: inline-block;

                    border: .1rem solid var(--c-corporate-1);
                    background-color: var(--c-corporate-5);
                    padding: 1.5rem 2rem;

                    @media #{$media-m} {
                        padding: $gutter / 3 $gutter / 2;
                    }

                    @media #{$media-sx} {
                        margin-top: $gutter / 4;
                    }

                    &:hover,
                    &:focus-within {

                        color: var(--c-white);
                        background-color: var(--c-corporate-1);

                    }

                }

                // .link--kit

                // .download
                .download {

                    padding: 1.2rem 2rem;

                    .info {

                        display: flex;
                        align-items: center;
                        gap: 1rem;
        
                        .title {
        
                            font-family: $f-family-title;
                            font-weight: $f-weight-500;
                            font-size: 1.8rem;
                            text-transform: uppercase;
        
                            @media #{$media-s} {
                                font-size: 1.4rem;
                            }
        
                        }
        
                        // .title
        
                        .icon {
        
                            display: inline-flex;
        
                            width: 4rem;
                            height: 3rem;
        
                            @media #{$media-s} {
                                width: 2rem;
                                height: 2.9rem;
                            }
        
                            svg {
                                display: block;
                                width: 100%;
                                height: 100%;
                                fill: currentColor;
                            }
        
                        }
        
                        // .icon
        
                        .label {
        
                            // color: var(--c-corporate-1);
        
                            .type {
                                font-weight: $f-weight-700;
                                text-transform: uppercase;
                            }
        
                            // .type
        
                            .size {}
        
                            // .size
        
                        }
        
                        // .label
        
                    }
                }

                // .download


            }

            // .content--text

        }

        // .content

    }

    // .product--info



    .product--data {

        max-width: 190rem;
        // padding: $gutter;
        margin: 0 $gutter;
        background-color: hsl(0, 0%, 98%);
        border: .1rem solid var(--c-corporate-1);

        @media #{$media-sx} {
            margin: 0 $gutter*.5;
        }

        .data-title {

            max-width: 40rem;
            color: var(--c-corporate-1);
            background-color: var(--c-grey-light);
            border: .1rem solid currentColor;
            border-bottom-color: transparent;
            border-top-color: transparent;
            border-left-color: transparent;

            display: flex;
            justify-content: space-between;
            align-items: center;

            padding: 2rem;
            font-size: 1.8rem;
            font-weight: $f-weight-600;
            text-transform: uppercase;

            @media #{$media-s} {
                font-size: 1.6rem;
                padding: 1rem;
            }

            &::after {

                display: inline-flex;
                content: '';
                width: 0;
                height: 0;

                border-left: .75rem solid transparent;
                border-right: .75rem solid transparent;
                border-top: 1.3rem solid currentColor;
                border-bottom: initial;

            }

            &:hover {
                cursor: pointer;
            }

        }

        // .data-title

        &[open] {

            .data-title {

                background-color: var(--c-corporate-5);
                border-bottom-color: currentColor;

                &::after {
                    border-top: initial;
                    border-bottom: 1.3rem solid currentColor;
                }

            }

        }

        .data-content {

            padding: $gutter;
            // background-color: var(--c-white);

            @media #{$media-s} {
                padding: $gutter / 2
            }

            .link {
                display: block;
                width: 100%;
                height: 100%;
            }

        }

        // .data-content

    }

    // .product--data

    .product--data+.product--data {
        border-top: none;
    }





    .product--composition {

        .data-content {

            display: grid;
            grid-gap: $gutter;
            grid-template-columns: 1fr 2fr;
            align-items: center;

            @media #{$media-m} {
                grid-template-columns: repeat(1, 1fr);
            }

        }

        .image {}

        // .image

        .info {

            // width: 100%;

            .info--table {

                border-collapse: collapse;
                margin: 0;
                margin-top: 2rem;
                padding: 0;
                width: 99%;
                // table-layout: fixed;
                // overflow: hidden;

                @media #{$media-s} {
                    width: 100%;
                    table-layout: fixed;
                    overflow: hidden;
                    margin-top: 0;
                }

                tr th,
                tr td {
                    padding: 1rem;
                    position: relative;
                }

                thead {

                    border-bottom: .1rem solid var(--c-grey-dark);

                    @media #{$media-s} {
                        border: none;
                        clip: rect(0 0 0 0);
                        height: 1px;
                        margin: -1px;
                        overflow: hidden;
                        padding: 0;
                        position: absolute;
                        width: 1px;
                    }


                    tr {
                        border-bottom: .1rem solid var(--c-grey-normal);
                    }

                    th {

                        border-left: .1rem solid var(--c-grey-normal);
                        border-right: .1rem solid var(--c-grey-normal);

                        &:first-of-type {
                            border-left: none;
                        }

                        &:last-of-type {
                            border-right: none;
                        }

                        text-transform: uppercase;

                    }

                }

                // thead

                tbody {

                    &::before {
                        content: '';
                        display: table-row;
                        height: 2rem;

                        @media #{$media-s} {
                            height: 0;
                        }
                    }

                    tr {

                        &:not(:last-of-type) {
                            border-bottom: .1rem solid var(--c-grey-light);
                        }

                        @media #{$media-s} {

                            display: block;
                            margin-top: 2rem;
                            border-bottom: none;

                        }


                        td {

                            padding: 0;
                            text-align: center;

                            border-left: .1rem solid var(--c-grey-normal);
                            border-right: .1rem solid var(--c-grey-normal);

                            &:first-of-type {
                                border-left: none;
                            }

                            &:last-of-type {
                                border-right: none;
                            }

                            @media #{$media-s} {

                                background-color: #fff;
                                border-bottom: .1rem solid #7C7C7C;
                                max-width: 100%;

                                display: flex;
                                flex-wrap: wrap;
                                gap: 1rem;
                                align-items: center;
                                justify-content: space-between;

                                &:empty {
                                    display: none;
                                }

                                &::before {
                                    content: attr(title);
                                    font-size: 1.4rem;
                                }

                            }

                        }
                    }

                }

                // tbody

            }

            // .info--table

        }

        // .info

    }

    // .product--composition





    .product--diagram {

        .gallery--image {
            display: grid;
            grid-gap: $gutter;
            grid-template-columns: repeat(2, 1fr);

            max-width: 120rem;
            margin: auto;

            @media #{$media-m} {
                grid-template-columns: repeat(1, 1fr);
            }
        }

    }

    // .product--diagram





    .product--techdata {

        .gallery--image {
            max-width: 120rem;
            margin: auto;
        }

    }

    // .product--techdata





    .product--ends,
    .product--noends {

        // padding: $gutter;

        .gallery {

            margin-bottom: $gutter;

        }

        // .gallery

        .ends-item {

            .title {

                color: var(--c-corporate-1);
                background-color: var(--c-corporate-5);

                display: flex;
                justify-content: space-between;
                align-items: center;

                padding: 2rem;
                font-size: 1.8rem;
                font-weight: $f-weight-600;
                text-transform: uppercase;

                @media #{$media-s} {
                    font-size: 1.6rem;
                    padding: 1rem;
                }

                &::after {

                    display: inline-flex;
                    content: '';
                    width: 0;
                    height: 0;

                    border-left: .75rem solid transparent;
                    border-right: .75rem solid transparent;
                    border-top: 1.3rem solid currentColor;
                    border-bottom: initial;

                }

                &:hover {
                    cursor: pointer;
                }

            }

            // .title

            &[open] {

                .title {

                    &::after {
                        border-top: initial;
                        border-bottom: 1.3rem solid currentColor;
                    }

                }

            }

        }

        // .ends-item

        .ends-item+.ends-item {
            margin-top: 1rem;
        }

        .content {

            display: flex;
            flex-direction: column;
            padding: 2rem;
            border: .1rem solid var(--c-grey-light);
            overflow-x: auto;

            @media #{$media-sm} {
                overflow-x: hidden;
            }

            .filters_image_container {
                display: flex;
                order: -1;
                flex-direction: row-reverse;
                justify-content: flex-end;

                @media #{$media-sm} {
                    flex-direction: column;
                }
            }

            .filter {

                margin-top: 2rem;

                button {

                    font-size: 1.4rem;
                    border: .1rem solid hsl(0, 0%, 57%);
                    background-color: hsl(0, 0%, 87%);
                    padding: .5rem 1.5rem;

                    &:first-of-type:last-of-type,
                    &.active {
                        pointer-events: none;
                    }

                    &.active {
                        background-color: hsl(95, 41%, 60%);
                    }

                    @media #{$media-sx} {
                        font-size: 1.3rem;
                    }

                }

            }

            // .filter

            .image {
                max-width: 40rem;
                max-height: 15rem;

                @media #{$media-sm} {
                    max-height: none;
                }

                .img {

                    display: block;
                    width: 100%;
                    height: 100%;

                }

                // .img

            }

            // .image

        }

        // .content

        .model--table {

            border-collapse: collapse;
            margin: 0;
            margin-top: 2rem;
            padding: 0;
            width: 99%;
            // table-layout: fixed;
            // overflow: hidden;

            @media #{$media-sm} {
                width: 100%;
                table-layout: fixed;
                overflow: hidden;
            }

            tr th,
            tr td {
                padding: 1rem;
                position: relative;
            }

            thead {

                border-bottom: .1rem solid var(--c-grey-dark);

                @media #{$media-sm} {
                    border: none;
                    clip: rect(0 0 0 0);
                    height: 1px;
                    margin: -1px;
                    overflow: hidden;
                    padding: 0;
                    position: absolute;
                    width: 1px;
                }


                tr {
                    background-color: var(--c-corporate-5);
                }

                th {

                    border: .1rem solid var(--c-grey-dark);

                    vertical-align: middle;
                    font-size: 1.8rem;
                    line-height: 1;
                    color: var(--c-theme-4);

                    span {
                        display: block;
                        margin-top: .5rem;
                        font-size: 1.4rem;
                        color: var(--c-corporate-1);
                    }

                }

            }

            // thead

            tbody {

                tr {

                    border: .1rem solid var(--c-grey-dark);

                    @media #{$media-sm} {

                        display: block;
                        margin-top: 2rem;
                        border-bottom: none;

                    }


                    td {

                        &:not(:first-of-type) {
                            text-align: center;
                        }

                        .itemInWishlist {
                            filter: invert(15%) sepia(63%) saturate(4289%) hue-rotate(349deg) brightness(90%) contrast(106%);
                        }

                        @media #{$media-sm} {

                            background-color: #fff;
                            border-bottom: .1rem solid #7C7C7C;
                            max-width: 100%;

                            display: flex;
                            flex-wrap: wrap;
                            gap: 1rem;
                            align-items: center;
                            justify-content: space-between;

                            &:empty {
                                display: none;
                            }

                            &::before {
                                content: attr(title) ' ' attr(data-unit);
                                font-size: 1.4rem;
                            }

                            &:nth-child(1) {
                                background-color: var(--c-corporate-5);
                                font-size: 2.2rem;

                                @media #{$media-s} {
                                    font-size: 1.6rem;
                                }
                            }
                        }

                    }
                }

            }

            // tbody

        }

        // .model--table

    }

    // .product--ends

    .product--noends {

        .content {
            overflow-x: auto;

            @media #{$media-sm} {
                overflow-x: hidden;
            }
        }

        // .content

    }

    // .product--noends





    .produc--certification {

        .data-content {}

        .item {

            padding: $gutter*.5 $gutter;
            display: flex;
            align-items: center;
            gap: $gutter;

            @media #{$media-s} {
                flex-direction: column;
                align-items: flex-start;
                gap: $gutter*.5;
                padding: $gutter*.5 0;
            }

            &:not(:only-of-type):not(:last-of-type) {
                border-bottom: .1rem solid var(--c-grey-light);
            }

            .image {

                flex-shrink: 0;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 6rem;
                height: 6rem;
                padding: 1rem;
                background-color: var(--c-white);
                border: .1rem solid var(--c-grey-light);

                .img {
                    display: block;
                    object-fit: contain;
                }

                // .img

            }

            // .image

            .title {

                font-size: 3rem;
                font-weight: $f-weight-500;
                line-height: 1.1;

                @media #{$media-s} {
                    font-size: 2rem;
                }

            }

            // .title

            .text {

                font-family: $f-family-title;
                font-size: 1.6rem;

                @media #{$media-s} {
                    font-size: 1.4rem;
                }

            }

            // .text

        }

        // .item

    }

    // .produc--certification





    .produc--download {

        .item {

            padding: $gutter;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            gap: $gutter;

            @media #{$media-s} {
                flex-direction: column;
                align-items: flex-start;
                gap: $gutter*.5;
                padding: 0;
                margin-bottom: $gutter / 2;
            }

            &:not(:only-of-type) {
                border-bottom: .1rem solid var(--c-grey-light);
            }

            .content {

                .title {

                    font-size: 3rem;
                    font-weight: $f-weight-500;
                    line-height: 1.1;

                    @media #{$media-s} {
                        font-size: 2rem;
                    }

                }

                // .title

                .text {

                    font-family: $f-family-title;
                    font-size: 1.8rem;

                    @media #{$media-s} {
                        font-size: 1.6rem;
                    }

                }

                // .text

                .warning {

                    font-size: 1.4rem;

                    a {
                        text-decoration: underline;
                    }

                }

                // .warning

            }

            // .content

            .link,
            .file--lock {

                border: .1rem solid var(--c-corporate-1);
                background-color: var(--c-corporate-5);
                padding: 1.5rem 2rem;

                @media #{$media-s} {
                    padding: 1rem 1.5rem;
                }

            }

            .link {

                &:hover,
                &:focus-within {

                    color: var(--c-white);
                    background-color: var(--c-corporate-1);

                }
            }

            // .link

            .info {

                display: flex;
                align-items: center;
                gap: 1rem;

                .title {

                    font-family: $f-family-title;
                    font-weight: $f-weight-500;
                    font-size: 1.8rem;
                    text-transform: uppercase;

                    @media #{$media-s} {
                        font-size: 1.4rem;
                    }

                }

                // .title

                .icon {

                    display: inline-flex;

                    width: 4rem;
                    height: 4rem;

                    @media #{$media-s} {
                        width: 2rem;
                        height: 2rem;
                    }

                    svg {
                        display: block;
                        width: 100%;
                        height: 100%;
                        fill: currentColor;
                    }

                }

                // .icon

                .label {

                    // color: var(--c-corporate-1);

                    .type {
                        font-weight: $f-weight-700;
                        text-transform: uppercase;
                    }

                    // .type

                    .size {}

                    // .size

                }

                // .label

            }

            // .info

            .file {

                flex-shrink: 0;
                margin-left: auto;

                display: flex;
                align-items: center;
                gap: $gutter;

                @media #{$media-s} {
                    margin-left: 0;
                }

                .link {}

                // .link

            }

            // .file

            .files {

                width: 100%;

                display: flex;
                flex-wrap: wrap;
                gap: $gutter*.5;

                .files-item {

                    .link {
                        height: auto;
                        width: auto;
                    }

                }

                // .files-item

                .icon {
                    width: 2rem;
                    height: 2rem;
                }

            }

            // .files

        }

        // .item

    }

    // .produc--download





    .produc--solution {

        .data-content {

            display: flex;
            flex-wrap: wrap;
            gap: $gutter*2;

        }

        // .data-content

        .item {

            position: relative;
            display: flex;
            flex-direction: column;
            max-width: 24rem;

            .image {

                flex-shrink: 0;
                width: 24rem;
                height: 18rem;
                position: relative;

                @media #{$media-s} {
                    width: 20rem;
                    height: 15rem;
                }

                svg:not(:last-of-type) {

                    display: block;
                    position: absolute;
                    // left: 8rem;
                    left: 9rem;
                    bottom: 1rem;
                    // width: 8rem;
                    width: 7.5rem;
                    height: auto;
                    z-index: 2;
                    fill: var(--c-white);
                    transition: all .25s ease;

                    @media #{$media-s} {
                        // width: 7rem;
                        // left: 6.5rem;
                        width: 6rem;
                        left: 7.5rem;
                    }

                }

                // .img

                .bg {
                    display: block;
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    top: 0;
                    left: 0;
                    z-index: 1;
                    fill: var(--c-corporate-1);
                    transition: all .25s ease;
                }

                //

            }

            // .image

            .title {

                margin-top: 1rem;
                font-size: 2.4rem;
                line-height: 1.1;
                text-transform: uppercase;

                .link {

                    outline: none;

                    &::before {
                        content: '';
                        display: block;
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        top: 0;
                        right: 0;
                        bottom: 0;
                        left: 0;
                        z-index: 5;
                    }

                }

                // .link

            }

            // .title

            &:hover,
            &:focus-within {

                outline: .1rem solid var(--c-accent);
                outline-offset: 0;

                svg:not(:last-of-type) {
                    fill: var(--c-corporate-1);
                }

                .bg {
                    fill: var(--c-accent);
                }

            }

        }

        // .item

    }

    // .produc--solution





    .back {

        padding: $gutter;

    }

    // .back



    .link--request {

        position: absolute;
        top: $gutter;
        right: $gutter;

        display: inline-flex;
        align-items: center;
        // position: relative;
        height: 4.5rem;
        padding-left: $gutter*.5;
        padding-right: $gutter*2;

        font-family: $f-family-title;
        font-weight: $f-weight-600;
        font-size: 1.8rem;
        line-height: 1;
        text-transform: uppercase;
        color: var(--c-white);
        background-color: var(--c-corporate-1);

        &::after {

            display: inline-flex;
            content: '';
            width: 0;
            height: 0;
            position: absolute;
            top: 0;
            right: 0;
            border-left: 4.5rem solid transparent;
            border-right: initial;
            border-top: initial;
            border-bottom: 4.5rem solid var(--c-white);

        }

        &:hover,
        &:focus-within {
            color: var(--c-corporate-1);
            background-color: var(--c-corporate-5);
        }

        @media #{$media-m} {

            font-size: 0;

            &::before {
                font-size: 1.8rem;
                display: block;
                content: 'info';
            }

        }

        @media #{$media-sm} {
            display: none;
        }

    }

    // .link--request {}

    .produc--request {

        margin: auto;
        margin-top: $gutter*2;
        max-width: 120rem;
        padding: $gutter;

        @media #{$media-sx} {
            margin-top: 0;
            padding: $gutter / 2;
        }

        .box--title {

            padding: 0;

            .title2 {
                @media #{$media-sx} {
                    font-size: 2.4rem;
                }
            }

        }

        // .box--title


        .gform_wrapper {

            .gform_footer button,
            .gform_footer input[type=submit] {

                display: inline-flex;
                align-items: center;
                position: relative;
                height: 6rem;
                padding-left: $gutter*.5;
                padding-right: $gutter*2;

                font-family: $f-family-title;
                font-weight: $f-weight-600;
                font-size: 1.8rem;
                line-height: 1;
                text-transform: uppercase;
                color: var(--c-white);
                background-color: var(--c-corporate-1);

                &::after {

                    display: inline-flex;
                    content: '';
                    width: 0;
                    height: 0;
                    position: absolute;
                    top: 0;
                    right: 0;
                    border-left: 6rem solid transparent;
                    border-right: initial;
                    border-top: initial;
                    border-bottom: 6rem solid var(--c-white);

                }

                &:hover,
                &:focus-within {
                    color: var(--c-corporate-1);
                    background-color: var(--c-corporate-5);
                    border: none;
                }

            }

        }

        // .gform_wrapper


    }

    // .produc--request

}

// .single-product


/*------------------------------------*\
    Post Type: Prodotti ( product )
    archive-product.php
    .post-type-archive-product {}
\*------------------------------------*/

.post-type-archive-product {
    .family {

        &.color-- { --c-family: var(--c-corporate-1); }
        &.color--hydro { --c-family: var(--c-hydro); }
        &.color--oilgas { --c-family: var(--c-oil); }
        &.color--electric { --c-family: var(--c-electric); }

        &.family--parent {

            position: relative;

            .bg {

                position: absolute;
                top: 0;
                right: 0;
                width: 30vw;
                max-width: 70rem;
                height: 50rem;
                z-index: -1;

                @media #{$media-bx} {
                    width: 20vw;
                    min-width: 30rem;
                }

                .family-icon {

                    display: block;
                    width: 6rem;
                    position: absolute;
                    top: 2rem;
                    left: 22rem;
                    z-index: 3;

                    @media #{$media-bx} {
                        left: inherit;
                        right: 10rem;
                    }
                    @media #{$media-b} {
                        right: 4rem;
                        top: 4rem;
                    }
                    @media #{$media-m} { width: 4rem; }
                    @media #{$media-sx} { display: none; }

                }// .family-icon

                .family-draw {

                    display: block;
                    width: 20rem;
                    position: absolute;
                    top: 4rem;
                    left: 0;
                    z-index: 2;
                    fill: var(--c-grey-normal);

                    @media #{$media-bx} {
                        fill: var(--c-grey-light);
                        left: inherit;
                        top: 10rem;
                        right: 10rem;
                    }
                    @media #{$media-b} { display: none; }

                }// .family-draw

                .family-dots {

                    display: block;
                    // width: 33.6rem;
                    height: 100%;
                    position: absolute;
                    top: 0;
                    right: 4rem;
                    z-index: -1;

                    .dots {

                        fill: var(--c-grey-light);
                        @media #{$media-bx} { display: none; }

                    }
                    .dots-color {

                        fill: var(--c-family);
                        @media #{$media-b} { display: none; }

                    }

                }// .family-dots

            }// .bg

            .items {

                padding: $gutter;

                display: flex;
                justify-content: center;
                gap: $gutter;
                flex-wrap: wrap;

                @media #{$media-b} { flex-wrap: wrap; }
                // @media #{$media-sm} { grid-template-columns: repeat(1, 1fr); }

                .item {

                    width: 36rem;
                    position: relative;

                    @media #{$media-b} { width: 30rem; }

                    &:hover,
                    &:focus-within {
                        outline: .1rem solid var(--c-accent);
                        outline-offset: 0;
                    }

                    .link {

                        outline: none;

                        &::before {
                            content: '';
                            display: block;
                            position: absolute;
                            width: 100%;
                            height: 100%;
                            top: 0;
                            right: 0;
                            bottom: 0;
                            left: 0;
                            z-index: 1;
                        }

                    }// .link

                    .image {

                        padding: $gutter / 2;
                        position: relative;

                        .img {
                            display: block;
                            width: 100%;
                            height: 30rem;
                            object-fit: contain;
                        }// .img

                        svg {
                            display: block;
                            width: 100%;
                            height: 100%;
                            position: absolute;
                            top: 0;
                            left: 0;
                            z-index: -1;
                            fill: var(--c-family)
                        }//

                    }// .image

                    .title {

                        // font-size: 3.6rem;
                        font-size: 2.6rem;
                        line-height: 1.1;
                        font-weight: $f-weight-500;
                        text-transform: uppercase;

                    }// .title

                }// .item

            }// .items

        }// .family--parent

    }// .family
}