.openTooltip {
    .tooltip {
        position: absolute;
        left: 3rem;
        top: -1rem;
        width: max-content;
        background-color: var(--c-tooltip);
        display: none;
        flex-direction: column;
        align-items: flex-start;
        -webkit-box-shadow: 0 3px 6px 0 #F0F0F0;
        box-shadow: 0 3px 6px 0 #F0F0F0;
        padding: 2px 5px;
        z-index: 2;
    
        span {
            font-family: $f-family-title;
        }
    
        span.small {
            font-size: 1.2rem;
        }
    
        span.big {
            font-size: 1.6rem;
            font-weight: $f-weight-500;
        }
    }

    &:hover {
        .tooltip {
            display: flex;
        }
    }
}

